import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Card,
  Grid,
  Icon,
  List,
  Button,
  Image,
  Modal,
  Header,
  Table,
} from "semantic-ui-react";
import moment from "moment";
import * as config from "../config";
import ScheduleById from "./Schedule/scheduleById";
import ScheduleList from "./Schedule/scheduleList";
import {
  getNewsList,
  getAlertList,
  updateReadNews,
  updateReadAlert,
  getPersonnelAlert,
  getPersonnelAlertList,
  getPersonnelAlertFlightList,
  getPersonnelAlertNameList,
  getPersonnelAlertDrop
} from "./service";
import ModalForm from "../components/modal";
import Wrap from "../components/wrap";

class Home extends Component {
  state = {
    bacUserData: JSON.parse(localStorage.getItem("bacUserData")),
    openNews: false,
    openAllNews: false,
    limitNews: 6,
    newsData: [],
    alertData: [],
    alertList: [],
    alertFlightList: [],
    alertDropList: [],
    currentNews: [],
    contentAll: null,
    bac_permission: JSON.parse(localStorage.getItem("bac_permission")),
    bac_role: JSON.parse(localStorage.getItem("bac_roles")),
    canSeeAlertAll: false,
  };

  componentDidMount() {
    if (
      this.checkPermissionMenu("Personnel", ["view-instructor", "view-student"])
    ) {
      this.setState({ canSeeAlertAll: true, limitNews: 3 });
      getPersonnelAlertDrop().then((res) => {
        if (res && res.status === 200) {
          console.log(res.data)
          this.setState({ alertDropList: res.data });
        }
      });
      getPersonnelAlertList().then((res) => {
        if (res && res.status === 200) {
          this.setState({ alertList: res.data });
        }
      });
      getPersonnelAlertFlightList().then((res) => {
        if (res && res.status === 200) {
          this.setState({ alertFlightList: res.data });
        }
      });

    }
    this.getNews();
    this.getAlerts();
  }

  checkPermissionMenu = (group, permission) => {
    let { bac_permission } = this.state;
    let userpermissionGroup = bac_permission.find(
      (item) => item.group === group
    );
    let allowed = permission.find((item) => {
      if (
        userpermissionGroup &&
        userpermissionGroup.permission.indexOf(item) !== -1
      )
        return item;
    });
    if (allowed) return true;
    else return false;
  };

  getPersonnelAlertNameList = (type, only) => {
    switch (type) {
      case "medical":
        this.setState({
          contentNameList: "Medical Certificate",
        });
        break;
      case "spl":
        if (only === "student")
          this.setState({
            contentNameList: "Student Pilot License",
          });
        else
          this.setState({
            contentNameList: "Pilot License",
          });
        break;
      case "dcp":
        this.setState({
          contentNameList: "DCP License",
        });
        break;
      case "lastFlight":
        this.setState({
          contentNameList: "Last Flight",
        });
        break;
      default:
        break;
    }
    this.setState({
      openNameList: true,
      loadingNameList: true,
      dataNameList: [],
    });
    getPersonnelAlertNameList("?type=" + type + "&only=" + only).then((res) => {
      if (res && res.status === 200) {
        this.setState({ dataNameList: res.data, loadingNameList: false });
      }
      this.setState({ loadingNameList: false });
    });
  };
  getNews = () => {
    this.setState({ loading: true });
    getNewsList("?limit=10").then((res) => {
      if (res && res.status === 200) {
        this.setState({ newsData: res.data });
      }
      this.setState({ loading: false });
    });
  };

  getAlerts = () => {
    this.setState({ loading: true });
    let { bacUserData } = this.state;
    getAlertList(bacUserData.id).then((res) => {
      if (res && res.status === 200) {
        this.setState({ alertData: res.data });
      }
      this.setState({ loading: false });
    });
    getPersonnelAlert(bacUserData.id).then((res) => {
      if (res && res.status === 200) {
        this.setState({ personnelAlertData: res.data });
      }
      this.setState({ loading: false });
    });
  };
  onClickAllNews = () => {
    getNewsList("?limit=10").then((res) => {
      if (res && res.status === 200) {
        this.setState({ newsDataAll: res.data });
      }
    });
  };

  onClickItem = (data) => {
    this.setState({ currentNews: data, openNews: true });
    updateReadAlert(data.id).then((res) => {
      if (res && res.status === 200) this.props.history.push("/Home");
    });
  };
  onClickItemNews = (data) => {
    this.setState({ currentNews: data, openNews: true });
    updateReadNews(data.id).then((res) => {
      if (res && res.status === 200) this.props.history.push("/Home");
    });
  };

  confirmPriority = (data) => {
    this.close();
    updateReadAlert(data.id).then((res) => {
      if (res && res.status === 200) this.props.history.push("/Home");
    });
  };
  close = () => this.setState({ openNews: false, openAllNews: false });
  getDataAlert = (qString) => {
    getNewsList(qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({ AlertData: res.data });
      }
    });
  };

  render() {
    const state = this.state;
    return (
      <div style={{ padding: "20px" }}>
        <br />
        <Grid columns={3} doubling>
          <Grid.Row stretched>
            <Grid.Column>
              <Card fluid>
                <Card.Content textAlign="left">
                  <Card.Header style={{ color: config.colorTheme }}>
                    Today
                  </Card.Header>
                  <ScheduleList />
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <Card fluid>
                <Card.Content textAlign="left">
                  <Card.Header style={{ color: config.colorTheme }}>
                    <Grid>
                      <Grid.Column
                        floated="left"
                        computer={8}
                        tablet={8}
                        mobile={8}
                      >
                        Alerts
                      </Grid.Column>
                      <Grid.Column
                        floated="right"
                        computer={8}
                        tablet={8}
                        mobile={8}
                        textAlign="right"
                        verticalAlign="bottom"
                        style={{ paddingLeft: "0px" }}
                      >
                        {state.alertData.length > 3 && (
                          <h5
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                            onClick={() =>
                              this.setState({ openAllAlerts: true })
                            }
                          >
                            Read more
                          </h5>
                        )}
                      </Grid.Column>
                    </Grid>
                  </Card.Header>
                  <List>
                    {state.loading ? (
                      <div>
                        <Icon loading name="spinner" /> Loading
                      </div>
                    ) : state.alertData.length === 0 ? (
                      <div>
                        No data visible.
                        <br />
                        <br />
                        <br />
                        <br />
                      </div>
                    ) : (
                      state.alertData.slice(0, 3).map((item) => (
                        <List.Item
                          style={{ padding: 5 }}
                          as="a"
                          onClick={() => this.onClickItem(item)}
                        >
                          {item.type === "Alert" ? (
                            <Icon
                              style={{ paddingTop: "5px" }}
                              color={
                                item.priority === 1
                                  ? "red"
                                  : item.priority === 2
                                    ? "yellow"
                                    : "green"
                              }
                              name="alarm "
                            />
                          ) : (
                            <Icon
                              style={{ paddingTop: "5px" }}
                              color="green"
                              name="thumbtack"
                            />
                          )}
                          <List.Content>
                            <List.Header>
                              <h5>{item.topic}</h5>
                            </List.Header>
                            <List.Description>
                              {" "}
                              {moment(item.created_at).format(
                                "DD MMM YYYY HH:mm:ss"
                              )}{" "}
                            </List.Description>
                          </List.Content>
                        </List.Item>
                      ))
                    )}
                  </List>
                </Card.Content>
              </Card>
              <Card fluid style={{ margin: "0px" }}>
                <Card.Content textAlign="left" style={{ paddingLeft: "20px" }}>
                  {state.loading ? (
                    <div>
                      <Icon loading name="spinner" /> Loading
                    </div>
                  ) : (
                    <List relaxed>
                      <List.Item>
                        <List.Content floated="right">
                          Expired Date:
                          {state.personnelAlertData &&
                            state.personnelAlertData.medicalexpirydate ? (
                            moment().isAfter(
                              moment(state.personnelAlertData.medicalexpirydate)
                            ) ? (
                              <span style={{ color: "red" }}>
                                <Icon name="warning sign" />
                                {state.personnelAlertData.medicalexpirydate}
                              </span>
                            ) : moment().isAfter(
                              moment(
                                state.personnelAlertData.medicalexpirydate
                              ).subtract(30, "days")
                            ) ? (
                              <span style={{ color: "orange" }}>
                                <Icon name="warning sign" />
                                {state.personnelAlertData.medicalexpirydate}
                              </span>
                            ) : (
                              state.personnelAlertData.medicalexpirydate
                            )
                          ) : (
                            "-"
                          )}
                        </List.Content>
                        <List.Content>
                          <List.Header>Medical Certificate</List.Header>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Content floated="right">
                          {state.bac_role.toString() === "Student"
                            ? "SPL Expired Date:"
                            : "CPL/ATPL Expired Date:"}
                          {state.personnelAlertData &&
                            state.personnelAlertData.splexpirydate ? (
                            moment().isAfter(
                              moment(state.personnelAlertData.splexpirydate)
                            ) ? (
                              <span style={{ color: "red" }}>
                                <Icon name="warning sign" />
                                {state.personnelAlertData.splexpirydate}
                              </span>
                            ) : moment().isAfter(
                              moment(
                                state.personnelAlertData.splexpirydate
                              ).subtract(30, "days")
                            ) ? (
                              <span style={{ color: "orange" }}>
                                {state.personnelAlertData.splexpirydate}
                              </span>
                            ) : (
                              state.personnelAlertData.splexpirydate
                            )
                          ) : (
                            "-"
                          )}
                        </List.Content>
                        <List.Content>
                          <List.Header>
                            {state.bac_role.toString() === "Student"
                              ? "Student Pilot License"
                              : "Pilot License"}
                          </List.Header>
                        </List.Content>
                      </List.Item>

                      <List.Item>
                        <List.Content floated="right">
                          Expired Date:{" "}
                          {state.personnelAlertData &&
                            state.personnelAlertData.dcpexpirydate ? (
                            moment().isAfter(
                              moment(state.personnelAlertData.dcpexpirydate)
                            ) ? (
                              <span style={{ color: "red" }}>
                                <Icon name="warning sign" />
                                {state.personnelAlertData.dcpexpirydate}
                              </span>
                            ) : moment().isAfter(
                              moment(
                                state.personnelAlertData.dcpexpirydate
                              ).subtract(30, "days")
                            ) ? (
                              <span style={{ color: "orange" }}>
                                {state.personnelAlertData.dcpexpirydate}
                              </span>
                            ) : (
                              state.personnelAlertData.dcpexpirydate
                            )
                          ) : (
                            "-"
                          )}
                        </List.Content>
                        <List.Content>
                          <List.Header>DCP License</List.Header>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        <List.Content floated="right">
                          {state.personnelAlertData &&
                            state.personnelAlertData.lastFlight ? (
                            moment().diff(
                              moment(state.personnelAlertData.lastFlight),
                              "days"
                            ) >= 45 ? (
                              <span style={{ color: "red" }}>
                                {state.personnelAlertData.lastFlight}
                              </span>
                            ) : (
                              state.personnelAlertData.lastFlight
                            )
                          ) : (
                            "-"
                          )}
                        </List.Content>
                        <List.Content>
                          <List.Header>Last Flight</List.Header>
                        </List.Content>
                      </List.Item>
                    </List>
                  )}
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card fluid>
                <Card.Content textAlign="left">
                  <Card.Header style={{ color: config.colorTheme }}>
                    <Grid>
                      <Grid.Column
                        floated="left"
                        computer={8}
                        tablet={8}
                        mobile={8}
                      >
                        News
                      </Grid.Column>
                      <Grid.Column
                        floated="right"
                        computer={8}
                        tablet={8}
                        mobile={8}
                        textAlign="right"
                        verticalAlign="bottom"
                        style={{ paddingLeft: "0px" }}
                      >
                        {state.newsData.length > state.limitNews && (
                          <h5
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                            onClick={() => this.setState({ openAllNews: true })}
                          >
                            Read more
                          </h5>
                        )}
                      </Grid.Column>
                    </Grid>
                  </Card.Header>
                  <List>
                    {state.loading ? (
                      <div>
                        <Icon loading name="spinner" /> Loading
                      </div>
                    ) : state.newsData.length === 0 ? (
                      <div>No data visible.</div>
                    ) : (
                      state.newsData.slice(0, state.limitNews).map((item) => (
                        <List.Item
                          style={{ padding: 5 }}
                          as="a"
                          onClick={() => this.onClickItemNews(item)}
                        >
                          <Icon
                            style={{ paddingTop: "5px" }}
                            color="green"
                            name="thumbtack"
                          />
                          <List.Content>
                            <List.Header>
                              <h5>{item.topic}</h5>
                            </List.Header>
                            <List.Description
                              style={{ overflowWrap: "anywhere" }}
                            >
                              {item.detail}

                              {/* {moment(item.created_at).format(
                            "DD MMM YYYY HH:mm:ss"
                          )}{" "} */}
                            </List.Description>
                          </List.Content>
                        </List.Item>
                      ))
                    )}
                  </List>
                </Card.Content>
              </Card>
              {state.canSeeAlertAll && (
                <Card fluid style={{ margin: "0px" }}>
                  <Card.Content
                    textAlign="left"
                    style={{ paddingLeft: "20px" }}
                  >
                    <List relaxed>
                      <List.Item>
                        {state.alertList && state.alertList.medical && (
                          <Wrap>
                            <List.Content floated="right">
                              {state.alertList.medical.students &&
                                state.alertList.medical.students > 0 && (
                                  <p
                                    as="a"
                                    style={{
                                      cursor: "pointer",
                                      color: "red",
                                      fontWeight: "bold",
                                      textDecoration: "underline",
                                    }}
                                    onClick={() =>
                                      this.getPersonnelAlertNameList(
                                        "medical",
                                        "student"
                                      )
                                    }
                                  >
                                    Student ({state.alertList.medical.students})
                                  </p>
                                )}
                            </List.Content>
                            <List.Content floated="right">
                              {state.alertList.medical.instructors &&
                                state.alertList.medical.instructors > 0 && (
                                  <p
                                    as="a"
                                    style={{
                                      cursor: "pointer",
                                      color: "red",
                                      fontWeight: "bold",
                                      textDecoration: "underline",
                                    }}
                                    onClick={() =>
                                      this.getPersonnelAlertNameList(
                                        "medical",
                                        "instructor"
                                      )
                                    }
                                  >
                                    Instructor (
                                    {state.alertList.medical.instructors})
                                  </p>
                                )}
                            </List.Content>
                          </Wrap>
                        )}
                        <List.Content>
                          <List.Header>Medical Certificate</List.Header>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        {state.alertList && state.alertList.spl && (
                          <List.Content floated="right">
                            {state.alertList.spl.instructors &&
                              state.alertList.spl.instructors > 0 && (
                                <p
                                  as="a"
                                  style={{
                                    cursor: "pointer",
                                    color: "red",
                                    fontWeight: "bold",
                                    textDecoration: "underline",
                                  }}
                                  onClick={() =>
                                    this.getPersonnelAlertNameList(
                                      "spl",
                                      "instructor"
                                    )
                                  }
                                >
                                  Instructor ({state.alertList.spl.instructors})
                                </p>
                              )}
                          </List.Content>
                        )}
                        <List.Content>
                          <List.Header>Pilot License</List.Header>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        {state.alertList && state.alertList.spl && (
                          <List.Content floated="right">
                            {state.alertList.spl.students &&
                              state.alertList.spl.students > 0 && (
                                <p
                                  as="a"
                                  style={{
                                    cursor: "pointer",
                                    color: "red",
                                    fontWeight: "bold",
                                    textDecoration: "underline",
                                  }}
                                  onClick={() =>
                                    this.getPersonnelAlertNameList(
                                      "spl",
                                      "student"
                                    )
                                  }
                                >
                                  Student ({state.alertList.spl.students})
                                </p>
                              )}
                          </List.Content>
                        )}
                        <List.Content>
                          <List.Header>Student Pilot License</List.Header>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        {state.alertList && state.alertList.dcp && (
                          <List.Content floated="right">
                            {state.alertList.dcp.instructors &&
                              state.alertList.dcp.instructors > 0 && (
                                <p
                                  as="a"
                                  style={{
                                    cursor: "pointer",
                                    color: "red",
                                    fontWeight: "bold",
                                    textDecoration: "underline",
                                  }}
                                  onClick={() =>
                                    this.getPersonnelAlertNameList(
                                      "dcp",
                                      "instructor"
                                    )
                                  }
                                >
                                  Instructor ({state.alertList.dcp.instructors})
                                </p>
                              )}
                          </List.Content>
                        )}
                        <List.Content>
                          <List.Header>DCP License</List.Header>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        {state.alertFlightList &&
                          state.alertFlightList.lastFlight && (
                            <Wrap>
                              <List.Content floated="right">
                                {state.alertFlightList.lastFlight.instructors &&
                                  state.alertFlightList.lastFlight.instructors >
                                  0 && (
                                    <p
                                      as="a"
                                      style={{
                                        cursor: "pointer",
                                        color: "red",
                                        fontWeight: "bold",
                                        textDecoration: "underline",
                                      }}
                                      onClick={() =>
                                        this.getPersonnelAlertNameList(
                                          "lastFlight",
                                          "instructor"
                                        )
                                      }
                                    >
                                      Instructor (
                                      {
                                        state.alertFlightList.lastFlight
                                          .instructors
                                      }
                                      )
                                    </p>
                                  )}
                              </List.Content>
                              <List.Content floated="right">
                                {state.alertFlightList.lastFlight.students &&
                                  state.alertFlightList.lastFlight.students >
                                  0 && (
                                    <p
                                      as="a"
                                      style={{
                                        cursor: "pointer",
                                        color: "red",
                                        fontWeight: "bold",
                                        textDecoration: "underline",
                                      }}
                                      onClick={() =>
                                        this.getPersonnelAlertNameList(
                                          "lastFlight",
                                          "student"
                                        )
                                      }
                                    >
                                      Student (
                                      {
                                        state.alertFlightList.lastFlight
                                          .students
                                      }
                                      )
                                    </p>
                                  )}
                              </List.Content>
                            </Wrap>
                          )}
                        <List.Content>
                          <List.Header>Last Flight</List.Header>
                        </List.Content>
                      </List.Item>
                      <List.Item>
                        {
                          state.alertDropList && (
                            <Wrap>
                              <List.Content floated="right">
                                {state.alertDropList.length >
                                  0 && (
                                    <p
                                      as="a"
                                      style={{
                                        cursor: "pointer",
                                        color: "red",
                                        fontWeight: "bold",
                                        textDecoration: "underline",
                                      }}
                                      onClick={() =>
                                        this.setState({
                                          openNameList: true,
                                          contentNameList: 'Drop End Date',
                                          dataNameList: state.alertDropList,
                                        })
                                      }
                                    >
                                      Student (
                                      {
                                        state.alertDropList.length
                                      }
                                      )
                                    </p>
                                  )}
                              </List.Content>
                            </Wrap>
                          )}
                        <List.Content>
                          <List.Header>Drop End Date</List.Header>
                        </List.Content>
                      </List.Item>

                    </List>
                  </Card.Content>
                </Card>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {/* <Card.Group itemsPerRow={3} stackable basic>
        </Card.Group> */}

        <Card fluid>
          <Card.Content textAlign="left" style={{ padding: "14px" }}>
            <Card.Header style={{ color: config.colorTheme }}>
              Schedule
            </Card.Header>
            <ScheduleById
              disableClick={
                this.checkPermissionMenu("Schedule", ["manage-schedule"]) === true
                  ? undefined
                  : true
              }
              canManage={this.checkPermissionMenu("Schedule", ["manage-schedule"])}
              id={state.bacUserData.id} />
          </Card.Content>
        </Card>
        <Modal
          open={state.openNews}
          closeIcon={
            state.currentNews.type === "Alert"
              ? state.currentNews.priority === 1
                ? false
                : true
              : true
          }
          centered={false}
          dimmer="blurring"
          size={"tiny"}
          closeOnDimmerClick={false}
          onClose={() => this.setState({ openNews: false })}
        >
          <Header
            icon={state.currentNews.type === "Alert" ? "alarm" : "thumbtack"}
            content={state.currentNews.type === "Alert" ? "Alert" : "News"}
            style={
              state.currentNews.type === "Alert"
                ? { backgroundColor: "red", color: "#ffffff", padding: "10px" }
                : {
                  backgroundColor: "#21ba45",
                  color: "#ffffff",
                  padding: "10px",
                }
            }
          />
          <Modal.Content>
            <h2 style={{ textAlign: "center", paddingTop: "20px" }}>
              {state.currentNews.topic}
            </h2>
            {state.currentNews.imageUrl && (
              <Image
                style={{ padding: 10 }}
                centered
                src={state.currentNews.imageUrl}
              />
            )}
            <p style={{ textAlign: "center" }}>{state.currentNews.detail}</p>
            {state.currentNews.fileUrl &&
              state.currentNews.fileUrl.map((item) => (
                <Wrap>
                  <Icon name="file pdf outline" />
                  <a href={item} download target="_blank">
                    Download file
                  </a>
                  <br />
                </Wrap>
              ))}
            <br />
            {state.currentNews.link && (
              <Wrap>
                <Icon name="linkify" />
                <a href={state.currentNews.link} download target="_blank">
                  Open link
                </a>
              </Wrap>
            )}
            <br />
            <br />
            <p style={{ textAlign: "right", color: "grey" }}>
              created at{" "}
              {moment(state.currentNews.created_at).format(
                "DD MMM YYYY HH:mm:ss"
              )}
            </p>
            {state.currentNews.type === "Alert" &&
              state.currentNews.priority === 1 && (
                <Button
                  inverted
                  color="red"
                  onClick={() => this.confirmPriority(state.currentNews)}
                >
                  Confirm
                </Button>
              )}
          </Modal.Content>
        </Modal>
        <ModalForm
          content="Alert"
          open={state.openAllAlerts}
          close={() => this.setState({ openAllAlerts: false })}
        >
          <List>
            {state.loading ? (
              <div>
                <Icon loading name="spinner" /> Loading
              </div>
            ) : (
              state.alertData.map((item) => (
                <List.Item
                  style={{ padding: 5 }}
                  as="a"
                  onClick={() => this.onClickItem(item)}
                >
                  {item.type === "Alert" ? (
                    <Icon
                      style={{ paddingTop: "5px" }}
                      color={
                        item.priority === 1
                          ? "red"
                          : item.priority === 2
                            ? "yellow"
                            : "green"
                      }
                      name="alarm "
                    />
                  ) : (
                    <Icon
                      style={{ paddingTop: "5px" }}
                      color="green"
                      name="thumbtack"
                    />
                  )}
                  <List.Content>
                    <List.Header>
                      <h5>{item.topic}</h5>
                    </List.Header>
                    <List.Description>
                      {item.detail}
                      <div style={{ paddingTop: "5px" }}>
                        created at{" "}
                        {moment(item.created_at).format("DD MMM YYYY HH:mm:ss")}
                      </div>
                    </List.Description>
                  </List.Content>
                </List.Item>
              ))
            )}
          </List>
        </ModalForm>
        <ModalForm
          content="News"
          open={state.openAllNews}
          close={() => this.setState({ openAllNews: false })}
        >
          <List>
            {state.loading ? (
              <div>
                <Icon loading name="spinner" /> Loading
              </div>
            ) : (
              state.newsData.map((item) => (
                <List.Item
                  style={{ padding: 5, paddingBottom: "20px" }}
                  as="a"
                  onClick={() => this.onClickItemNews(item)}
                >
                  <Icon
                    style={{ paddingTop: "5px" }}
                    color="green"
                    name="thumbtack"
                  />
                  <List.Content>
                    <List.Header>{item.topic}</List.Header>
                    <List.Description style={{ paddingTop: "5px" }}>
                      {item.detail}
                      <div style={{ paddingTop: "5px" }}>
                        created at{" "}
                        {moment(item.created_at).format("DD MMM YYYY HH:mm:ss")}
                      </div>
                    </List.Description>
                  </List.Content>
                </List.Item>
              ))
            )}
          </List>
        </ModalForm>
        <Modal
          open={state.openNameList}
          closeIcon={true}
          centered={false}
          dimmer="blurring"
          size={"small"}
          closeOnDimmerClick={false}
          onClose={() => this.setState({ openNameList: false })}
        >
          <Header
            icon="users"
            content={state.contentNameList + "  expired list"}
          />
          <Modal.Content>
            {state.loadingNameList ? (
              <div>
                <Icon loading name="spinner" /> Loading
              </div>
            ) : (
              state.dataNameList &&
              state.dataNameList.length > 0 && (
                <Table celled striped unstackable compact>
                  <Table.Header>
                    <Table.Row textAlign="center">
                      <Table.HeaderCell>Name</Table.HeaderCell>
                      <Table.HeaderCell>Date</Table.HeaderCell>
                      <Table.HeaderCell>BAC Email</Table.HeaderCell>
                      <Table.HeaderCell>Phone</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {state.dataNameList.map((item) => (
                      <Table.Row>
                        <Table.Cell>{item.name}</Table.Cell>
                        {item.expirydate ? (
                          <Table.Cell textAlign="center">
                            {moment().isAfter(moment(item.expirydate)) ? (
                              <span style={{ color: "red" }}>
                                {item.expirydate}
                              </span>
                            ) : moment().isAfter(
                              moment(item.expirydate).subtract(30, "days")
                            ) ? (
                              <span style={{ color: "orange" }}>
                                {item.expirydate}
                              </span>
                            ) : (
                              item.expirydate
                            )}
                          </Table.Cell>
                        ) : item.endDrop ? <Table.Cell textAlign="center">

                          <span style={{ color: "red" }}>
                            {item.endDrop}
                          </span>

                        </Table.Cell>
                          : (
                            <Table.Cell textAlign="center">
                              {moment().diff(moment(item.lastFlight), "days") >
                                44 ? (
                                <span style={{ color: "red" }}>
                                  {item.lastFlight}
                                </span>
                              ) : moment().diff(moment(item.lastFlight), "days") >
                                34 ? (
                                <span style={{ color: "orange" }}>
                                  {item.lastFlight}
                                </span>
                              ) : (
                                item.lastFlight
                              )}
                            </Table.Cell>
                          )}
                        <Table.Cell>{item.emailAddress}</Table.Cell>
                        <Table.Cell>{item.phoneNumber}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              )
            )}
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Home);
