import React, { Component } from "react";
import {
  Container,
  Header,
  Form,
  Input,
  Button,
  Image,
  Grid,
  Divider,
  List,
  Label,
  Table,
  Radio,
} from "semantic-ui-react";
import {
  postFlightTraningRecord,
  approveFlightTraningRecord,
} from "../service";
import { getSnagById } from "../../Portfolio/service";
import moment from "moment";
import Wrap from "../../../components/wrap";
import Confirmmodal from "../../../components/customconfirmmodal";
import { withRouter } from "react-router-dom";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import draftToMarkdown from 'draftjs-to-markdown';

class ScoreEdit extends Component {
  state = {
    editorState: EditorState.createEmpty(),
    open: false,
    dataInstructor: {
      username: {
        value: "",
        validation: {
          required: true,
        },
        valid: false,
      },
      password: {
        value: "",
        validation: {
          required: true,
          minLength: 6,
        },
        valid: false,
      },
    },
    errormsg: null,
    locationState: this.props.locationState,
    // rawData: [],
    // data: [],
    loading: false,
    // lessonRaw: [],
    // lesson: [],
    // criteria: [],
    // comment:{
    //   student: null,
    //   instructor: null,
    // },
    // totalthisflight: 0,
    // repeatItem: [],
    openConfirm: false,

    data: this.props.data,
    lesson: this.props.data.lesson,
    lessonRaw: this.props.data.lesson,
    criteria: this.props.data.criteria,
    totalthisflight: this.props.data.totalthisflight,
    comment: this.props.data.comment,
    repeatItem: this.props.data.repeatItem,
    rawRepeatItem: this.props.data.repeatItem,
    accumulate: this.props.data.student.accumulate,
    passed: this.props.data.passed,
    grade_type: this.props.data.grade_type,
  };

  onEditorStateChange = (editorState) => {
    let { comment } = this.state
    comment.student = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    this.setState({
      editorState,
      comment,
    });
  };

  close = () =>
    this.setState({ open: false, openConfirm: false, typeConfirm: null });

  handleSubmit = () => {
    // this.setState({loading: true})
    let {
      lesson,
      comment,
      criteria,
      totalthisflight,
      repeatItem,
      rawRepeatItem,
      locationState,
      editorState
    } = this.state;

    let data = {
      lesson: lesson,
      comment: comment,
      criteria: criteria,
      totalthisflight: totalthisflight,
      // repeatItem: repeatItem
      repeatItem: repeatItem.filter((re) => re.status != "pass"),
    };


    postFlightTraningRecord(locationState.id, data).then((res) => {
      if (res && res.status === 200) {
        this.setState({ data: [] });
        const { locationState } = this.state;
        this.props.getData(locationState.id);
        this.props.setPage();
      }
      this.setState({ loading: false });
    });
  };
  handleApprove = () => {
    this.setState({ openConfirm: true, typeConfirm: "Approve" });
  };
  onApprove = () => {
    this.setState({ loading: true });
    let {
      lesson,
      comment,
      criteria,
      totalthisflight,
      repeatItem,
      locationState,
      passed,
    } = this.state;
    let data = {
      // approved: instructor_data.id,
      lesson: lesson,
      comment: comment,
      criteria: criteria,
      totalthisflight: totalthisflight,
      // repeatItem: repeatItem
      passed: passed,
      repeatItem: repeatItem.filter((re) => re.status != "pass"),
    };
    approveFlightTraningRecord(locationState.id, data).then((res) => {
      if (res && res.status === 200) {
        this.setState({ data: [], typeConfirm: null });
        const { locationState } = this.state;
        this.props.getData(locationState.id);
        this.props.setPage();
      }
      this.setState({ loading: false });
    });
  };
  handleChange = (e, { name, value }) => {
    // this.setState({ [name]: value })
    if (name === "totalthisflight") {
      this.setState({ totalthisflight: parseInt(value) });
    } else {
      let { criteria } = this.state;
      criteria.map((cri) => {
        if (cri.id === parseInt(name.substr(1))) {
          cri.value = value;
        }
      });
      this.setState({ criteria });
    }
  };
  handleChangePassed = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  handleChangeComment = (e, { name, value }) => {
    let { comment } = this.state;
    if (name === "student") {
      comment.student = value;
    } else if (name === "instructor") {
      comment.instructor = value;
    }
  };
  handleChangeLesson = (e, { name, value }) => {
    let { lesson, repeatItem } = this.state;
    if (lesson[name])
      lesson[name].value = value;
    // lesson.map((les,index) => {
    //   // console.log('les',les)
    //   if (index === name) {
    //     les.value = value;
    //   }
    // });

    let filteredRepeatItems = lesson.filter(function (item) {
      if (item.MR != "D") {
        return item.value < item.MR;
      }
    });
    this.setState({ repeatItem: filteredRepeatItems });
  };

  handleChangeRemark = (e, { name, value }) => {
    let lesson = [...this.state.lesson];

    lesson = lesson.map((les) => {
      if (les.id === name) {
        return { ...les, remark: value };
      }
      return les;
    });

    this.setState({ lesson });
  };

  validateRemark = () => {
    let lesson = [...this.state.lesson];
    let hasInvalidRemark = 0;

    lesson.forEach((item,index)=>{
        if (item && item.value){
          if (item.value){
            if (item.value.toString() !== item.MR.toString()){
                if (!item.remark || item.remark ===''){
                  hasInvalidRemark += 1
                }
            }
          }
        }
    })
    return hasInvalidRemark > 0;
  }




  getSnag = (id) => {
    getSnagById(id).then((res) => {
      if (res && res.status === 200) {
        this.setState({ snag: res.data });
      }
    });
  };

  editSnag = (data) => {
    console.log("editSnag", data);
    const { locationState } = this.props;
    this.props.history.push({
      pathname: "/AdminTrainRecord/Score",
      state: { id: data.reservationId, data: locationState },
    });
  };
  // getById = (id) => {
  //   getFlightTraningRecordById(id).then(res => {
  //     if (res && res.status === 200) {
  //       console.log('getFlightTraningRecordById',res);
  //       this.setState({
  //         data: res.data,
  //         lesson: res.data.lesson,
  //         lessonRaw: res.data.lesson,
  //         criteria: res.data.criteria,
  //         totalthisflight: res.data.totalthisflight,
  //         comment: res.data.comment,
  //         repeatItem: res.data.repeatItem
  //       });
  //     }
  //     this.setState({ loading: false });
  //   });
  // };
  componentDidMount() {
    // let qString = "/1"; //Flight Training
    const { locationState, data } = this.state;
    // this.getById(locationState.id);
    this.getSnag(data.student.studentId);

    if (data.comment && data.comment.student) {
      const contentBlock = htmlToDraft(data.comment.student);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        this.setState({
          editorState
        })
      }
    }
  }

  render() {
    this.validateRemark()
    const state = this.state;
    const { locationState } = this.state;
    // console.log(state);
    return (
      <div>
        <Grid>
          <Grid.Row columns={3} textAlign="center">
            <Grid.Column width={5}>
              <Image
                centered
                src="https://ba.ac.th/web-assets/img/logo-bac.png"
                style={{
                  height: "100%",
                  width: "auto",
                }}
              />
            </Grid.Column>
            <Grid.Column width={6}>
              <Header as="h1">
                <Header.Content>
                  FLIGHT TRAINING RECORD
                  <Header.Subheader>
                    Bangkok Aviation Center Public Company Limited
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </Grid.Column>
            <Grid.Column width={5}>
              <h1>{state.data.subject ? state.data.subject.code : "-"}</h1>
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row columns="equal">
            <Grid.Column>
              Student's name:{" "}
              {state.data.student ? (
                <b>{`${state.data.student.studentName}`}</b>
              ) : (
                "-"
              )}
            </Grid.Column>
            <Grid.Column>
              Date:{" "}
              {state.data.start ? (
                <b>{`${moment(state.data.start).format("YYYY-MM-DD")}`}</b>
              ) : (
                "-"
              )}
            </Grid.Column>
            <Grid.Column>
              Reg HS -{" "}
              {state.data.aircraftName ? (
                <b>{`${state.data.aircraftName}`}</b>
              ) : (
                "-"
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="equal">
            <Grid.Column>
              Instructor's name:{" "}
              {state.data.instructorName ? (
                <b>{`${state.data.instructorName}`}</b>
              ) : (
                "-"
              )}
            </Grid.Column>
            <Grid.Column>
              Subject:{" "}
              {state.data.name ? (
                <b>{`${state.data.name} (${state.data.hours})`}</b>
              ) : (
                "-"
              )}
            </Grid.Column>
            <Grid.Column>
              Route/Legs:{" "}
              {state.data.departure ? (
                <b>{`${state.data.departure} - ${state.data.destination}`}</b>
              ) : (
                "-"
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="equal">
            <Grid.Column>
              START: {state.data.ST ? <b>{`${state.data.ST}`}</b> : "-"}<br />
              TAKEOFF: {state.data.TO ? <b>{`${state.data.TO}`}</b>: "-"}
            </Grid.Column>
            <Grid.Column>
              LANDING: {state.data.LD ? <b>{`${state.data.LD}`}</b>:"-"}<br />
              SHUTDOWN: {state.data.SD ? <b>{`${state.data.SD}`}</b> : "-"}
              {/* SHUTDOWN: {state.data.SD ? <b>{`${state.data.SD}`}</b> : "-"} */}
            </Grid.Column>
            <Grid.Column>
              ACTUAL:{" "}
              {state.data.flightInstruction ? (
                <b>{`${state.data.flightInstruction}`}</b>
              ) : (
                "-"
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <br />
        <br />
        <Container>
          <p>
            <b>Objective:</b>{" "}
            {state.data.objective ? state.data.objective : "-"}
          </p>
          <p style={{ color: "red" }}>
            <b>Snag: &nbsp;&nbsp;</b>
            {state.snag && (
              <Wrap>
                {state.snag.flightSubjects.length > 0 &&
                  state.snag.flightSubjects.map(
                    (fight) =>
                      fight.subject.length > 0 &&
                      fight.subject.map((sub) => (
                        <Label
                          as="a"
                          color="red"
                          onClick={() => this.editSnag(sub)}
                        >
                          {sub.code} (
                          {sub.lesson.map((les, index) => {
                            if (index == 0) {
                              return <span>{les.name}</span>;
                            } else {
                              return <span>, {les.name}</span>;
                            }
                          })}
                          )
                        </Label>
                      ))
                  )}
              </Wrap>
            )}
            {/* groundSubjects */}
          </p>
        </Container>
        <br />
        {/*FIXME No.9*/}
        {this.state.grade_type && this.state.grade_type ===1 &&
            <Table celled compact>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell className="header-table" width={1}>
                    No.
                  </Table.HeaderCell>
                  <Table.HeaderCell className="header-table" width={5}>
                    Lesson
                  </Table.HeaderCell>
                  <Table.HeaderCell className="header-table" width={3}>
                    Reference
                  </Table.HeaderCell>
                  <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                      width={1}
                  >
                    MR
                  </Table.HeaderCell>

                  <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                      width={1}
                  >
                    1
                  </Table.HeaderCell>
                  <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                      width={1}
                  >
                    2
                  </Table.HeaderCell>
                  <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                      width={1}
                  >
                    3
                  </Table.HeaderCell>
                  <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                      width={1}
                  >
                    4
                  </Table.HeaderCell>
                  <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                      width={1}
                  >
                    5
                  </Table.HeaderCell>
                  <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                      width={2}
                  >
                    Remark
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {state.lesson &&
                    state.lesson.map((les, index) => {
                      {/*FIXME No.11*/}
                      return (
                          <Table.Row className={(les.highlight && les.highlight ===1)?'highlight':""}>
                            <Table.Cell width={1}>{index + 1}</Table.Cell>
                            <Table.Cell width={5}>{les.name}</Table.Cell>
                            <Table.Cell width={3}>
                              {les.url ? (
                                  <a href={les.url} target="_blank">
                                    {les.reference}
                                  </a>
                              ) : (
                                  les.reference
                              )}
                            </Table.Cell>
                            <Table.Cell textAlign="center" width={1}>
                              {les.MR}
                            </Table.Cell>
                            <Table.Cell textAlign="center" width={1}>
                              <Form.Field>
                                <Radio
                                    id={index + "1"}
                                    name={index}
                                    checked={les.value === "1"}
                                    value="1"
                                    onChange={this.handleChangeLesson}
                                />
                              </Form.Field>
                            </Table.Cell>
                            <Table.Cell textAlign="center" width={1}>
                              <Form.Field>
                                <Radio
                                    id={index + "2"}
                                    name={index}
                                    checked={les.value === "2"}
                                    value="2"
                                    onChange={this.handleChangeLesson}
                                />
                              </Form.Field>
                            </Table.Cell>
                            <Table.Cell textAlign="center" width={1}>
                              <Form.Field>
                                <Radio
                                    id={index + "3"}
                                    name={index}
                                    checked={les.value === "3"}
                                    value="3"
                                    onChange={this.handleChangeLesson}
                                />
                              </Form.Field>
                            </Table.Cell>
                            <Table.Cell textAlign="center" width={1}>
                              <Form.Field>
                                <Radio
                                    id={index + "4"}
                                    name={index}
                                    checked={les.value === "4"}
                                    value="4"
                                    onChange={this.handleChangeLesson}
                                />
                              </Form.Field>
                            </Table.Cell>
                            <Table.Cell textAlign="center" width={1}>
                              <Form.Field>
                                <Radio
                                    id={index + "5"}
                                    name={index}
                                    checked={les.value === "5"}
                                    value="5"
                                    onChange={this.handleChangeLesson}
                                />
                              </Form.Field>
                            </Table.Cell>
                            <Table.Cell textAlign="center" width={2}>
                              <Form>
                                <Form.Input
                                    name={les.id}
                                    type="text"
                                    size="mini"
                                    defaultValue={les.remark}
                                    required={les.value && les.value.toString() !== les.MR ? true : false}
                                    error={les.value && les.value.toString() !== les.MR && (!les.remark || les.remark.trim() === '') ? true : false}
                                    onChange={this.handleChangeRemark}
                                />
                              </Form>
                            </Table.Cell>
                          </Table.Row>
                      );
                    })}
              </Table.Body>
            </Table>
        }
        {this.state.grade_type && this.state.grade_type ===2 &&
            <Table celled compact>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell className="header-table" width={1}>
                    No.
                  </Table.HeaderCell>
                  <Table.HeaderCell className="header-table" width={5}>
                    Lesson
                  </Table.HeaderCell>
                  <Table.HeaderCell className="header-table" width={3}>
                    Reference
                  </Table.HeaderCell>
                  <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                      width={1}
                  >
                    MR
                  </Table.HeaderCell>
                  <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                      width={1}
                  >
                    I
                  </Table.HeaderCell>
                  <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                      width={1}
                  >
                    1
                  </Table.HeaderCell>
                  <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                      width={1}
                  >
                    2
                  </Table.HeaderCell>
                  <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                      width={1}
                  >
                    3
                  </Table.HeaderCell>
                  <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                      width={1}
                  >
                    4
                  </Table.HeaderCell>
                  <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                      width={2}
                  >
                    Remark
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {state.lesson &&
                    state.lesson.map((les, index) => {
                      {/*FIXME No.11*/}
                      return (
                          <Table.Row className={(les.highlight && les.highlight ===1)?'highlight':""}>
                            <Table.Cell width={1}>{index + 1}</Table.Cell>
                            <Table.Cell width={5}>{les.name}</Table.Cell>
                            <Table.Cell width={3}>
                              {les.url ? (
                                  <a href={les.url} target="_blank">
                                    {les.reference}
                                  </a>
                              ) : (
                                  les.reference
                              )}
                            </Table.Cell>
                            <Table.Cell textAlign="center" width={1}>
                              {les.MR}
                            </Table.Cell>
                            <Table.Cell textAlign="center" width={1}>
                              <Form.Field>
                                <Radio
                                    id={index + "1"}
                                    name={index}
                                    checked={les.value === "I"}
                                    value="I"
                                    onChange={this.handleChangeLesson}
                                />
                              </Form.Field>
                            </Table.Cell>
                            <Table.Cell textAlign="center" width={1}>
                              <Form.Field>
                                <Radio
                                    id={index + "2"}
                                    name={index}
                                    checked={les.value === "1"}
                                    value="1"
                                    onChange={this.handleChangeLesson}
                                />
                              </Form.Field>
                            </Table.Cell>
                            <Table.Cell textAlign="center" width={1}>
                              <Form.Field>
                                <Radio
                                    id={index + "3"}
                                    name={index}
                                    checked={les.value === "2"}
                                    value="2"
                                    onChange={this.handleChangeLesson}
                                />
                              </Form.Field>
                            </Table.Cell>
                            <Table.Cell textAlign="center" width={1}>
                              <Form.Field>
                                <Radio
                                    id={index + "4"}
                                    name={index}
                                    checked={les.value === "3"}
                                    value="3"
                                    onChange={this.handleChangeLesson}
                                />
                              </Form.Field>
                            </Table.Cell>
                            <Table.Cell textAlign="center" width={1}>
                              <Form.Field>
                                <Radio
                                    id={index + "5"}
                                    name={index}
                                    checked={les.value === "4"}
                                    value="4"
                                    onChange={this.handleChangeLesson}
                                />
                              </Form.Field>
                            </Table.Cell>
                            <Table.Cell textAlign="center" width={2}>
                              <Form>
                                <Form.Input
                                    name={les.id}
                                    type="text"
                                    size="mini"
                                    defaultValue={les.remark}
                                    required={les.value && les.value.toString() !== les.MR ? true : false}
                                    error={les.value && les.value.toString() !== les.MR && (!les.remark || les.remark.trim() === '') ? true : false}
                                    onChange={this.handleChangeRemark}
                                />
                              </Form>
                            </Table.Cell>
                          </Table.Row>
                      );
                    })}
              </Table.Body>
            </Table>
        }
        {!this.state.grade_type &&
            <Table celled compact>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell className="header-table" width={1}>
                    No.
                  </Table.HeaderCell>
                  <Table.HeaderCell className="header-table" width={5}>
                    Lesson
                  </Table.HeaderCell>
                  <Table.HeaderCell className="header-table" width={3}>
                    Reference
                  </Table.HeaderCell>
                  <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                      width={1}
                  >
                    MR
                  </Table.HeaderCell>
                  <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                      width={1}
                  >
                    0
                  </Table.HeaderCell>
                  <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                      width={1}
                  >
                    1
                  </Table.HeaderCell>
                  <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                      width={1}
                  >
                    2
                  </Table.HeaderCell>
                  <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                      width={1}
                  >
                    3
                  </Table.HeaderCell>
                  <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                      width={2}
                  >
                    Remark
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {state.lesson &&
                    state.lesson.map((les, index) => {
                      {/*FIXME No.11*/}
                      return (
                          <Table.Row className={(les.highlight && les.highlight ===1)?'highlight':""}>
                            <Table.Cell width={1}>{index + 1}</Table.Cell>
                            <Table.Cell width={5}>{les.name}</Table.Cell>
                            <Table.Cell width={3}>
                              {les.url ? (
                                  <a href={les.url} target="_blank">
                                    {les.reference}
                                  </a>
                              ) : (
                                  les.reference
                              )}
                            </Table.Cell>
                            <Table.Cell textAlign="center" width={1}>
                              {les.MR}
                            </Table.Cell>
                            <Table.Cell textAlign="center" width={1}>
                              <Form.Field>
                                <Radio
                                    id={index + "1"}
                                    name={index}
                                    checked={les.value === "0"}
                                    value="0"
                                    onChange={this.handleChangeLesson}
                                />
                              </Form.Field>
                            </Table.Cell>
                            <Table.Cell textAlign="center" width={1}>
                              <Form.Field>
                                <Radio
                                    id={index + "2"}
                                    name={index}
                                    checked={les.value === "1"}
                                    value="1"
                                    onChange={this.handleChangeLesson}
                                />
                              </Form.Field>
                            </Table.Cell>
                            <Table.Cell textAlign="center" width={1}>
                              <Form.Field>
                                <Radio
                                    id={index + "3"}
                                    name={index}
                                    checked={les.value === "2"}
                                    value="2"
                                    onChange={this.handleChangeLesson}
                                />
                              </Form.Field>
                            </Table.Cell>
                            <Table.Cell textAlign="center" width={1}>
                              <Form.Field>
                                <Radio
                                    id={index + "4"}
                                    name={index}
                                    checked={les.value === "3"}
                                    value="3"
                                    onChange={this.handleChangeLesson}
                                />
                              </Form.Field>
                            </Table.Cell>
                            <Table.Cell textAlign="center" width={2}>
                              <Form>
                                <Form.Input
                                    name={les.id}
                                    type="text"
                                    size="mini"
                                    defaultValue={les.remark}
                                    required={les.value && les.value.toString() !== les.MR ? true : false}
                                    error={les.value && les.value.toString() !== les.MR && (!les.remark || les.remark.trim() === '') ? true : false}
                                    onChange={this.handleChangeRemark}
                                />
                              </Form>
                            </Table.Cell>
                          </Table.Row>
                      );
                    })}
              </Table.Body>
            </Table>
        }

        <br />
        <Grid>
          <Grid.Row textAlign="center">
            <Grid.Column>
              <Divider horizontal>
                <b>COMMENT</b>
              </Divider>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="equal">
            <Grid.Column>
              {/* <Form>
                <Form.TextArea
                  name="student"
                  // placeholder="Comment..."
                  // defaultValue={state.comment.student}
                  value={state.editorState && draftToHtml(convertToRaw(state.editorState.getCurrentContent()))}
                  onChange={this.handleChangeComment}
                />
              </Form> */}
              {/* <Form.TextArea
                disabled
                value={editorState && draftToMarkdown(convertToRaw(editorState.getCurrentContent()))}
              /> */}
              <Editor
                editorState={state.editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={this.onEditorStateChange}
                toolbar={{
                  options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
                  inline: { inDropdown: true },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: true },
                }}
              />

              {
                //mook
              }
            </Grid.Column>
            {/* <Grid.Column>
              <Form>
                <Form.TextArea name='instructor' label='Instructor' placeholder='Comment Instructor' defaultValue={state.comment.instructor} onChange={this.handleChangeComment}/>
              </Form>
            </Grid.Column> */}
          </Grid.Row>
          <Grid.Row columns="equal">
            <Grid.Column>
              <Grid>
                <Divider horizontal>Repeat item</Divider>
                <Grid.Row columns="equal">
                  <Grid.Column>
                    {state.repeatItem && state.repeatItem.length > 0 && (
                      <List as="ul">
                        {state.repeatItem.map((rep) =>
                          rep.status === "pass" ? (
                            <List.Item as="li" style={{ color: "green" }}>
                              {rep.name} : Failed Score {rep.value} (Passed on{" "}
                              {moment(rep.passedAt).format("MMM DD, YYYY")})
                            </List.Item>
                          ) : (
                            <List.Item as="li" style={{ color: "red" }}>
                              {rep.name}
                            </List.Item>
                          )
                        )}
                      </List>
                    )}
                  </Grid.Column>
                </Grid.Row>
                <Divider horizontal>No. of landing</Divider>
                <Grid.Row columns="equal">
                  <Grid.Column>
                    <Form>
                      <Form.Field inline>
                        {/*<Form.Group widths='equal'>*/}
                        <b>Total this flight:</b>
                        <Input
                          type="number"
                          min={0}
                          name="totalthisflight"
                          defaultValue={state.data.totalthisflight}
                          onChange={this.handleChange}
                        />
                        {/*<Form.Input fluid label='Accumulate:' placeholder='Last name' />
                    </Form.Group>*/}
                      </Form.Field>
                    </Form>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns="equal">
                  <Grid.Column>
                    <p>
                      <b>
                        Total to Date: {state.accumulate ? state.accumulate : 0}
                      </b>
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Divider horizontal>Accumulate hour</Divider>
                <Grid.Row columns="equal">
                  <Grid.Column>
                    <p>
                      <b>
                        Accumulate hour:{" "}
                        {state.data.student
                          ? state.data.student.accumulate_hour
                          : "-"}{" "}
                      </b>
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column>
              <Table compact celled striped>
                <Table.Header>
                  <Table.Row>
                    {/*FIXME No.10*/}
                    <Table.HeaderCell className="header-table" width={12}>
                      Evaluation criteria
                    </Table.HeaderCell>
                    <Table.HeaderCell
                        className="header-table"
                        textAlign="center"
                        width={1}
                    >
                      N/A
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                      width={1}
                    >
                      1
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                      width={1}
                    >
                      2
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                      width={1}
                    >
                      3
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                      width={1}
                    >
                      4
                    </Table.HeaderCell>
                    <Table.HeaderCell
                        className="header-table"
                        textAlign="center"
                        width={1}
                    >
                      5
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {state.criteria &&
                    state.criteria.map((criteria) => {
                      return (
                          <Table.Row className={(criteria.highlight && criteria.highlight ===1)?'highlight':""}>
                          <Table.Cell width={12}>{criteria.name}</Table.Cell>
                          <Table.Cell textAlign="center" width={1}>
                            <Form.Field>
                              <Radio
                                  id={criteria.name + "N/A"}
                                  name={"c" + criteria.id}
                                  checked={criteria.value === "N/A"}
                                  value="N/A"
                                  onChange={this.handleChange}
                              />
                            </Form.Field>
                          </Table.Cell>
                          <Table.Cell textAlign="center" width={1}>
                            <Form.Field>
                              <Radio
                                id={criteria.name + "1"}
                                name={"c" + criteria.id}
                                checked={criteria.value === "1"}
                                value="1"
                                onChange={this.handleChange}
                              />
                            </Form.Field>
                          </Table.Cell>
                          <Table.Cell textAlign="center" width={1}>
                            <Form.Field>
                              <Radio
                                id={criteria.name + "2"}
                                name={"c" + criteria.id}
                                checked={criteria.value === "2"}
                                value="2"
                                onChange={this.handleChange}
                              />
                            </Form.Field>
                          </Table.Cell>
                          <Table.Cell textAlign="center" width={1}>
                            <Form.Field>
                              <Radio
                                id={criteria.name + "3"}
                                name={"c" + criteria.id}
                                checked={criteria.value === "3"}
                                value="3"
                                onChange={this.handleChange}
                              />
                            </Form.Field>
                          </Table.Cell>
                          <Table.Cell textAlign="center" width={1}>
                            <Form.Field>
                              <Radio
                                id={criteria.name + "4"}
                                name={"c" + criteria.id}
                                checked={criteria.value === "4"}
                                value="4"
                                onChange={this.handleChange}
                              />
                            </Form.Field>
                          </Table.Cell>
                          <Table.Cell textAlign="center" width={1}>
                            <Form.Field>
                              <Radio
                                  id={criteria.name + "5"}
                                  name={"c" + criteria.id}
                                  checked={criteria.value === "5"}
                                  value="5"
                                  onChange={this.handleChange}
                              />
                            </Form.Field>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="equal">
            <Grid.Column textAlign="center">
              {/*<Button color='green' onClick={this.handleSubmit} loading={state.loading}>Save</Button> &nbsp;&nbsp;
              <Button color='primary' onClick={this.handleApprove}>Approve</Button> &nbsp;&nbsp;*/}
              {state.data.status === "APPROVED" ? (
                <Wrap>
                  {state.data.subject && state.data.subject.checkSubject && (
                    <Wrap>
                      <Form.Field>
                        <b> Check Flight : </b>
                        <Radio
                          label="PASS"
                          name="passed"
                          value="true"
                          defaultChecked
                          checked={this.state.passed === "true"}
                          onChange={this.handleChangePassed}
                        />
                        &nbsp; &nbsp;
                        <Radio
                          label="NOT PASS"
                          name="passed"
                          value="false"
                          checked={this.state.passed === "false"}
                          onChange={this.handleChangePassed}
                        />
                      </Form.Field>
                      <br />
                    </Wrap>
                  )}
                  <Button color="primary" onClick={this.handleApprove} disabled={this.validateRemark()}>
                    Approve
                  </Button>{" "}
                  &nbsp;&nbsp;
                </Wrap>
              ) : (
                <Wrap>
                  {state.data.subject && state.data.subject.checkSubject && (
                    <Wrap>
                      <Form.Field>
                        <b> Check Flight : </b>
                        <Radio
                          label="PASS"
                          name="passed"
                          value="true"
                          defaultChecked
                          checked={this.state.passed === "true"}
                          onChange={this.handleChangePassed}
                        />
                        &nbsp; &nbsp;
                        <Radio
                          label="NOT PASS"
                          name="passed"
                          value="false"
                          checked={this.state.passed === "false"}
                          onChange={this.handleChangePassed}
                        />
                      </Form.Field>
                      <br />
                    </Wrap>
                  )}
                  <Button
                    color="green"
                    onClick={this.handleSubmit}
                    loading={state.loading}
                    disabled={this.validateRemark()}
                  >
                    Save
                  </Button>{" "}
                  &nbsp;&nbsp;
                  <Button color="primary" onClick={this.handleApprove} disabled={this.validateRemark()}>
                    Approve
                  </Button>{" "}
                  &nbsp;&nbsp;
                </Wrap>
              )}
              <Button
                onClick={() => this.props.history.push("/AdminTrainRecord")}
              >
                Cancel
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Confirmmodal
          loading={state.loading}
          open={state.openConfirm}
          title={state.typeConfirm && state.typeConfirm}
          onClose={this.close}
          onComfirm={state.typeConfirm === "Approve" && this.onApprove}
        />
      </div>
    );
  }
}

export default withRouter(ScoreEdit);
