import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Segment, Header, Form, Button, Grid, Image, Label, Icon, Table } from 'semantic-ui-react'
import { Field, reduxForm } from 'redux-form'
import * as config from '../../config'
import validate from '../../validate'
import HeaderWithMenu from '../../components/headerwithmenu'
// import TableList from '../../components/table'
import MainButton from '../../components/mainbutton'
import ModalForm from '../../components/modal'
import Wrap from '../../components/wrap'
import {
    getAllCompany, getAllGroup, getInstructorListBySearch, getInstructorsWork,
    getInstructorTypes, getInstructorsEvent, postPersonnel,
    postInstructors
} from './service'
import FormInstructors from './instructorForm'
import DropdownCheckbox from '../../components/dropdownCheckbox'
import ScheduleById from '../Schedule/scheduleById'
import moment from "moment";
import TableList from "../../components/tablePerPage";
import { MonthInput } from "semantic-ui-calendar-react";

class Instructors extends Component {

    state = {
        current_page: 0,
        per_page: 10,
        last_page: 1,
        open: false,
        openWorkShd: false,
        loading: true,
        clickState: '',
        rawData: [],
        data: [],
        dataEvent: [],
        btnLoading: false,
        search: {
            status: 'Active',
            group: '',
            sort: { key: 'firstName', text: 'Name', value: '1', icon: 'sort alphabet ascending', description: 'ASC ' },
            worktime: '',
            categories: '',
            date: ''
        },
        options_group: [],
        options_instructortype: [],
        checked_instructortype: [],
        instructortype: [],
        sortText: 'Name [ASC]',
        options_sortby: [
            { key: 'firstName', text: 'Name', value: '1', icon: 'sort alphabet ascending', description: 'ASC' },
            { key: 'firstName', text: 'Name', value: '2', icon: 'sort alphabet descending', description: 'DESC' },
            // { key: 'medicalexpirydate', text: 'Medical Expired Date', value: '3', icon: 'sort alphabet ascending', description: 'ASC' },
            { key: 'medicalexpirydate', text: 'Medical Expired Date', value: '4', icon: 'sort alphabet descending', description: 'DESC' },
            // { key: 'splexpirydate', text: 'CPL/ATPL Expired Date', value: '5', icon: 'sort alphabet ascending', description: 'ASC' },
            { key: 'splexpirydate', text: 'CPL/ATPL Expired Date', value: '6', icon: 'sort alphabet descending', description: 'DESC' },
            // { key: 'dcpexpirydate', text: 'DCP Expired Date', value: '7', icon: 'sort alphabet ascending', description: 'ASC' },
            { key: 'dcpexpirydate', text: 'DCP Expired Date', value: '8', icon: 'sort alphabet descending', description: 'DESC' },
            // { key: 'lastFlight', text: 'Last Flight', value: '9', icon: 'sort alphabet ascending', description: 'ASC' },
            { key: 'lastFlight', text: 'Last Flight', value: '10', icon: 'sort alphabet descending', description: 'DESC' },
        ],
        filterInstructorTypeByText: 'All Instructors Type',
        filterWorkTimeByText: 'All Work Time',
        filterCategoriesByText: 'All Categories',
        options_worktime: [
            { key: "r01", value: "IP Fulltime", text: "IP Fulltime" },
            { key: "r02", value: "IP Parttime", text: "IP Parttime" }
        ],
        checked_worktime: [],
        options_categories: [
            { key: "c01", value: "CAT A", text: "CAT A" },
            { key: "c02", value: "CAT B", text: "CAT B" },
            { key: "c03", value: "CAT C", text: "CAT C" },
            { key: "c04", value: "CAT D", text: "CAT D" },
            // { key: "c05", value: "CAT D", text: "CAT D (pro)" }
        ],
        checked_categories: [],
        column: [{
            Header: 'Image',
            accessor: 'image',
            style: { textAlign: 'center' },
            sortable: false,
            Cell: cellInfo => (
                <Image avatar
                    size='mini' verticalAlign='middle'
                    src={cellInfo.original.image && cellInfo.original.image + '?time=' + new Date()}
                    onError={(e) => { e.target.onerror = null; e.target.src = 'https://app.flightschedulepro.com/images/instructor-default.gif' }}
                />
            ),
            width: 60,
        }, {
            Header: 'Name',
            accessor: 'description',
            sortable: false,
            width: 280,
            Cell: cellInfo => (
                <Wrap>
                    <span className="bold" style={{ cursor: 'pointer' }} onClick={() => this.onClickProfile(cellInfo.original)}>{cellInfo.original.description}</span><br />
                    <p>ตำแหน่ง : {cellInfo.original.position || '-'}</p>
                </Wrap>
            )
        }, {
            Header: 'Instructor Type',
            accessor: 'instructorType',
            style: { textAlign: 'left' },
            sortable: false,
            Cell: cellInfo => (
                // console.log(cellInfo)
                <span>
                    {
                        cellInfo.original
                            ? cellInfo.original.instructorType.length > 0
                                ? cellInfo.original.instructorType.map(item =>
                                    <span key={item.value}> {item.name} <br /> </span>
                                )
                                : null
                            : null
                    }
                </span>
            ),
            width: 150,
        }, {
            Header: 'Work Time',
            accessor: 'employmenttype',
            style: { textAlign: 'left' },
            sortable: false,
            Cell: cellInfo => (
                // console.log(cellInfo)
                <span>
                    {
                        cellInfo.original
                            ? cellInfo.original.employmenttype : '-'
                    }
                </span>
            ),
            width: 150,
        }, {
            Header: 'Categories',
            accessor: 'categories',
            style: { textAlign: 'left' },
            sortable: false,
            Cell: cellInfo => (
                // console.log(cellInfo)
                <span>
                    {
                        cellInfo.original
                            ? cellInfo.original.categories : '-'
                    }
                </span>
            ),
            width: 100,
        },
        {
            Header: 'Medical Certificate',
            accessor: 'personnelmedical',
            style: { textAlign: 'left' },
            sortable: false,
            Cell: cellInfo => (
                // console.log(cellInfo)
                <span>
                    {
                        cellInfo.original &&
                        <Wrap>
                            <span>Medical Class: {cellInfo.original.medicalclass || '-'}</span><br />
                            <span>Expired Date:
                            {cellInfo.original.medicalexpirydate ?
                                    moment().isAfter(moment(cellInfo.original.medicalexpirydate)) ?
                                        <span style={{ color: 'red' }}>{cellInfo.original.medicalexpirydate}</span>
                                        : moment().isAfter(moment(cellInfo.original.medicalexpirydate).subtract(30, 'days')) ?
                                            <span style={{ color: 'orange' }}>{cellInfo.original.medicalexpirydate}</span>
                                            : cellInfo.original.medicalexpirydate
                                    : '-'}
                            </span>
                        </Wrap>
                    }
                </span>
            ),
            width: 180,
        },
        {
            Header: 'Pilot License',
            accessor: 'personnelspl',
            style: { textAlign: 'left' },
            sortable: false,
            Cell: cellInfo => (

                <span>
                    {
                        cellInfo.original &&
                        <Wrap>
                            <span>CPL/ATPL No.: {cellInfo.original.splnumber || '-'}</span><br />
                            <span>CPL/ATPL Expired Date:
                            {cellInfo.original.splexpirydate ?
                                    moment().isAfter(moment(cellInfo.original.splexpirydate)) ?
                                        <span>
                                            <span style={{ color: 'red' }}>{cellInfo.original.splexpirydate}</span>
                                        </span> :
                                        moment().isAfter(moment(cellInfo.original.splexpirydate).subtract(30, 'days')) ?
                                            <span>
                                                <span style={{ color: 'orange' }}>{cellInfo.original.splexpirydate}</span>
                                            </span> : cellInfo.original.splexpirydate
                                    : '-'
                                }
                            </span>
                        </Wrap>
                    }
                </span>
            ),
            width: 250,
        },
        {
            Header: 'DCP License',
            accessor: 'dcpexpirydate',
            style: { textAlign: 'left' },
            sortable: false,
            Cell: cellInfo => (

                <span>
                    {
                        cellInfo.original &&
                        <Wrap>
                            <span>Expired Date:
                            {cellInfo.original.dcpexpirydate ?
                                    moment().isAfter(moment(cellInfo.original.dcpexpirydate)) ?
                                        <span>
                                            <span style={{ color: 'red' }}>{cellInfo.original.dcpexpirydate}</span>
                                        </span> :
                                        moment().isAfter(moment(cellInfo.original.dcpexpirydate).subtract(30, 'days')) ?
                                            <span>
                                                <span style={{ color: 'orange' }}>{cellInfo.original.dcpexpirydate}</span>
                                            </span> : cellInfo.original.dcpexpirydate
                                    : '-'
                                }
                            </span>
                        </Wrap>
                    }
                </span>
            ),
            width: 180,
        }, {
            Header: 'Checker Type',
            accessor: 'checkerType',
            style: { textAlign: 'left' },
            sortable: false,
            Cell: cellInfo => (
                // console.log(cellInfo)
                <span>
                    {
                        cellInfo.original
                            ? cellInfo.original.checkerType && cellInfo.original.checkerType.length > 0
                                ? cellInfo.original.checkerType.map(item =>
                                    <span > {item} <br /> </span>
                                )
                                : '-'
                            : '-'
                    }
                </span>
            ),
            width: 100,
        }, {
            Header: 'Eng Level',
            accessor: 'engLevel',
            style: { textAlign: 'left' },
            sortable: false,
            Cell: cellInfo => (
                // console.log(cellInfo)
                <span>
                    {
                        cellInfo.original ?
                            cellInfo.original.engLevel ?
                                cellInfo.original.engLevel
                                : '-'
                            : '-'
                    }
                </span>
            ),
            width: 100,
        }, {
            Header: 'Proficiency Check',
            accessor: 'proficiencyCheck',
            style: { textAlign: 'left' },
            sortable: false,
            Cell: cellInfo => (
                // console.log(cellInfo)
                <span>
                    <span>INSTRUMENT: {cellInfo.original.instrument || '-'}</span><br />
                    <span>GENERAL HANDLING: {cellInfo.original.generalHanding || '-'}</span>
                </span>
            ),
            width: 170,
        },
        {
            Header: 'Last Flight',
            accessor: 'lastFlight',
            style: { textAlign: 'left' },
            sortable: false,
            Cell: cellInfo => (
                // console.log(cellInfo)
                <span>
                    {
                        cellInfo.original ?
                            cellInfo.original.lastFlight ?
                                moment().diff(moment(cellInfo.original.lastFlight), 'days') > 44 ?
                                    <span >
                                        <span>{cellInfo.original.lastFlightName}</span><br />
                                        <span style={{ color: 'red' }}>{cellInfo.original.lastFlight}</span>
                                    </span>
                                    : moment().diff(moment(cellInfo.original.lastFlight), 'days') > 34 ?
                                        <span >
                                            <span>{cellInfo.original.lastFlightName}</span><br />
                                            <span style={{ color: 'orange' }}>{cellInfo.original.lastFlight}</span>
                                        </span>
                                        : <span >
                                            <span>{cellInfo.original.lastFlightName}</span><br />
                                            <span>{cellInfo.original.lastFlight}</span>
                                        </span>
                                : '-'
                            : '-'
                    }
                </span>
            ),
            width: 150,
        },
        // {
        //     Header: 'Phone',
        //     accessor: 'phone',
        //     sortable: false,
        //     width: 100,
        //     Cell: cellInfo => (
        //         <Wrap>
        //             <p>{cellInfo.original.phone}</p>
        //         </Wrap>
        //     )
        // }, {
        //     Header: 'Email',
        //     accessor: 'email',
        //     sortable: false,
        //     style: { textAlign: 'center' },
        //     Cell: cellInfo => (
        //         <span>
        //             {
        //                 cellInfo.original.email
        //                     ? <Icon link circular color='red' name='mail' />
        //                     : <Icon circular color='grey' name='mail' />
        //             }
        //
        //         </span>
        //     ),
        //     width: 70,
        // },
        {
            Header: 'Action',
            accessor: 'id',
            style: { textAlign: 'center' },
            sortable: false,
            Cell: cellInfo => (
                <Wrap>
                    <Icon link circular color='grey' name='calendar alternate' onClick={() => this.onClickWorkShd(cellInfo.original)} />
                    <Icon link circular color='orange' name='user' onClick={() => this.onClickProfile(cellInfo.original)} />
                </Wrap>
            ),
            width: 100,
        }
        ],

    }
    componentDidMount() {
        let { options_worktime, options_categories } = this.state
        let checked_worktime = []
        let checked_categories = []
        let qString = '?status=Active'
        this.getData(qString)
        this.getInstructorType()

        options_worktime.map(item => {
            checked_worktime.push(item.value)
        })
        options_categories.map(item => {
            checked_categories.push(item.value)
        })
        this.setState({ checked_worktime, checked_categories })

    }
    handleChangeDate = (event, { name, value }) => {
        let { search, checked_instructortype, options_instructortype,
            checked_categories, options_categories, options_worktime, checked_worktime } = this.state
        let qString
        search['date'] = value;
        this.setState({ search, })
        qString = '?status=' + search.status +
            '&_sort=' + search.sort.key +
            '&_order=' + search.sort.description +
            '&proficiencyCheck=' + moment(value, 'MMM').format('MM')

        if (search['name']) {
            qString += '&name=' + search.name
        }
        if (checked_instructortype && checked_instructortype.length != options_instructortype.length)
            checked_instructortype.map(item => {
                qString += `&instuctortype[]=${item}`
            })

        if (checked_worktime && checked_worktime.length != options_worktime.length)
            checked_worktime.map(item => {
                qString += `&worktime[]=${item}`
            })

        if (checked_categories && checked_categories.length != options_categories.length)
            checked_categories.map(item => {
                qString += `&categories[]=${item}`
            })
        this.getData(qString)

    }

    handleChangeSort = (e, data) => {
        let { search, checked_instructortype, options_instructortype,
            checked_categories, options_categories, options_worktime, checked_worktime } = this.state
        let qString

        const result = data.options.find((member) => {
            return member.value === data.value
        })
        search['sort'] = result
        this.setState({ search, sortText: `${result.text} (${result.description})` })

        qString = '?status=' + search.status + '&_sort=' + search.sort.key + '&_order=' + search.sort.description
        if (search['name']) {
            qString += '&name=' + search.name
        }
        if (search['date']) {
            qString += '&proficiencyCheck=' + moment(search.date, 'MMM').format('MM')
        }
        if (checked_instructortype && checked_instructortype.length != options_instructortype.length)
            checked_instructortype.map(item => {
                qString += `&instuctortype[]=${item}`
            })

        if (checked_worktime && checked_worktime.length != options_worktime.length)
            checked_worktime.map(item => {
                qString += `&worktime[]=${item}`
            })

        if (checked_categories && checked_categories.length != options_categories.length)
            checked_categories.map(item => {
                qString += `&categories[]=${item}`
            })
        this.getData(qString)
    }

    handleChangeStatus = (e, data) => {
        let { search, checked_instructortype, options_instructortype,
            checked_categories, options_categories, options_worktime, checked_worktime } = this.state
        let qString

        search['status'] = data.value // "Active/Inactive"
        this.setState({ search })
        qString = '?status=' + data.value
        if (search['name']) {
            qString += '&name=' + search.name
        }
        if (search['date']) {
            qString += '&proficiencyCheck=' + moment(search.date, 'MMM').format('MM')
        }
        if (checked_instructortype && checked_instructortype.length != options_instructortype.length)
            checked_instructortype.map(item => {
                qString += `&instuctortype[]=${item}`
            })

        if (checked_worktime && checked_worktime.length != options_worktime.length)
            checked_worktime.map(item => {
                qString += `&worktime[]=${item}`
            })

        if (checked_categories && checked_categories.length != options_categories.length)
            checked_categories.map(item => {
                qString += `&categories[]=${item}`
            })

        if (search['sort'])
            qString += '&_sort=' + search.sort.key + '&_order=' + search.sort.description

        this.getData(qString)
    }

    handleChangeSearch = (e) => {
        let { rawData, search, options_instructortype, checked_instructortype,
            checked_categories, options_categories, options_worktime, checked_worktime } = this.state;
        let active;
        this.setState({ loading: true })
        if (this._timeout) { //if there is already a timeout in process cancel it
            clearTimeout(this._timeout);
        }
        const val = e.target.value;
        const name = e.target.name
        this._timeout = setTimeout(() => {
            this._timeout = null;
            search[name] = val
            let qString = "?name=" + val
            qString += '&status=' + search.status
            if (search['date']) {
                qString += '&proficiencyCheck=' + moment(search.date, 'MMM').format('MM')
            }
            if (checked_instructortype && checked_instructortype.length != options_instructortype.length)
                checked_instructortype.map(item => {
                    qString += `&instuctortype[]=${item}`
                })

            if (checked_worktime && checked_worktime.length != options_worktime.length)
                checked_worktime.map(item => {
                    qString += `&worktime[]=${item}`
                })

            if (checked_categories && checked_categories.length != options_categories.length)
                checked_categories.map(item => {
                    qString += `&categories[]=${item}`
                })

            if (search['sort'])
                qString += '&_sort=' + search.sort.key + '&_order=' + search.sort.description
            this.getData(qString)

        }, 2000);
    }


    getData = (qString) => {
        this.setState({ loading: true })
        let { per_page, current_page, search } = this.state;
        current_page = current_page + 1;
        qString = qString + "&_perpage=" + per_page + "&page=" + current_page;
        getInstructorListBySearch(qString).then(res => {
            if (res && res.status === 200) {
                let newdata = []
                res.data.data.map(item => {
                    let description = `${item.instructorID ? item.instructorID : ''} ${item.firstName} ${item.lastName}`
                    newdata.push({
                        id: item.id,
                        image: item.imageURL || [],
                        description: description,
                        email: item.emailAddress,
                        phone: item.phoneNumber,
                        roles: item.roleId || [],
                        instructorType: item.instructorType || [],
                        categories: item.categories,
                        employmenttype: item.employmenttype,
                        position: item.position,
                        medicalclass: item.medicalclass,
                        medicalexpirydate: item.medicalexpirydate,
                        splnumber: item.splnumber,
                        splexpirydate: item.splexpirydate,
                        lastFlight: item.lastFlight,
                        engLevel: item.engLevel,
                        checkerType: item.checkerType,
                        dcpexpirydate: item.dcpexpirydate,
                        instrument: item.instrument,
                        generalHanding: item.generalHanding,
                        lastFlightName: item.lastFlightName

                    })
                })
                this.setState({ data: newdata, rawData: res.data.data, current_page: res.data.current_page - 1, last_page: res.data.last_page })
            }
            this.setState({ loading: false })
        })
    }

    filterByInstructortypes = (data) => {
        let { search, options_instructortype,
            checked_categories, options_categories, options_worktime, checked_worktime } = this.state

        let qString = '?status=' + search.status
        let checked_instructortype = []

        if (data && data.length != options_instructortype.length)
            data.map(item => {
                qString += `&instuctortype[]=${item}`
                checked_instructortype.push(item)
            })
        else
            data.map(item => {
                checked_instructortype.push(item)
            })

        if (data.length === options_instructortype.length) {
            this.setState({ filterInstructorTypeByText: 'All Instructors Type' })
        }
        else {
            this.setState({ filterInstructorTypeByText: `Instructors type (${data.length})` })
        }
        this.setState({ checked_instructortype, })
        if (search['name']) {
            qString += '&name=' + search.name
        }
        if (search['date']) {
            qString += '&proficiencyCheck=' + moment(search.date, 'MMM').format('MM')
        }
        if (search['sort'])
            qString += '&_sort=' + search.sort.key + '&_order=' + search.sort.description

        if (checked_worktime && checked_worktime.length != options_worktime.length)
            checked_worktime.map(item => {
                qString += `&worktime[]=${item}`
            })

        if (checked_categories && checked_categories.length != options_categories.length)
            checked_categories.map(item => {
                qString += `&categories[]=${item}`
            })
        // console.log(qString)
        this.getData(qString)
    }
    filterByWorktime = (data) => {
        let { search, options_worktime, checked_instructortype,
            options_instructortype, options_categories, checked_categories } = this.state

        let qString = '?status=' + search.status
        let checked_worktime = []

        if (data && data.length != options_worktime.length)
            data.map(item => {
                qString += `&worktime[]=${item}`
                checked_worktime.push(item)
            })
        else
            data.map(item => {
                checked_worktime.push(item)
            })

        if (data.length === options_worktime.length) {
            this.setState({ filterWorkTimeByText: 'All Work Time' })
        }
        else {
            this.setState({ filterWorkTimeByText: `Work Time (${data.length})` })
        }
        this.setState({ checked_worktime, })
        if (search['name']) {
            qString += '&name=' + search.name
        }
        if (search['date']) {
            qString += '&proficiencyCheck=' + moment(search.date, 'MMM').format('MM')
        }
        if (search['sort'])
            qString += '&_sort=' + search.sort.key + '&_order=' + search.sort.description

        if (checked_instructortype && checked_instructortype.length != options_instructortype.length)
            checked_instructortype.map(item => {
                qString += `&instuctortype[]=${item}`
            })

        if (checked_categories && checked_categories.length != options_categories.length)
            checked_categories.map(item => {
                qString += `&categories[]=${item}`
            })

        // console.log(qString)
        this.getData(qString)
    }
    filterByCategories = (data) => {
        let { search, options_categories, options_worktime, checked_worktime, checked_instructortype, options_instructortype } = this.state

        let qString = '?status=' + search.status
        let checked_categories = []

        if (data && data.length != options_categories.length)
            data.map(item => {
                qString += `&categories[]=${item}`
                checked_categories.push(item)
            })
        else
            data.map(item => {
                checked_categories.push(item)
            })

        if (data.length === options_categories.length) {
            this.setState({ filterCategoriesByText: 'All Categories' })
        }
        else {
            this.setState({ filterCategoriesByText: `Categories (${data.length})` })
        }
        this.setState({ checked_categories, })
        if (search['name']) {
            qString += '&name=' + search.name
        }
        if (search['date']) {
            qString += '&proficiencyCheck=' + moment(search.date, 'MMM').format('MM')
        }
        if (search['sort'])
            qString += '&_sort=' + search.sort.key + '&_order=' + search.sort.description

        if (checked_instructortype && checked_instructortype.length != options_instructortype.length)
            checked_instructortype.map(item => {
                qString += `&instuctortype[]=${item}`
            })

        if (checked_worktime && checked_worktime.length != options_worktime.length)
            checked_worktime.map(item => {
                qString += `&worktime[]=${item}`
            })

        // console.log(qString)
        this.getData(qString)
    }

    getInstructorType = () => {
        let options = [], checked_instructortype = []
        getInstructorTypes().then(res => {
            if (res && res.status === 200) {
                res.data.map(item => {
                    options.push({ key: item.id, text: item.name, value: item.id })
                    checked_instructortype.push(item.id)
                })
                this.setState({
                    options_instructortype: options, instructortype: res.data, checked_instructortype,
                })
            }
        })
    }
    show = () => this.setState({ open: true })
    close = () => this.setState({ open: false, openWorkShd: false })
    onClickWorkShd = (value) => {
        this.setState({ currentData: value, openWorkShd: true })
    }
    editShift = () => this.setState({ clickState: 'edit' })
    saveShift = () => this.setState({ clickState: 'main' })
    onClickNew = (id, type) => console.log("fefefef", id, type)
    onClickProfile = (data) => {
        // console.log(data)
        this.props.history.push({
            pathname: '/Instructors/Profile',
            state: { id: data.id }
        })
    }

    render() {

        let state = this.state
        return (
            <Segment className="contentSegment" textAlign='left'>
                {/* <Header as='h2' style={{ color: config.colorTheme }}>Instructors</Header> */}
                <HeaderWithMenu name='Instructors' >
                    {/* วานีปิดให้ 12/11/2019 */}
                    {/* <MainButton
                        size='small'
                        onClick={this.show}
                        text='Add Instructors' /> */}
                </HeaderWithMenu>
                <Form>
                    <Form.Group>
                        <Form.Input
                            width='3'
                            icon='search'
                            size='small'
                            placeholder='Search'
                            name='name'
                            onChange={this.handleChangeSearch} />
                        <Form.Field width='2'>
                            <DropdownCheckbox style={{ textAlign: 'left' }} checked={state.checked_instructortype} options={state.options_instructortype} onClick={this.filterByInstructortypes}>
                                <Button fluid size='small' icon basic labelPosition='right' style={{ textAlign: 'left' }}>
                                    {state.filterInstructorTypeByText}
                                    <Icon name='angle down' />
                                </Button>
                            </DropdownCheckbox>
                        </Form.Field>
                        <Form.Field width='2'>
                            <DropdownCheckbox style={{ textAlign: 'left' }} checked={state.checked_worktime} options={state.options_worktime} onClick={this.filterByWorktime}>
                                <Button fluid size='small' icon basic labelPosition='right' style={{ textAlign: 'left' }}>
                                    {state.filterWorkTimeByText}
                                    <Icon name='angle down' />
                                </Button>
                            </DropdownCheckbox>
                        </Form.Field>
                        <Form.Field width='2'>
                            <DropdownCheckbox style={{ textAlign: 'left' }} checked={state.checked_categories} options={state.options_categories} onClick={this.filterByCategories}>
                                <Button fluid size='small' icon basic labelPosition='right' style={{ textAlign: 'left' }}>
                                    {state.filterCategoriesByText}
                                    <Icon name='angle down' />
                                </Button>
                            </DropdownCheckbox>
                        </Form.Field>
                        <Form.Dropdown
                            icon={null}
                            fluid
                            floating
                            // width='3'
                            style={{ width: '100%', marginRight: '30px' }}
                            trigger={<Button basic icon fluid size='small' labelPosition='right' style={{ textAlign: 'left' }}>
                                <Icon name='angle down' />
                                Sort by : <b>{state.sortText}</b>
                            </Button>}
                            options={state.options_sortby}
                            defaultValue={state.search.sort}
                            selectOnBlur={false}
                            onChange={this.handleChangeSort}
                        />
                        <Form.Dropdown
                            icon={null}
                            fluid
                            floating
                            // width='3'
                            style={{ width: '100%', marginRight: '30px' }}
                            trigger={<Button basic icon fluid size='small' labelPosition='right' style={{ textAlign: 'left' }}>
                                <Icon name='angle down' />
                                Status : <b>{state.search.status}</b>
                            </Button>}
                            options={config.options_active}
                            defaultValue={state.search.status}
                            selectOnBlur={false}
                            onChange={this.handleChangeStatus}
                        />
                        <Form.Field width='2'>
                            {/*<label style={{ textAlign: "left" }}>Filter by Calendar</label>*/}
                            <MonthInput
                                name="date"
                                placeholder="Proficiency Check"
                                hideMobileKeyboard
                                dateFormat="MMM"
                                iconPosition="right"
                                value={this.state.search.date}
                                onChange={this.handleChangeDate}
                                clearable
                            />
                        </Form.Field>
                        {/* <Form.Field width='5' style={{ textAlign: 'right' }}>
                            <MainButton
                                size='small'
                                onClick={this.show}
                                text='Add Instructors' />
                        </Form.Field> */}
                    </Form.Group>
                </Form>
                <ModalForm
                    icon='calendar alternate'
                    content='Work Schedule'
                    size='large'
                    open={state.openWorkShd}
                    close={this.close}  >
                    <Wrap>
                        <ScheduleById id={state.currentData ? state.currentData.id : null} disableClick />
                    </Wrap>
                </ModalForm>
                <ModalForm
                    icon='add user'
                    content='Add Instructor'
                    open={state.open}
                    close={this.close}  >
                    <FormInstructors
                        isAdd={true}
                        loading={state.btnLoading}
                        onSubmit={this.onSubmitAddInstructors}
                        onCancel={this.close}
                        options_instructortype={state.options_instructortype}
                    />
                </ModalForm>
                <TableList
                    data={state.data}
                    columns={state.column}
                    loading={state.loading}
                    rowClick={this.onClickProfile}
                    pageSize={state.per_page}
                    page={state.current_page}
                    pages={state.last_page}
                    onPageChange={this.onPageChange}
                    onPageSizeChange={this.onPageSizeChange}
                />
            </Segment>
        )
    }

    onPageSizeChange = (pageSize, pageIndex) => {
        let { search, checked_instructortype, options_instructortype,
            checked_categories, options_categories, options_worktime, checked_worktime } = this.state
        this.setState({
            per_page: pageSize, current_page: pageIndex
        }, () => {
            let qString = '?status=' + search.status
            if (search['name']) {
                qString += '&name=' + search.name
            }
            if (search['date']) {
                qString += '&proficiencyCheck=' + moment(search.date, 'MMM').format('MM')
            }
            if (checked_instructortype && checked_instructortype.length != options_instructortype.length)
                checked_instructortype.map(item => {
                    qString += `&instuctortype[]=${item}`
                })

            if (checked_worktime && checked_worktime.length != options_worktime.length)
                checked_worktime.map(item => {
                    qString += `&worktime[]=${item}`
                })

            if (checked_categories && checked_categories.length != options_categories.length)
                checked_categories.map(item => {
                    qString += `&categories[]=${item}`
                })

            if (search['sort'])
                qString += '&_sort=' + search.sort.key + '&_order=' + search.sort.description
            this.getData(qString)
        });
    };

    onPageChange = pageIndex => {
        let { search, checked_instructortype, options_instructortype,
            checked_categories, options_categories, options_worktime, checked_worktime } = this.state
        this.setState({
            current_page: pageIndex
        }, () => {
            let qString = '?status=' + search.status
            if (search['name']) {
                qString += '&name=' + search.name
            }
            if (search['date']) {
                qString += '&proficiencyCheck=' + moment(search.date, 'MMM').format('MM')
            }
            if (checked_instructortype && checked_instructortype.length != options_instructortype.length)
                checked_instructortype.map(item => {
                    qString += `&instuctortype[]=${item}`
                })

            if (checked_worktime && checked_worktime.length != options_worktime.length)
                checked_worktime.map(item => {
                    qString += `&worktime[]=${item}`
                })

            if (checked_categories && checked_categories.length != options_categories.length)
                checked_categories.map(item => {
                    qString += `&categories[]=${item}`
                })

            if (search['sort'])
                qString += '&_sort=' + search.sort.key + '&_order=' + search.sort.description
            this.getData(qString)
        });

    };
}

export default withRouter(Instructors)
// export default Instructors = reduxForm({
//     // a unique name for the form
//     form: 'instructors',
//     validate,
//     enableReinitialize: true

// })(Instructors)
