var productName = 'scheduler';/* eslint-disable */
// leave in, comment removed in bryntum-loader. also needs to go at top since polyfill is used by other code
//import "nodelist-foreach-polyfill";

if (!window.bryntum) window.bryntum = {};

import 'whatwg-fetch';
import 'abortcontroller-polyfill';
import "regenerator-runtime/runtime";

//region Common

import Base from './lib/Core/Base.js';

// adapter
import BryntumWidgetAdapter from './lib/Core/adapter/widget/BryntumWidgetAdapter.js';
import BryntumWidgetAdapterRegister from './lib/Core/adapter/widget/util/BryntumWidgetAdapterRegister.js';

// data
import AjaxStore from './lib/Core/data/AjaxStore.js';
import Model from './lib/Core/data/Model.js';
import Store from './lib/Core/data/Store.js';
import StateTrackingManager from './lib/Core/data/stm/StateTrackingManager.js';
import Transaction from './lib/Core/data/stm/Transaction.js';

// helpers
import AjaxHelper from './lib/Core/helper/AjaxHelper.js';
import ArrayHelper from './lib/Core/helper/ArrayHelper.js';
import BrowserHelper from './lib/Core/helper/BrowserHelper.js';
import CSSHelper from './lib/Core/helper/CSSHelper.js';
import DateHelper from './lib/Core/helper/DateHelper.js';
import DragHelper from './lib/Core/helper/DragHelper.js';
import DomHelper from './lib/Core/helper/DomHelper.js';
import DomSync from './lib/Core/helper/DomSync.js';
import DomClassList from './lib/Core/helper/util/DomClassList.js';
import EventHelper from './lib/Core/helper/EventHelper.js';
import FunctionHelper from './lib/Core/helper/FunctionHelper.js';
import IdHelper from './lib/Core/helper/IdHelper.js';
import ObjectHelper from './lib/Core/helper/ObjectHelper.js';
import ResizeHelper from './lib/Core/helper/ResizeHelper.js';
import StringHelper from './lib/Core/helper/StringHelper.js';
import TemplateHelper from './lib/Core/helper/TemplateHelper.js';
import VersionHelper from './lib/Core/helper/VersionHelper.js';
import WidgetHelper from './lib/Core/helper/WidgetHelper.js';

import DataGenerator from './lib/Core/helper/util/DataGenerator.js';
import RandomGenerator from './lib/Core/helper/util/RandomGenerator.js';
import DemoBot from './lib/Core/helper/util/DemoBot.js';
import Fullscreen from './lib/Core/helper/util/Fullscreen.js';
import Scroller from './lib/Core/helper/util/Scroller.js';

import Point from './lib/Core/helper/util/Point.js';
import Rectangle from './lib/Core/helper/util/Rectangle.js';
import ResizeMonitor from './lib/Core/helper/ResizeMonitor.js';
import GlobalEvents from './lib/Core/GlobalEvents.js';

// localization
import Localizable from './lib/Core/localization/Localizable.js';
import LocaleManager from './lib/Core/localization/LocaleManager.js';

// mixin
import Delayable from './lib/Core/mixin/Delayable.js';
import Events from './lib/Core/mixin/Events.js';
import InstancePlugin from './lib/Core/mixin/InstancePlugin.js';
import Override from './lib/Core/mixin/Override.js';
import Pluggable from './lib/Core/mixin/Pluggable.js';
import State from './lib/Core/mixin/State.js';

// util
import CollectionFilter from './lib/Core/util/CollectionFilter.js';
import CollectionSorter from './lib/Core/util/CollectionSorter.js';
import Collection from './lib/Core/util/Collection.js';

// widget
import BooleanCombo from './lib/Core/widget/BooleanCombo.js';
import Button from './lib/Core/widget/Button.js';
import ButtonGroup from './lib/Core/widget/ButtonGroup.js';
import CalendarPanel from './lib/Core/widget/CalendarPanel.js';
import Checkbox from './lib/Core/widget/Checkbox.js';
import Combo from './lib/Core/widget/Combo.js';
import Container from './lib/Core/widget/Container.js';
import Field from './lib/Core/widget/Field.js';
import FileField from './lib/Core/widget/FileField.js';
import FilePicker from './lib/Core/widget/FilePicker.js';
import FlagField from './lib/Core/widget/FlagField.js';
import DateField from './lib/Core/widget/DateField.js';
import DatePicker from './lib/Core/widget/DatePicker.js';
import DisplayField from './lib/Core/widget/DisplayField.js';
import DurationField from './lib/Core/widget/DurationField.js';
import List from './lib/Core/widget/List.js';
import ChipView from './lib/Core/widget/ChipView.js';
import Mask from './lib/Core/widget/Mask.js';
import Menu from './lib/Core/widget/Menu.js';
import MenuItem from './lib/Core/widget/MenuItem.js';
import MessageDialog from './lib/Core/widget/MessageDialog.js';
import NumberField from './lib/Core/widget/NumberField.js';
import Tool from './lib/Core/widget/Tool.js';
import PagingToolbar from './lib/Core/widget/PagingToolbar.js';
import Panel from './lib/Core/widget/Panel.js';
import Popup from './lib/Core/widget/Popup.js';
import Ripple from './lib/Core/widget/Ripple.js';
import Slider from './lib/Core/widget/Slider.js';
import Splitter from './lib/Core/widget/Splitter.js';
import TabPanel from './lib/Core/widget/TabPanel.js';
import TextAreaField from './lib/Core/widget/TextAreaField.js';
import TextField from './lib/Core/widget/TextField.js';
import TimeField from './lib/Core/widget/TimeField.js';
import TimePicker from './lib/Core/widget/TimePicker.js';
import Toast from './lib/Core/widget/Toast.js';
import Tooltip from './lib/Core/widget/Tooltip.js';
import Widget from './lib/Core/widget/Widget.js';

//endregion

//region Grid

// columns
import AggregateColumn from './lib/Grid/column/AggregateColumn.js';
import Column from './lib/Grid/column/Column.js';
import CheckColumn from './lib/Grid/column/CheckColumn.js';
import DateColumn from './lib/Grid/column/DateColumn.js';
import PercentColumn from './lib/Grid/column/PercentColumn.js';
import RatingColumn from './lib/Grid/column/RatingColumn.js';
import RowNumberColumn from './lib/Grid/column/RowNumberColumn.js';
import TemplateColumn from './lib/Grid/column/TemplateColumn.js';
import TimeColumn from './lib/Grid/column/TimeColumn.js';
import TreeColumn from './lib/Grid/column/TreeColumn.js';
import WidgetColumn from './lib/Grid/column/WidgetColumn.js';

//import GridTag from './lib/Grid/customElements/GridTag.js';

// data
import ColumnStore from './lib/Grid/data/ColumnStore.js';
import GridRowModel from './lib/Grid/data/GridRowModel.js';

// features
import GridFeatureManager from './lib/Grid/feature/GridFeatureManager.js';
import CellEdit from './lib/Grid/feature/CellEdit.js';
import ColumnDragToolbar from './lib/Grid/feature/ColumnDragToolbar.js';
import ColumnPicker from './lib/Grid/feature/ColumnPicker.js';
import ColumnReorder from './lib/Grid/feature/ColumnReorder.js';
import ColumnResize from './lib/Grid/feature/ColumnResize.js';
import ContextMenu from './lib/Grid/feature/ContextMenu.js';
import CellTooltip from './lib/Grid/feature/CellTooltip.js';
import Filter from './lib/Grid/feature/Filter.js';
import FilterBar from './lib/Grid/feature/FilterBar.js';
import Group from './lib/Grid/feature/Group.js';
import QuickFind from './lib/Grid/feature/QuickFind.js';
import Search from './lib/Grid/feature/Search.js';
import Sort from './lib/Grid/feature/Sort.js';
import RegionResize from './lib/Grid/feature/RegionResize.js';
import RowReorder from './lib/Grid/feature/RowReorder.js';
import Stripe from './lib/Grid/feature/Stripe.js';
import Tree from './lib/Grid/feature/Tree.js';

// export
import GridPdfExport from './lib/Grid/feature/export/PdfExport.js';
import GridSinglePageExporter from './lib/Grid/feature/export/exporter/SinglePageExporter.js';
import GridMultiPageExporter from './lib/Grid/feature/export/exporter/MultiPageExporter.js';
import GridExportDialog from './lib/Grid/view/export/ExportDialog.js';
import { PaperFormat, Orientation, RowsRange, FileFormat } from './lib/Grid/feature/export/Utils.js';

// feature experimental
import GridExcelExporter from './lib/Grid/feature/experimental/ExcelExporter.js';

// util
import ScrollManager from './lib/Grid/util/ScrollManager.js';
import TableExporter from './lib/Grid/util/TableExporter.js';

// view
import RowManager from './lib/Grid/row/RowManager.js';
import Footer from './lib/Grid/view/Footer.js';
import GridBase from './lib/Grid/view/GridBase.js';
import Grid from './lib/Grid/view/Grid.js';
import Header from './lib/Grid/view/Header.js';
import TreeGrid from './lib/Grid/view/TreeGrid.js';

//endregion

//region Scheduler

// localization
import SchedulerCommon from './lib/Scheduler/localization/Common.js';

// crud
import AjaxTransport from './lib/Scheduler/crud/transport/AjaxTransport.js';
import AbstractCrudManager from './lib/Scheduler/crud/AbstractCrudManager.js';
import JsonEncoder from './lib/Scheduler/crud/encoder/JsonEncoder.js';

// data
import AssignmentStore from './lib/Scheduler/data/AssignmentStore.js';
import Calendar from './lib/Scheduler/data/Calendar.js';
import CrudManager from './lib/Scheduler/data/CrudManager.js';
import DependencyStore from './lib/Scheduler/data/DependencyStore.js';
import EventStore from './lib/Scheduler/data/EventStore.js';
import RecurringTimeSpansMixin from './lib/Scheduler/data/mixin/RecurringTimeSpansMixin.js';
import RecurringEventsMixin from './lib/Scheduler/data/mixin/RecurringEventsMixin.js';
import AbstractRecurrenceIterator from './lib/Scheduler/data/util/recurrence/AbstractRecurrenceIterator.js';
import DailyRecurrenceIterator from './lib/Scheduler/data/util/recurrence/DailyRecurrenceIterator.js';
import MonthlyRecurrenceIterator from './lib/Scheduler/data/util/recurrence/MonthlyRecurrenceIterator.js';
import RecurrenceDayRuleEncoder from './lib/Scheduler/data/util/recurrence/RecurrenceDayRuleEncoder.js';
import RecurrenceLegend from './lib/Scheduler/data/util/recurrence/RecurrenceLegend.js';
import WeeklyRecurrenceIterator from './lib/Scheduler/data/util/recurrence/WeeklyRecurrenceIterator.js';
import YearlyRecurrenceIterator from './lib/Scheduler/data/util/recurrence/YearlyRecurrenceIterator.js';

import ResourceStore from './lib/Scheduler/data/ResourceStore.js';
import ResourceTimeRangeStore from './lib/Scheduler/data/ResourceTimeRangeStore.js';
import TimeAxis from './lib/Scheduler/data/TimeAxis.js';

// features
import ColumnLines from './lib/Scheduler/feature/ColumnLines.js';
import Dependencies from './lib/Scheduler/feature/Dependencies.js';
import DependencyEdit from './lib/Scheduler/feature/DependencyEdit.js';
import EventContextMenu from './lib/Scheduler/feature/EventContextMenu.js';
import EventDrag from './lib/Scheduler/feature/EventDrag.js';
import EventDragCreate from './lib/Scheduler/feature/EventDragCreate.js';
import EventDragSelect from './lib/Scheduler/feature/EventDragSelect.js';
import EventEdit from './lib/Scheduler/feature/EventEdit.js';
import EventFilter from './lib/Scheduler/feature/EventFilter.js';
import EventResize from './lib/Scheduler/feature/EventResize.js';
import EventTooltip from './lib/Scheduler/feature/EventTooltip.js';
import GroupSummary from './lib/Scheduler/feature/GroupSummary.js';
import HeaderContextMenu from './lib/Scheduler/feature/HeaderContextMenu.js';
import HeaderZoom from './lib/Scheduler/feature/HeaderZoom.js';
import Labels from './lib/Scheduler/feature/Labels.js';
import NonWorkingTime from './lib/Scheduler/feature/NonWorkingTime.js';
import Pan from './lib/Scheduler/feature/Pan.js';
import RecurringEventEdit from './lib/Scheduler/feature/mixin/RecurringEventEdit.js';
import RecurringEvents from './lib/Scheduler/feature/RecurringEvents.js';
import RecurringTimeSpans from './lib/Scheduler/feature/RecurringTimeSpans.js';
import ResourceTimeRanges from './lib/Scheduler/feature/ResourceTimeRanges.js';
import ScheduleTooltip from './lib/Scheduler/feature/ScheduleTooltip.js';
import SimpleEventEdit from './lib/Scheduler/feature/SimpleEventEdit.js';
import Summary from './lib/Scheduler/feature/Summary.js';
import TimeRanges from './lib/Scheduler/feature/TimeRanges.js';

// export
import PdfExport from './lib/Scheduler/feature/export/PdfExport.js';
import SinglePageExporter from './lib/Scheduler/feature/export/exporter/SinglePageExporter.js';
import MultiPageExporter from './lib/Scheduler/feature/export/exporter/MultiPageExporter.js';
import SchedulerExportDialog from './lib/Scheduler/view/export/SchedulerExportDialog.js';
import { ScheduleRange } from './lib/Scheduler/feature/export/Utils.js';

// features experimental
import ExcelExporter from './lib/Scheduler/feature/experimental/ExcelExporter.js';

// columns
import TimeAxisColumn from './lib/Scheduler/column/TimeAxisColumn.js';
import ResourceInfoColumn from './lib/Scheduler/column/ResourceInfoColumn.js';

// model
import AssignmentModel from './lib/Scheduler/model/AssignmentModel.js';
import CalendarDayModel from './lib/Scheduler/model/CalendarDayModel.js';
import DependencyBaseModel from './lib/Scheduler/model/DependencyBaseModel.js';
import DependencyModel from './lib/Scheduler/model/DependencyModel.js';
import EventModel from './lib/Scheduler/model/EventModel.js';
import RecurringTimeSpan from './lib/Scheduler/model/mixin/RecurringTimeSpan.js';
import RecurrenceModel from './lib/Scheduler/model/RecurrenceModel.js';
import ResourceModel from './lib/Scheduler/model/ResourceModel.js';
import ResourceTimeRangeModel from './lib/Scheduler/model/ResourceTimeRangeModel.js';
import TimeSpan from './lib/Scheduler/model/TimeSpan.js';

// preset
import PresetStore from './lib/Scheduler/preset/PresetStore.js';
import PresetManager from './lib/Scheduler/preset/PresetManager.js';
import ViewPreset from './lib/Scheduler/preset/ViewPreset.js';

// util
import RectangularPathFinder from './lib/Scheduler/util/RectangularPathFinder.js';
import ScheduleTableExporter from './lib/Scheduler/util/ScheduleTableExporter.js';

//view
import TimeAxisViewModel from './lib/Scheduler/view/model/TimeAxisViewModel.js';
import HorizontalTimeAxis from './lib/Scheduler/view/HorizontalTimeAxis.js';
import RecurrenceConfirmationPopup from './lib/Scheduler/view/recurrence/RecurrenceConfirmationPopup.js';
import RecurrenceEditor from './lib/Scheduler/view/recurrence/RecurrenceEditor.js';
import RecurrenceStopConditionCombo from './lib/Scheduler/view/recurrence/field/RecurrenceStopConditionCombo.js';
import RecurrenceCombo from './lib/Scheduler/view/recurrence/field/RecurrenceCombo.js';
import RecurrenceDaysCombo from './lib/Scheduler/view/recurrence/field/RecurrenceDaysCombo.js';
import RecurrenceMonthsButtonGroup from './lib/Scheduler/view/recurrence/field/RecurrenceMonthsButtonGroup.js';
import RecurrenceMonthDaysButtonGroup from './lib/Scheduler/view/recurrence/field/RecurrenceMonthDaysButtonGroup.js';
import RecurrenceFrequencyCombo from './lib/Scheduler/view/recurrence/field/RecurrenceFrequencyCombo.js';
import RecurrencePositionsCombo from './lib/Scheduler/view/recurrence/field/RecurrencePositionsCombo.js';
import RecurrenceDaysButtonGroup from './lib/Scheduler/view/recurrence/field/RecurrenceDaysButtonGroup.js';
import RecurrenceLegendButton from './lib/Scheduler/view/recurrence/RecurrenceLegendButton.js';
import SchedulerBase from './lib/Scheduler/view/SchedulerBase.js';
import Scheduler from './lib/Scheduler/view/Scheduler.js';

// IMPORTANT NOTE: Tag should be imported last to be last in the rolled up bundle. We rely on its position to embed
import SchedulerTag from './lib/Scheduler/customElements/SchedulerTag.js';

//endregion

export {
    //region Common
    Base,

    // localization
    Localizable,
    LocaleManager,

    // helpers
    AjaxHelper,
    ArrayHelper,
    BrowserHelper,
    CSSHelper,
    DateHelper,
    DomHelper,
    DomSync,
    DomClassList,
    DragHelper,
    EventHelper,
    FunctionHelper,
    IdHelper,
    ObjectHelper,
    ResizeHelper,
    StringHelper,
    TemplateHelper,
    VersionHelper,
    WidgetHelper,

    Point,
    Rectangle,
    ResizeMonitor,
    GlobalEvents,

    DataGenerator,
    RandomGenerator,
    DemoBot,
    Fullscreen,
    Scroller,

    // mixin
    Delayable,
    Events,
    InstancePlugin,
    Override,
    Pluggable,
    State,

    // widgets
    BooleanCombo,
    Button,
    ButtonGroup,
    CalendarPanel,
    Checkbox,
    Combo,
    Container,
    Field,
    FileField,
    FilePicker,
    FlagField,
    DateField,
    DatePicker,
    DisplayField,
    DurationField,
    List,
    ChipView,
    Mask,
    Menu,
    MenuItem,
    MessageDialog,
    NumberField,
    Tool,
    PagingToolbar,
    Panel,
    Popup,
    Ripple,
    Slider,
    Splitter,
    TabPanel,
    TextField,
    TextAreaField,
    TimeField,
    TimePicker,
    Toast,
    Tooltip,
    Widget,

    //endregion

    //region Grid
    BryntumWidgetAdapter,
    BryntumWidgetAdapterRegister,
    AjaxStore,
    Model,
    Store,
    StateTrackingManager,
    Transaction,

    // columns
    AggregateColumn,
    Column,
    CheckColumn,
    DateColumn,
    PercentColumn,
    RatingColumn,
    RowNumberColumn,
    TemplateColumn,
    TimeColumn,
    TreeColumn,
    WidgetColumn,
    TimeAxisColumn,
    ResourceInfoColumn,

    //GridTag,

    // features
    GridFeatureManager,
    CellEdit,
    CellTooltip,
    ColumnPicker,
    ColumnReorder,
    ColumnResize,
    ContextMenu,
    Filter,
    FilterBar,
    Group,
    QuickFind,
    Search,
    Sort,
    RegionResize,
    RowReorder,
    Stripe,
    
    // export
    GridPdfExport,
    GridSinglePageExporter,
    GridMultiPageExporter,
    GridExportDialog,
    PaperFormat,
    Orientation,
    RowsRange,
    FileFormat,

    // Experimental
    GridExcelExporter,

    // view
    Tree,
    GridBase,
    Grid,
    TreeGrid,

    // util
    TableExporter,

    //endregion

    //region Scheduler

    // localization
    SchedulerCommon,

    // crud
    AjaxTransport,
    AbstractCrudManager,
    JsonEncoder,

    // data
    ColumnStore,
    AssignmentStore,
    Calendar,
    CrudManager,
    DependencyStore,
    EventStore,
    RecurringTimeSpansMixin,
    RecurringEventsMixin,
    AbstractRecurrenceIterator,
    DailyRecurrenceIterator,
    MonthlyRecurrenceIterator,
    RecurrenceDayRuleEncoder,
    RecurrenceLegend,
    WeeklyRecurrenceIterator,
    YearlyRecurrenceIterator,
    ResourceStore,
    ResourceTimeRangeStore,
    TimeAxis,

    // features
    ColumnLines,
    Dependencies,
    DependencyEdit,
    EventContextMenu,
    EventDrag,
    EventDragCreate,
    EventDragSelect,
    EventEdit,
    EventFilter,
    EventResize,
    EventTooltip,
    GroupSummary,
    HeaderContextMenu,
    HeaderZoom,
    Labels,
    NonWorkingTime,
    Pan,
    RecurringTimeSpans,
    RecurringEventEdit,
    RecurringEvents,
    ResourceTimeRanges,
    ScheduleTooltip,
    SimpleEventEdit,
    Summary,
    TimeRanges,

    // feature experimental
    ExcelExporter,
    
    // Export
    PdfExport,
    SinglePageExporter,
    MultiPageExporter,
    SchedulerExportDialog,
    ScheduleRange,

    // model
    AssignmentModel,
    CalendarDayModel,
    DependencyBaseModel,
    DependencyModel,
    EventModel,
    RecurringTimeSpan,
    RecurrenceModel,
    ResourceModel,
    ResourceTimeRangeModel,
    TimeSpan,

    // preset
    PresetStore,
    PresetManager,
    ViewPreset,

    // util
    RectangularPathFinder,
    ScheduleTableExporter,

    // view
    TimeAxisViewModel,
    HorizontalTimeAxis,
    RecurrenceConfirmationPopup,
    RecurrenceEditor,
    RecurrenceStopConditionCombo,
    RecurrenceCombo,
    RecurrenceDaysCombo,
    RecurrenceMonthsButtonGroup,
    RecurrenceMonthDaysButtonGroup,
    RecurrenceFrequencyCombo,
    RecurrencePositionsCombo,
    RecurrenceDaysButtonGroup,
    RecurrenceLegendButton,
    SchedulerBase,
    Scheduler,

    RowManager,

    // IMPORTANT!!!
    // DO NOT CHANGE anything below this line. We rely on SchedulerTag variable to embed trial limit code
    SchedulerTag
    //endregion
}
