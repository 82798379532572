import React, { Component } from "react";
import { Form, Button, Table, Icon, Grid } from "semantic-ui-react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import renderField from "../../components/renderField";
import renderTimeField from "../../components/renderTimeField";
import renderCheckboxField from "../../components/renderCheckboxField";
import renderTextAreaField from "../../components/renderTextAreaField";
import renderRadioGroupField from "../../components/renderRadioGroupField";
import renderCheckboxGroupField from "../../components/renderCheckboxGroupField";

import {getCriteria, getLogbookIP, getLogbookSP, getTrainingCriteria} from "./service";
import Wrap from "../../components/wrap";

const validate = (values) => {
  const errors = {};
  if (!values.code) errors.code = true;
  if (!values.name) errors.name = true;
  if (values.checkSubject) {
    if (!values.checkSubjectType) errors.checkSubject = true;
    if (!values.checkSubjectGroup) errors.checkSubject = true;
  }
  return errors;
};

class FormflightSubjects extends Component {
  state = {
    loadData: true,
    lesson: [],
    criteria: [],
    options_lesson: [],
    options_criteria: [],
    logbookIP: [],
    logbookSP: [],
  };

  componentDidMount() {
    getLogbookIP("?editable=true").then((res) => {
      if (res && res.status === 200) {
        this.setState({ logbookIP: res.data });
      }
    });
    getLogbookSP("?editable=true").then((res) => {
      if (res && res.status === 200) {
        this.setState({ logbookSP: res.data, loadData: false });
      }
    });
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.initialValues !== this.state.lesson) {
      if (nextProps.initialValues && nextProps.initialValues.lesson) {
        this.setState({ lesson: nextProps.initialValues.lesson });
      }
    }

    if (nextProps.options_lesson !== this.state.options_lesson) {
      if (nextProps.options_lesson) {
        this.setState({ options_lesson: nextProps.options_lesson });
      }
    }

    if (nextProps.initialValues !== this.state.criteria) {
      if (nextProps.initialValues && (!nextProps.initialValues.criteria || nextProps.initialValues.criteria.length<1)) {
        this.setState({ criteria: nextProps.options_criteria });
      }else {
        this.setState({ criteria: nextProps.initialValues.criteria  });
      }
    }

    if (nextProps.options_criteria !== this.state.options_criteria) {
      if (nextProps.options_criteria) {
        this.setState({ options_criteria: nextProps.options_criteria });
      }
    }
  }

  onChange = (e, data, key) => {
    console.log("onChange xxxx")
    let { lesson } = this.state;
    // หลอกไว้ รอ put แค่ id
    const result = data.options.find((member) => {
      return member.id === data.value;
    });
    if (result) {
      lesson[key] = { id: result.id, name: result.title };
      this.setState({ lesson });
    }
  };
  onChangeLesson = (e, data, key) => {
    let { lesson } = this.state;
    lesson[key] = { ...lesson[key], [data.name]: data.value };
    this.setState({ lesson });
  };
  // FIXME No.11
  onChangeCriteria = (e, data, key) => {
    let { criteria } = this.state;
    criteria[key] = { ...criteria[key], [data.name]: data.value };
    this.setState({ criteria });
  };
  handleItemClickAdd = () => {
    let lesson = this.state.lesson;
    lesson.push({});
    this.setState({ lesson });
  };

  handleItemClickDelete = (index) => {
    let lesson = this.state.lesson;
    lesson.splice(index, 1);
    this.setState({ lesson });
  };

  // ตัวเลือกไม่ซ้ำ
  // handleISelectedOption = (currentValue) => {
  //     let { options_lesson, lesson } = this.state
  //     let deleteKey = [], option = []
  //     deleteKey = lesson.map(item => {
  //         if (item.id && (item.id !== currentValue)) {
  //             return item.id
  //         }
  //     })
  //     option = options_lesson.filter(el => !deleteKey.includes(el.id))
  //     return option
  // }

  render() {
    const state = this.state;
    const {
      handleSubmit,
      loading,
      checkSubjectType,
      checkSubject,
    } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.props.onSubmit)}>
        <Form.Group unstackable>
          <Field
            name="code"
            required={true}
            width="4"
            label="Code"
            component={renderField}
          />
          <Field
            name="name"
            type="text"
            required={true}
            width="12"
            component={renderField}
            label="Flight Subjects name"
          />
        </Form.Group>
        <Field
          name="hours"
          width="4"
          label="Flight (Hours)"
          component={renderTimeField}
        />
        <Field
          name="objective"
          label="Objective"
          component={renderTextAreaField}
        />
        {/* <Field
          name="checkSubject"
          label={
            <label>
              <b>STAGE CHECK</b>{" "}
            </label>
          }
          component={renderCheckboxField}
        /> */}

        <Field
          style={{ paddingLeft: "34px" }}
          name="checkSubjectType"
          component={renderCheckboxGroupField}
          inline
          label=""
          options={[
            {
              key: "caatCheck",
              name: "caatCheck",
              label: "CAAT Check  ",
              value: "caatCheck",
            },
            {
              key: "bacCheck",
              name: "bacCheck",
              label: "BAC Check",
              value: "bacCheck",
            },
          ]}
        />

        {checkSubjectType && checkSubjectType === "caatCheck" && (
          <Form.Group style={{ marginBottom: "0px" }}>
            <Field
              style={{ marginBottom: "0px" }}
              name="checkSubjectGroup"
              component={renderRadioGroupField}
              inline
              label=""
              options={[
                { key: "CPL", label: "CPL", value: "CPL" },
                { key: "PPL", label: "PPL", value: "PPL" },
                { key: "IR", label: "IR", value: "IR" },
                { key: "IP", label: "IP", value: "IP" },
                { key: "ME", label: "ME", value: "ME" },
              ]}
            />
          </Form.Group>
        )}
        {checkSubjectType && checkSubjectType === "bacCheck" && (
          <Wrap style={{ marginBottom: "0px" }}>
            <Form.Field>
              <div style={{ paddingLeft: "20%" }}>CPL : </div>
              <Field
                style={{ marginBottom: "0px" }}
                name="checkSubjectGroup"
                component={renderRadioGroupField}
                label=""
                inline
                options={[
                  // { key: "CPL", label: "CPL", value: "CPL" },
                  { key: "CPPL", label: "CPPL", value: "CPPL" },
                  { key: "CIR", label: "CIR", value: "CIR" },
                  { key: "CADV", label: "CADV", value: "CADV" },
                  { key: "CME", label: "CME", value: "CME" },
                ]}
              />
            </Form.Field>
            <br />
            <Form.Field>
              <Field
                style={{ marginBottom: "0px" }}
                name="checkSubjectGroup"
                component={renderRadioGroupField}
                label=""
                inline
                options={[
                  { key: "PPL", label: "PPL", value: "PPL" },
                  { key: "IR", label: "IR", value: "IR" },
                  { key: "IP", label: "IP", value: "IP" },
                  { key: "ME", label: "ME", value: "ME" },
                  { key: "ADV", label: "ADV", value: "ADV" },
                  { key: "BP", label: "BP", value: "BP" },
                  { key: "BI", label: "BI", value: "BI" },
                ]}
              />
            </Form.Field>
          </Wrap>
        )}
        <Field
          name="additional"
          label={
            <label>
              <b>Additional Subjects</b>{" "}
            </label>
          }
          component={renderCheckboxField}
        />
        <br />
        {state.loadData ? (
          <div style={{ textAlign: "center" }}>
            <Icon loading name="circle notch" />
            <br />
            Loading..
          </div>
        ) : (
          <Wrap>
            {" "}
            <Table compact celled striped style={{ marginBottom: "30px" }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell className="header-table" width="6">
                    Lesson
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    className="header-table"
                    width="6"
                    textAlign="center"
                  >
                    Reference
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    className="header-table"
                    width="2"
                    textAlign="center"
                  >
                    MR
                  </Table.HeaderCell>
                  {/*FIXME No.11*/}
                  <Table.HeaderCell
                      className="header-table"
                      width="2"
                      textAlign="center"
                  >
                    Highlight
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    className="header-table"
                    width="1"
                  ></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {state.lesson.map((item, index) => (
                  <Table.Row>
                    <Table.Cell>
                      <Form.Select
                        search
                        name="name"
                        fluid
                        options={state.options_lesson}
                        placeholder="Please Select"
                        value={item.id}
                        onChange={(e, value) => this.onChange(e, value, index)}
                      />
                    </Table.Cell>

                    <Table.Cell textAlign="right">
                      <Form.Input
                        name="reference"
                        value={item.reference}
                        width="16"
                        placeholder="Reference"
                        onChange={(e, value) =>
                          this.onChangeLesson(e, value, index)
                        }
                      />
                      <Form.Input
                        name="url"
                        value={item.url}
                        width="16"
                        placeholder="Link"
                        onChange={(e, value) =>
                          this.onChangeLesson(e, value, index)
                        }
                      />
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Form.Select
                        name="MR"
                        value={item.MR}
                        fluid
                        options={[
                          { key: "1", text: "1", value: "1" },
                          { key: "2", text: "2", value: "2" },
                          { key: "3", text: "3", value: "3" },
                          { key: "D", text: "D", value: "D" },
                        ]}
                        placeholder="MR"
                        onChange={(e, value) =>
                          this.onChangeLesson(e, value, index)
                        }
                      />
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <div className="ui checked checkbox">
                        <input type="checkbox"
                               name={`highlight.${item.id}`}
                               onChange={(e, value) =>
                                   this.onChangeLesson(e, {name: 'highlight', value: !item.highlight ? 1 : 0}, index)
                               }
                               checked={item.highlight === 1 || item.highlight === true}
                               value={item.highlight}
                        />
                        <label></label>
                      </div>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Icon
                          link
                        color="red"
                        name="trash alternate"
                        onClick={() => this.handleItemClickDelete(index)}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
                <Table.Row>
                  <Table.Cell className="footer-table"></Table.Cell>
                  <Table.Cell className="footer-table" textAlign="right" />
                  <Table.Cell className="footer-table" textAlign="right" />
                  <Table.Cell className="footer-table" textAlign="center" />
                  <Table.Cell className="footer-table" textAlign="center">
                    <Button
                      color="blue"
                      size="small"
                      compact
                      type="button"
                      onClick={this.handleItemClickAdd}
                    >
                      {" "}
                      Add
                    </Button>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            {/*FIXME No.11*/}
            <Table compact celled striped style={{ marginBottom: "30px" }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell className="header-table" width="6">
                    Evaluation criteria
                  </Table.HeaderCell>
                  <Table.HeaderCell
                      className="header-table"
                      width="2"
                      textAlign="center"
                  >
                    Highlight
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {state.criteria.map((item, index) => (
                    <Table.Row>
                      <Table.Cell>
                        {item.name}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <div className="ui checked checkbox">
                          <input type="checkbox"
                                 name={`highlight.${item.id}`}
                                 onChange={(e, value) =>
                                     this.onChangeCriteria(e, {name: 'highlight', value: !item.highlight ? 1 : 0}, index)
                                 }
                                 checked={item.highlight === 1 || item.highlight === true}
                                 value={item.highlight}
                          />
                          <label></label>
                        </div>
                      </Table.Cell>
                    </Table.Row>
                ))}
              </Table.Body>
            </Table>
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column>
                  <Table
                    compact
                    celled
                    striped
                    style={{ marginBottom: "30px" }}
                    unstackable
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell className="header-table">
                          Syllabus - IP
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          className="header-table"
                          width="1"
                        ></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {state.logbookIP.map((item) =>
                        item.group ? (
                          <Wrap>
                            <Table.Row>
                              <Table.Cell
                                className="bold"
                                style={{ fontSize: "90%" }}
                                colSpan="2"
                              >
                                {item.name}
                              </Table.Cell>
                            </Table.Row>
                            {item.group.map((sub) => (
                              <Table.Row>
                                <Table.Cell
                                  className="bold"
                                  style={{
                                    fontSize: "90%",
                                    paddingLeft: "15px",
                                  }}
                                >
                                  <li>{sub.name}</li>
                                </Table.Cell>
                                <Table.Cell style={{ paddingBottom: "0px" }}>
                                  <Field
                                    name={`syllabusIP.${sub.id}`}
                                    width="11"
                                    component={renderCheckboxField}
                                  />
                                </Table.Cell>
                              </Table.Row>
                            ))}
                          </Wrap>
                        ) : (
                          <Table.Row>
                            <Table.Cell
                              className="bold"
                              style={{ fontSize: "90%" }}
                            >
                              {item.name}
                            </Table.Cell>
                            <Table.Cell style={{ paddingBottom: "0px" }}>
                              <Field
                                name={`syllabusIP.${item.id}`}
                                width="11"
                                component={renderCheckboxField}
                              />
                            </Table.Cell>
                          </Table.Row>
                        )
                      )}
                    </Table.Body>
                  </Table>
                </Grid.Column>
                <Grid.Column>
                  <Table
                    compact
                    celled
                    striped
                    style={{ marginBottom: "30px" }}
                    unstackable
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell className="header-table">
                          Syllabus - SP
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          className="header-table"
                          width="1"
                        ></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {state.logbookSP.map((item) =>
                        item.group ? (
                          <Wrap>
                            <Table.Row>
                              <Table.Cell
                                className="bold"
                                style={{ fontSize: "90%" }}
                                colSpan="2"
                              >
                                {item.name}
                              </Table.Cell>
                            </Table.Row>
                            {item.group.map((sub) => (
                              <Table.Row>
                                <Table.Cell
                                  className="bold"
                                  style={{
                                    fontSize: "90%",
                                    paddingLeft: "15px",
                                  }}
                                >
                                  <li>{sub.name}</li>
                                </Table.Cell>
                                <Table.Cell style={{ paddingBottom: "0px" }}>
                                  <Field
                                    name={`syllabusSP.${sub.id}`}
                                    width="11"
                                    component={renderCheckboxField}
                                  />
                                </Table.Cell>
                              </Table.Row>
                            ))}
                          </Wrap>
                        ) : (
                          <Table.Row>
                            <Table.Cell
                              className="bold"
                              style={{ fontSize: "90%" }}
                            >
                              {item.name}
                            </Table.Cell>
                            <Table.Cell style={{ paddingBottom: "0px" }}>
                              <Field
                                name={`syllabusSP.${item.id}`}
                                width="11"
                                component={renderCheckboxField}
                              />
                            </Table.Cell>
                          </Table.Row>
                        )
                      )}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Wrap>
        )}
        <br />
        <Button
          floated="left"
          type="submit"
          content="Save"
          compact
          color="green"
          loading={loading}
        />
        {/*  disabled={pristine || submitting} */}
        <Button
          floated="right"
          type="button"
          content="Cancel"
          compact
          color="green"
          basic
          onClick={this.props.onCancel}
        />
        <br />
      </Form>
    );
  }
}

FormflightSubjects = reduxForm({
  // a unique name for the form
  form: "formflightsubjects",
  validate,
  enableReinitialize: true,
})(FormflightSubjects);

// Decorate with connect to read form values
const selector = formValueSelector("formflightsubjects"); // <-- same as form name
FormflightSubjects = connect((state) => {
  const checkSubjectType = selector(state, "checkSubjectType");
  const checkSubject = selector(state, "checkSubject");

  return {
    checkSubjectType,
    checkSubject,
  };
})(FormflightSubjects);
export default FormflightSubjects;

// export default FormflightSubjects = reduxForm({
//   // a unique name for the form
//   form: "formflightsubjects",
//   validate,
//   enableReinitialize: true
// })(FormflightSubjects);
