// const validate = values => {
//   const errors = {};
//   return errors;
// };

const validate = (values) => {
  const errors = {};
  if (!values.reservationType) {
    errors.reservationType = true;
  }
  if (values.end < values.start) {
    errors.end = "Incorrect end time";
  }
  if (values.reservationType && values.reservationType.name.indexOf("Others") >= 0) {
    if (!values.reservationName) {
      errors.reservationName = true;
    }
    if (!values.room) {
      errors.room = true;
    }
  } else if (
    values.reservationType &&
    values.reservationType.name.indexOf("Ground") >= 0
  ) {
    if (!values.course) {
      errors.course = true;
    }
    if (!values.groundSubjects) {
      errors.groundSubjects = true;
    }
    if (!values.attendees || values.attendees.length === 0) {
      errors.studentGroup = true;
      errors.studentId = true;
    }
    if (!values.typeGroundInstructor) {
      errors.typeGroundInstructor = true;
    }

    if (!values.instructor) {
      errors.instructor = true;
    }

    if (!values.room) {
      errors.room = true;
    }
  } else if (
    values.reservationType &&
    values.reservationType.name.indexOf("Maintenance") >= 0
  ) {
    if (!values.aircraft) {
      errors.aircraft = true;
    }
  } else if (
    values.reservationType &&
    values.reservationType.name.indexOf("Flight") >= 0
  ) {
    if (values.reservationType.name.indexOf("Training") >= 0) {
      if (values.flightTypeIPSV && values.flightTypeIPSV === "svflight") {
        if (!values.instructorSVId) errors.instructorSVId = true;
      } else if (
        values.flightTypeIPSV &&
        values.flightTypeIPSV === "ipproficiency"
      ) {
        if (!values.instructorProId) errors.instructorProId = true;
      }
      // if(values.flightTypeMonitor && values.flightTypeMonitor === 'monitor'){
      //   if(!values.monitorId)
      //    errors.monitorId  = true
      // }
    }

    if (values.reservationType.name.indexOf("Additional") >= 0) {
      if (!values.flightSubjects) {
        errors.flightSubjects = true;
      }
      if (!values.grade_type) {
        errors.grade_type = true;
      }
      if (!values.aircraft) {
        errors.aircraft = true;
      }

      if (!values.departureId) {
        errors.departureId = true;
      }

      if (!values.destinationId) {
        errors.destinationId = true;
      }

      if (!values.attendees || values.attendees.length === 0) {
        // errors.studentId = true;
      }
    } else if (values.reservationType.name.indexOf("Non") >= 0) {
      if (!values.attendees || values.attendees.length === 0) {
        // errors.studentId = true;
      }

      if (!values.flightName) {
        errors.flightName = true;
      }

      if (!values.aircraft) {
        errors.aircraft = true;
      }

      if (!values.departureId) {
        errors.departureId = true;
      }

      if (!values.destinationId) {
        errors.destinationId = true;
      }
    } else {
      if (!values.course) {
        errors.course = true;
      }

      if (!values.studentId || values.studentId.length === 0) {
        errors.studentId = true;
      }

      if (!values.flightSubjects) {
        errors.flightSubjects = true;
      }
      if (!values.grade_type) {
        errors.grade_type = true;
      }
      if (!values.instructor) {
        errors.instructor = true;
      }

      if (!values.aircraft) {
        errors.aircraft = true;
      }

      if (!values.departureId) {
        errors.departureId = true;
      }

      if (!values.destinationId) {
        errors.destinationId = true;
      }
    }
  }
  return errors;
};

export default validate;
