import React, {Component, useRef} from "react";
import {
  Segment,
  Header,
  Form,
  Button,
  Icon,
  Image,
  Label,
  Table,
  Input,
  Checkbox,
  List,
  Grid,
  Loader,
} from "semantic-ui-react";
import HeaderWithMenu from "../../components/headerwithmenu";
import * as config from "../../config";
import {
  getReservation,
  getAttendanceAdmin,
  updateAttendance,
  uploadDocAttendance,
} from "./service";
import TableList from "../../components/table";
import Wrap from "../../components/wrap";
import moment from "moment";
import ModalForm from "../../components/modal";
import DayPicker from "../../components/dayPicker";
import {uploadImageMed} from "../People/service";

class Attendance extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.state = {
      seletedDate: new Date(),
      rawData: [],
      data: [],
      loading: true,
      detailReservation: null,
      studentAttendance: [],
      allChecked: false,
      currentStuID:null,
      column: [
        {
          Header: "Title",
          accessor: "title",
          // style: { textAlign: "center" },
          sortable: false,
          Cell: (cellInfo) => (
            <Wrap>
              <span
                className="bold"
                style={{ cursor: "pointer" }}
                onClick={() => this.onClickRow(cellInfo.original)}
              >
                {cellInfo.original.title}
              </span>
            </Wrap>
          ),
        },
        // {
        //   Header: "Course",
        //   width: 100,
        //   accessor: "courseName",
        //   sortable: false
        // },
        {
          Header: "Instructor",
          accessor: "instructorName",
          sortable: false,
        },
        {
          Header: "Room",
          accessor: "roomName",
          width: 150,
          sortable: false,
        },
        {
          Header: "Time",
          accessor: "start",
          style: { textAlign: "center" },
          width: 250,
          sortable: false,
          Cell: (cellInfo) => (
            <p>
              <p>
                {moment(cellInfo.original.start).format("MMMM Do YYYY") ===
                moment(cellInfo.original.end).format("MMMM Do YYYY")
                  ? moment(cellInfo.original.start).format(
                      "ddd, MMMM Do YYYY HH:mm"
                    ) +
                    " - " +
                    moment(cellInfo.original.end).format("HH:mm")
                  : moment(cellInfo.original.start).format(
                      "MMMM Do YYYY, HH:mm"
                    ) +
                    " - " +
                    moment(cellInfo.original.end).format("MMMM Do YYYY, HH:mm")}
              </p>
            </p>
          ),
        },
        {
          Header: "Status",
          accessor: "status",
          sortable: false,
          style: { textAlign: "center" },
          width: 150,
          Cell: (cellInfo) => (
            <span>
              {cellInfo.original.status === 0 ? (
                <Label
                  size="small"
                  className="statusLabel"
                  style={{
                    backgroundColor: "#FF9800",
                    color: "#FFFFFF",
                    border: "1px solid #FF9800",
                    borderRadius: "14px",
                  }}
                >
                  Reserved
                </Label>
              ) : cellInfo.original.status === 1 ? (
                <Label
                  size="small"
                  className="statusLabel"
                  style={{
                    backgroundColor: "#56B9F4",
                    color: "#FFFFFF",
                    border: "1px solid #56B9F4",
                    borderRadius: "14px",
                  }}
                >
                  Dispatched
                </Label>
              ) : cellInfo.original.status === 2 ? (
                <Label
                  size="small"
                  className="statusLabel"
                  style={{
                    backgroundColor: "#27A8A2",
                    color: "#FFFFFF",
                    border: "1px solid #27A8A2",
                    borderRadius: "14px",
                  }}
                >
                  Completed
                </Label>
              ) : cellInfo.original.status === 3 ? (
                <Label
                  size="small"
                  className="statusLabel"
                  style={{
                    backgroundColor: "#CE2921",
                    color: "#FFFFFF",
                    border: "1px solid #CE2921",
                    borderRadius: "14px",
                  }}
                >
                  Cancelled
                </Label>
              ) : cellInfo.original.status === 4 ? (
                <Label
                  size="small"
                  className="statusLabel"
                  style={{
                    backgroundColor: "#56B9F4",
                    color: "#FFFFFF",
                    border: "1px solid #56B9F4",
                    borderRadius: "14px",
                  }}
                >
                  Landing
                </Label>
              ) : (
                <Label
                  size="small"
                  className="statusLabel"
                  style={{
                    backgroundColor: "#56B9F4",
                    color: "#FFFFFF",
                    border: "1px solid #56B9F4",
                    borderRadius: "14px",
                  }}
                >
                  No Status
                </Label>
              )}
            </span>
          ),
        },
      ],
    };
  }
  close = () =>
    this.setState({
      open: false,
      detailReservation: null,
      studentAttendance: [],
    });
  async onClickRow(data) {
    let { studentAttendance, allChecked } = this.state;
    // console.log("data", data);
    // let qString = "/" + data.id + "?_embed=attendance";
    let qString = "/" + data.id;
    await this.getDetailReservation(qString);
    // await this.setState(prevState => {
    //   let { studentAttendance, allChecked } = prevState;
    //   if (allChecked) {
    //     studentAttendance = studentAttendance.map(item => ({ ...item, attendees: true }));
    //   }
    //   return { studentAttendance,}
    // })
    await this.setState({ open: true });
    // console.log('1',allChecked);

    // allChecked = studentAttendance.every(item => item.attendees);
    // await allChecked = studentAttendance.every((member) => {
    //   return member.attendees === false
    // });
    // await this.setState(prevState =>{
    //   allChecked = studentAttendance.every((member) => {
    //     console.log('studentAttendance member',member);
    //     console.log('member',member);
    //     return member.attendees === true
    //   });
    //   console.log('2',allChecked);
    //   return { allChecked }
    // })
    // await this.setState({ allChecked })
  }

  getDetailReservation = (qString) => {
    getReservation(qString).then((res) => {
      this.setState({ loading: false });
      if (res && res.status === 200) {
        // let options = []
        // res.data.attendees.map(members => {
        //   options.push({ ...members, attendees : true})
        // })
        // console.log('options studentAttendance',options)
        this.setState({
          detailReservation: res.data,
          studentAttendance: res.data.attendees,
          testSubject: res.data.testSubject,
          allChecked: false,
        });
        // console.log('Reservation', res)
      }
    });
  };
  getReservationAll = (seletedDate) => {
    this.setState({ loading: true });
    let qString = moment(seletedDate).format("YYYY-MM-DD");
    getAttendanceAdmin("?date=" + qString).then((res) => {
      this.setState({ loading: false });
      if (res && res.status === 200) {
        this.setState({ data: res.data });
        console.log("ReservationAdmin", res.data);
      }
    });
  };

  handleChange = (e) => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    this.setState((prevState) => {
      let { studentAttendance, allChecked } = prevState;
      if (itemName === "checkAll") {
        allChecked = checked;
        studentAttendance = studentAttendance.map((item) => ({
          ...item,
          attendees: checked,
        }));
      } else {
        studentAttendance = studentAttendance.map((item) =>
          // console.log(item.id, itemName)
          parseInt(item.id) === parseInt(itemName)
            ? { ...item, attendees: checked }
            : item
        );
        allChecked = studentAttendance.every((item) => item.attendees);
      }
      return { studentAttendance, allChecked };
    });
  };
  handleChangePassed = (e) => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    this.setState((prevState) => {
      let { studentAttendance } = prevState;
      studentAttendance = studentAttendance.map((item) =>
        // console.log(item.id, itemName)
        parseInt(item.id) === parseInt(itemName)
          ? { ...item, passed: checked }
          : item
      );
      return { studentAttendance };
    });
  };
  // FIXME No.1
  handleChangeRemark = (e) => {
    let itemName = e.target.name;
    let value = e.target.value;
    this.setState((prevState) => {
      let { studentAttendance } = prevState;
      studentAttendance = studentAttendance.map((item) =>
          // console.log(item.id, itemName)
          parseInt(item.id) === parseInt(itemName) ? { ...item, remark: value }: item
      );
      return { studentAttendance };
    });
  };

  handleSubmitFile = (event) => {
    const file = event.target.files[0]; // รับค่าไฟล์จาก input
    let { currentStuID } = this.state;
    if (file) {
      this.setState({ loading: true, uploadError: null });
      let { detailReservation } = this.state;
      var formData = new FormData();
      formData.append("file", file);
      formData.append("stuID", currentStuID);
      uploadDocAttendance(detailReservation.id, formData).then((res) => {
        this.fileInputRef = React.createRef();
        // event.target.value = null;
        if (res && res.status === 200) {
          if (res.data.url) {
            this.setState((prevState) => {
              let { studentAttendance } = prevState;
              studentAttendance = studentAttendance.map((item) =>
                  // console.log(item.id, itemName)
                  parseInt(item.id) === parseInt(currentStuID) ? { ...item, docUrl: res.data.url }: item
              );

              this.setState(prevState => ({
                ...prevState,
                currentStuID: null
              }));
              this.fileInputRef = React.createRef();
              return { studentAttendance };
            });
          } else {
            this.close();
          }
        } else if (res && res.status === 422) {
          this.setState({ FileError: "Please browse file" });
        }
        this.fileInputRef.current.value = "";
        this.setState({ loading: false });
      });
    } else {
      this.setState({ uploadError: "No file selected." });
    }
  };

  save = () => {
    this.setState({ loading: true });
    let { studentAttendance, detailReservation, seletedDate } = this.state;
    let option = [],
      passed = [],
      docUrl = [],
      remark = [];
    studentAttendance.map((item) => {
      if (item.attendees === true || item.attendees === 1) option.push(item.id);
      if (item.passed === true || item.passed === 1) passed.push(item.id);
      if (item.remark) remark.push({id:item.id,remark:item.remark});
      if (item.docUrl) docUrl.push({id:item.id,docUrl:item.docUrl});
    });

    // console.log('save',option)
    let dataSent = {
      attendees: option,
      passed: passed,
      remark: remark,
      docUrl: docUrl,
    };
    updateAttendance(detailReservation.id, dataSent).then((res) => {
      if (res && res.status === 200) {
        this.close();
        // let qString = "?displayType=2";
        this.getReservationAll(seletedDate);
      }
      this.setState({ loading: false });
    });
  };

  checkValidSubmit =()=>{
    let option = [],
        passed = [],
        remark = [];
    let { studentAttendance, detailReservation, seletedDate } = this.state;
    studentAttendance.map((item) => {
      if (item.attendees === true || item.attendees === 1) option.push(item.id);
      if (item.passed === true || item.passed === 1) passed.push(item.id);
      if (item.remark) remark.push({id:item.id,remark:item.remark});
    });

    let total_students = this.state.studentAttendance.length
    let total_checked = option.length
    let total_remark = remark.length


    if (total_checked !== total_students){
      if ((total_students - total_checked) !== total_remark){
        return true
      }
    }

    return false
  }
  handleChangeDate = (seletedDate) => {
    this.setState({ seletedDate, loading: true });
    this.getReservationAll(seletedDate);
  };
  componentDidMount() {
    // let qString = "?displayType=2";
    this.getReservationAll(this.state.seletedDate);
  }

  render() {
    let state = this.state;
    return (
      <Segment className="contentSegment" textAlign="left">
        <HeaderWithMenu name="Attendance"></HeaderWithMenu>
        <Form>
          <Form.Group style={{ marginBottom: "0px" }}>
            <Form.Field width="5" style={{ paddingTop: "10px" }}>
              <DayPicker
                day={state.seletedDate}
                onChange={this.handleChangeDate}
              />
            </Form.Field>
            <Form.Field width="8"></Form.Field>
            <Form.Field
              width="5"
              style={{ paddingTop: "4px", textAlign: "right" }}
            ></Form.Field>
          </Form.Group>
        </Form>
        {state.loading ? (
          <Loader active inline="centered" content="Loading" />
        ) : (
          <Table celled padded compact unstackable style={{ fontSize: "95%" }}>
            <Table.Header>
              <Table.Row textAlign="center">
                <Table.HeaderCell>Title</Table.HeaderCell>
                <Table.HeaderCell>Instructor</Table.HeaderCell>
                <Table.HeaderCell>Room</Table.HeaderCell>
                <Table.HeaderCell>Time</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {state.data.map((item, index) => (
                <Table.Row
                  style={{ cursor: "pointer" }}
                  onClick={() => this.onClickRow(item)}
                >
                  <Table.Cell className="bold">{`${item.title}`}</Table.Cell>
                  <Table.Cell>
                    {item.instructorName && `${item.instructorName}`}
                  </Table.Cell>
                  <Table.Cell>{item.roomName && `${item.roomName}`}</Table.Cell>
                  <Table.Cell>
                    <p>
                      {moment(item.start).format("MMMM Do YYYY") ===
                      moment(item.end).format("MMMM Do YYYY")
                        ? moment(item.start).format("ddd, MMMM Do YYYY HH:mm") +
                          " - " +
                          moment(item.end).format("HH:mm")
                        : moment(item.start).format("MMMM Do YYYY, HH:mm") +
                          " - " +
                          moment(item.end).format("MMMM Do YYYY, HH:mm")}
                    </p>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <span>
                      {item.status === 0 ? (
                        <Label
                          size="small"
                          className="statusLabel"
                          style={{
                            backgroundColor: "#FF9800",
                            color: "#FFFFFF",
                            border: "1px solid #FF9800",
                            borderRadius: "14px",
                          }}
                        >
                          Reserved
                        </Label>
                      ) : item.status === 1 ? (
                        <Label
                          size="small"
                          className="statusLabel"
                          style={{
                            backgroundColor: "#56B9F4",
                            color: "#FFFFFF",
                            border: "1px solid #56B9F4",
                            borderRadius: "14px",
                          }}
                        >
                          Dispatched
                        </Label>
                      ) : item.status === 2 ? (
                        <Label
                          size="small"
                          className="statusLabel"
                          style={{
                            backgroundColor: "#27A8A2",
                            color: "#FFFFFF",
                            border: "1px solid #27A8A2",
                            borderRadius: "14px",
                          }}
                        >
                          Completed
                        </Label>
                      ) : item.status === 3 ? (
                        <Label
                          size="small"
                          className="statusLabel"
                          style={{
                            backgroundColor: "#CE2921",
                            color: "#FFFFFF",
                            border: "1px solid #CE2921",
                            borderRadius: "14px",
                          }}
                        >
                          Cancelled
                        </Label>
                      ) : item.status === 4 ? (
                        <Label
                          size="small"
                          className="statusLabel"
                          style={{
                            backgroundColor: "#56B9F4",
                            color: "#FFFFFF",
                            border: "1px solid #56B9F4",
                            borderRadius: "14px",
                          }}
                        >
                          Landing
                        </Label>
                      ) : (
                        <Label
                          size="small"
                          className="statusLabel"
                          style={{
                            backgroundColor: "#56B9F4",
                            color: "#FFFFFF",
                            border: "1px solid #56B9F4",
                            borderRadius: "14px",
                          }}
                        >
                          No Status
                        </Label>
                      )}
                    </span>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
        {/*<TableList
          loading={this.state.loading}
          data={this.state.data}
          columns={this.state.column}
        />*/}

        <ModalForm
          icon="check square outline"
          content="Attendance"
          open={this.state.open}
          close={this.close}
          size="small"
        >
          {/*FIXME No.1*/}
          {/*TODO condirion reuire remark */}
          <Wrap>
            {state.detailReservation && (
              <Wrap>
                <Header as="h2" textAlign="center">
                  {state.detailReservation.title}
                </Header>
                <Grid centered>
                  <Grid.Column width={8}>
                    <List className="bold">
                      <List.Item>
                        <b>
                          <Icon name="clock" /> Date Time :{" "}
                        </b>{" "}
                        {moment(state.detailReservation.start).format(
                          "ddd, MMM D HH:mm"
                        )}{" "}
                        -{" "}
                        {moment(state.detailReservation.start).format(
                          "ddd, MMM D"
                        ) ===
                        moment(state.detailReservation.end).format("ddd, MMM D")
                          ? moment(state.detailReservation.end).format("HH:mm")
                          : moment(state.detailReservation.end).format(
                              "ddd, MMM D HH:mm"
                            )}
                      </List.Item>
                      <List.Item>
                        <b>
                          <Icon name="building" /> Room &nbsp; &nbsp; &nbsp;
                          &nbsp;:{" "}
                        </b>
                        {state.detailReservation.roomName}
                      </List.Item>
                      <List.Item>
                        <b>
                          <Icon name="user circle" /> Instructor :{" "}
                        </b>{" "}
                        {state.detailReservation.instructorName}
                      </List.Item>
                      <List.Item>
                        <b>
                          <Icon name="user circle" /> SP &nbsp;&nbsp; &nbsp;
                          &nbsp;&nbsp; &nbsp; &nbsp; :{" "}
                        </b>{" "}
                        {state.detailReservation.studentName}
                      </List.Item>
                    </List>
                  </Grid.Column>
                </Grid>
              </Wrap>
            )}

            <Table compact celled style={{ marginBottom: "30px" }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    className="header-table"
                    width={1}
                    textAlign="center"
                  >
                    <Input
                      type="checkbox"
                      name="checkAll"
                      checked={this.state.allChecked}
                      onChange={this.handleChange}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell className="header-table" textAlign="center">
                    Name
                  </Table.HeaderCell>
                  {state.testSubject && (
                    <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                    >
                      Pass
                    </Table.HeaderCell>
                  )}

                  <Table.HeaderCell className="header-table" textAlign="center">
                    Class
                  </Table.HeaderCell>
                  <Table.HeaderCell className="header-table" textAlign="center">
                    Remark
                  </Table.HeaderCell>
                  <Table.HeaderCell className="header-table" textAlign="center">
                    Link
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.studentAttendance.length > 0 &&
                  this.state.studentAttendance.map((item, index) => {
                    return (
                      <Table.Row key={item.id}>
                        <Table.Cell textAlign="center" verticalAlign="top">
                          <Input
                            key={item.id}
                            type="checkbox"
                            name={item.id}
                            value={item.id}
                            checked={item.attendees}
                            onChange={this.handleChange}
                          />
                        </Table.Cell>
                        <Table.Cell verticalAlign="top">
                          {item.studentId} {item.firstName} {item.lastName}
                        </Table.Cell>
                        {state.testSubject && (
                          <Table.Cell textAlign="center" verticalAlign="top">
                            <Input
                              key={item.id}
                              type="checkbox"
                              name={item.id}
                              value={item.id}
                              checked={item.passed}
                              onChange={this.handleChangePassed}
                            />
                          </Table.Cell>
                        )}

                        <Table.Cell textAlign="center" verticalAlign="top">
                          {item.class}
                        </Table.Cell>
                        <Table.Cell textAlign="center" verticalAlign="top">
                          <Input
                              key={item.id}
                              placeholder={`remark`}
                              type="text"
                              name={item.id}
                              value={item.remark}
                              error={!item.attendees && !item.remark}
                              onChange={this.handleChangeRemark}
                          />
                        </Table.Cell>
                        <Table.Cell textAlign="center" verticalAlign="top">
                          {item && item.docUrl &&
                              <div>
                                <Button
                                    size="small"
                                    icon="external alternate"
                                    onClick={() => {
                                      window.open(item.docUrl, '_blank', 'noopener,noreferrer');
                                    }}
                                />
                              </div>
                          }
                          {item && !item.docUrl &&
                              <div>
                            <Button
                                content="Choose File"
                                size={'small'}
                                labelPosition="left"
                                icon="upload"
                                onClick={() => {
                                  this.setState({ currentStuID: item.id });
                                  this.fileInputRef.current.click();
                                }}
                            />
                            <input
                                ref={this.fileInputRef}
                                type="file"
                                hidden
                                onChange={this.handleSubmitFile}
                            />
                          </div>
                          }

                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
              </Table.Body>
            </Table>
            <Button
              floated="left"
              type="submit"
              content="Save"
              color="green"
              disabled={this.checkValidSubmit()}
              size="small"
              loading={this.state.loading}
              onClick={this.save}
            />
            <Button
              floated="right"
              type="button"
              content="Cancel"
              color="green"
              basic
              size="small"
              onClick={this.close}
            />
          </Wrap>
        </ModalForm>
      </Segment>
    );
  }
}

export default Attendance;
