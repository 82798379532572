import React, { Component } from "react";
import {
  Segment,
  Breadcrumb,
  Grid,
  Header,
  Image,
  Button,
  Dimmer,
  Loader,
  Modal,
  Icon,
} from "semantic-ui-react";
import { reduxForm } from "redux-form";
import * as config from "../../config";
import validate from "../../validate";
import {
  getStudentById,
  getStudentType,
  getCourseById,
  getAllClass,
  getAllCompany,
  getAllCourse,
  getAllHospital,
  getAllNotes,
  getAllInstructors,
  getReservationById,
  getLogbookByReservation,
  updateStudent,
  uploadImageProfile,
  deleteImageProfile,
  getSnagById,
  getSnagPassedById,
  getAttendanceById,
  exportAttendance,
} from "./service";
import { getSpl, getMed } from "../People/service";
import MainPeople from "../People/main";
import HeaderWithMenu from "../../components/headerwithmenu";
import ModalForm from "../../components/modal";
import Documents from "../People/documents";
import ScheduleById from "../Schedule/scheduleById";
import FormStudent from "../Student/studentForm";
import PortfolioProfile from "../People/portfolio";
import Wrap from "../../components/wrap";
import Hours from "../People/Hours";
import Notes from "../People/note";
import moment from "moment";
import UploadImage from "../../components/uploadImage";

class ProfileStudent extends Component {
  state = {
    locationState: this.props.location.state,
    bacUserData: JSON.parse(localStorage.getItem("bacUserData")),
    bac_permission: JSON.parse(localStorage.getItem("bac_permission")),
    status: 3,
    search: {
      status: 4,
    },
    data: [],
    dataEdit: null,
    dataDoc: [],
    dataCourse: [],
    note: [],
    options_class: [],
    options_company: [],
    options_course: [],
    options_hospital: [],
    options_instructors: [],
    options_studenttype: [],
    reservation: [],
    logbook: [],
    flightList: [
      { name: "SP - Dual Received", hours: "01:11" },
      { name: "SP - Single Engine", hours: "01:11" },
      { name: "SP - Multi Engines", hours: "01:11" },
      { name: "SP - Actual Instrument", hours: "01:11" },
      { name: "SP - SIM Single Engine", hours: "01:11" },
      { name: "SP - SIM Multi Engine", hours: "01:11" },
      { name: "SP - SIM A320", hours: "01:11" },
      { name: "SP - SOLO P15", hours: "01:11" },
      { name: "SP - SOLO XC", hours: "01:11" },
      { name: "SP - Dual XC", hours: "01:11" },
      { name: "SP - PIC Student", hours: "01:11" },
    ],
    attendance: [],
  };
  onClickUploadPhoto = () => {
    let { data } = this.state;
    this.setState({
      openUploadPhoto: true,
      imageURL: data.imageURL,
    });
  };
  onUploadPhoto = (file) => {
    if (file) {
      this.setState({ loading: true, uploadError: null });
      let { data } = this.state;
      var formData = new FormData();
      if (typeof file === "object") formData.append("image", file[0]);
      else formData.append("image", file);
      uploadImageProfile(data.id, formData).then((res) => {
        if (res && res.status === 200) {
          this.setState({
            imageURL: res.data.imageUrl,
          });
          this.getDataStudentById(data.id);
        } else if (res && res.status === 422) {
          this.setState({
            uploadError:
              res.data.errors &&
              res.data.errors.image &&
              res.data.errors.image[0],
          });
        } else {
          this.setState({
            uploadError: "Whoops, looks like something went wrong.",
          });
        }
        this.setState({ loading: false });
      });
    }
  };
  onDeletePhoto = () => {
    let { data } = this.state;
    deleteImageProfile(data.id).then((res) => {
      if (res && res.status === 200) {
        this.setState({
          imageURL: null,
        });
        this.getDataStudentById(data.id);
      }
      this.setState({ loading: false });
    });
  };
  getInstructors = (qString) => {
    getAllInstructors(qString).then((res) => {
      if (res && res.status === 200) {
        let options = [];
        res.data.map((item) => {
          options.push({
            key: item.id,
            text: `${item.firstName} ${item.lastName} (${
              item.nickName || "-"
            })`,
            value: item.id,
          });
        });
        this.setState({
          options_instructors: options,
          rawDataInstructors: res.data,
        });
      }
    });
  };
  getCourse = (qString) => {
    getCourseById(qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({ dataCourse: res.data });
      }
    });
  };
  getDataStudentById = (id) => {
    getStudentById(id).then((res) => {
      if (res && res.status === 200) {
        let dataSetEdit = {
          customerCode: res.data.customerCode,
          batchCode: res.data.batchCode,
          studentId: res.data.studentId,
          titleEng: res.data.titleEng,
          titleTh: res.data.titleTh,
          firstNameEng: res.data.firstNameEng,
          lastNameEng: res.data.lastNameEng,
          firstNameTh: res.data.firstNameTh,
          lastNameTh: res.data.lastNameTh,
          foreigner: res.data.foreigner,
          foreignerCountry: res.data.foreignerCountry,
          email: res.data.emailAddress,
          personnel_email: res.data.personnel_email,
          phone: res.data.phoneNumber,
          class: res.data.class && res.data.class.id,
          studentType: res.data.studentType && res.data.studentType.id,
          company: res.data.company && res.data.company.id,
          courseID: res.data.course && res.data.course.id,
          course: res.data.course,
          dateOfBirth: res.data.birthDate,
          gender: res.data.gender,
          username: res.data.username,
          isActive: res.data.isActive,
          nickName: res.data.nickName,
          status: res.data.status,
          startDrop: res.data.startDrop,
          endDrop: res.data.endDrop,
          idcard: res.data.idcard,
          address: res.data.address,
          degree: res.data.degree,
          institute: res.data.institute,
          height: res.data.height,
          weight: res.data.weight,
          identifyFeature: res.data.identifyFeature,
          religion: res.data.religion,
          relationship: res.data.relationship,
          children: res.data.children,
          motherFirstName: res.data.motherFirstName,
          motherLastName: res.data.motherLastName,
          motherPhone: res.data.motherPhone,
          motherAge: res.data.motherAge,
          motherOccupation: res.data.motherOccupation,
          fatherFirstName: res.data.fatherFirstName,
          fatherLastName: res.data.fatherLastName,
          fatherPhone: res.data.fatherPhone,
          fatherAge: res.data.fatherAge,
          fatherOccupation: res.data.fatherOccupation,
          spouseFirstName: res.data.spouseFirstName,
          spouseLastName: res.data.spouseLastName,
          spousePhone: res.data.spousePhone,
          spouseAge: res.data.spouseAge,
          spouseOccupation: res.data.spouseOccupation,
          emergencyName: res.data.emergencyName,
          emergencyPhone: res.data.emergencyPhone,
          licencenumber: res.data.licencenumber,
          issueddate: res.data.issueddate,
          new_company: res.data.new_company,
          last_company: res.data.last_company,
          job_name: res.data.job_name,
          job_position: res.data.job_position,
          year_employed: res.data.year_employed,
        };

        this.setState({ data: res.data, dataEdit: dataSetEdit });
      }
    });
  };
  getCompany = () => {
    getAllCompany().then((res) => {
      if (res && res.status === 200) {
        let options = [];
        res.data.map((item) => {
          options.push({ key: item.id, text: item.name, value: item.id });
        });
        this.setState({ options_company: options, rawDataCompany: res.data });
      }
    });
  };
  getClass = () => {
    getAllClass().then((res) => {
      if (res && res.status === 200) {
        let options = [];
        res.data.map((item) => {
          options.push({ key: item.id, text: item.name, value: item.id });
        });
        this.setState({ options_class: options, rawDataClass: res.data });
      }
    });
  };
  getOptionCourse = (qString) => {
    getAllCourse(qString).then((res) => {
      if (res && res.status === 200) {
        let options = [];
        res.data.map((item) => {
          options.push({
            key: item.id,
            text: `${item.name} (${item.desc}) ${moment(
              item.start,
              "DD-MM-YYYY"
            ).format("DD/MM/YYYY")} - ${moment(item.end, "DD-MM-YYYY").format(
              "DD/MM/YYYY"
            )}`,
            value: item.id,
          });
        });
        this.setState({ options_course: options, rawDataCourse: res.data });
      }
    });
  };
  getHospital = () => {
    getAllHospital().then((res) => {
      if (res && res.status === 200) {
        let options = [];
        res.data.map((item) => {
          options.push({ key: item.id, text: item.name, value: item.id });
        });
        this.setState({ options_hospital: options, rawDataHospital: res.data });
      }
    });
  };
  getNotes = (id) => {
    let { bacUserData, bac_permission } = this.state;
    getAllNotes(id).then((res) => {
      if (res && res.status === 200) {
        let options = [];
        if (bacUserData.id === id) {
          res.data.map((members) => {
            if (members.internal === "false" || members.internal === false) {
              options.push(members);
            }
          });
        } else {
          if (
            this.checkPermissionMenu("Personnel", [
              "manage-instructor",
              "manage-student",
              "manage-staff",
            ])
          ) {
            res.data.map((members) => {
              options.push(members);
            });
          } else {
            res.data.map((members) => {
              if (members.internal === "false" || members.internal === false) {
                options.push(members);
              }
            });
          }
        }
        this.setState({ note: options, rawNote: res.data });
      }
    });
  };
  onClickEdit = () => {
    let { dataEdit } = this.state;
    if (dataEdit.isActive === "true" || dataEdit.isActive === true)
      this.setState({ activeUser: true });
    else this.setState({ activeUser: false });
    this.setState({ open: true });
    this.getInstructors("?isActive=true");
  };
  onClickCancel = () => {
    this.setState({ open: false, openUploadPhoto: false });
  };
  onSubmitEditStudent = (data) => {
    this.setState({ loading: true });
    if (data.isActive) data.isActive = "true";
    else data.isActive = "false";
    let dataStudent = {
      id: this.state.currentId,
      ...data,
    };
    updateStudent(dataStudent).then((res) => {
      if (res && res.status === 200) {
        this.setState({ data: [] });
        this.getDataStudentById(this.state.currentId);
        this.onClickCancel();
      } else if (res && res.status === 409) {
        this.setState({ errorMessage: "Username or Email already exists." });
      }
      this.setState({ loading: false });
    });
  };
  getReservation = (id) => {
    let qString = "?subjectId=" + id;
    getReservationById(qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({ reservation: res.data });
      }
    });
    getLogbookByReservation().then((res) => {
      if (res && res.status === 200) {
        this.setState({ logbook: res.data });
      }
    });
  };
  getSplById = (id) => {
    getSpl(id).then((res) => {
      if (res && res.status === 200) {
        this.setState({ dataSpl: res.data });
      }
    });
  };
  getMedById = (id) => {
    getMed(id).then((res) => {
      if (res && res.status === 200) {
        this.setState({ dataMed: res.data });
      }
    });
  };
  onDisable = () => {
    let { dataEdit } = this.state;
    this.setState({ loadingUp: true });
    dataEdit.isActive = "false";
    let dataUpdate = {
      id: this.state.currentId,
      ...dataEdit,
    };
    updateStudent(dataUpdate).then((res) => {
      if (res && res.status === 200) {
        this.setState({ activeUser: false });
        this.getDataStudentById(this.state.currentId);
      }
      this.setState({ loadingUp: false });
    });
  };
  onEnable = () => {
    let { dataEdit } = this.state;
    this.setState({ loadingUp: true });
    dataEdit.isActive = "true";
    let dataUpdate = {
      id: this.state.currentId,
      ...dataEdit,
    };
    updateStudent(dataUpdate).then((res) => {
      if (res && res.status === 200) {
        this.setState({ activeUser: true });
        this.getDataStudentById(this.state.currentId);
      }
      this.setState({ loadingUp: false });
    });
  };
  getAllStudentType = () => {
    getStudentType().then((res) => {
      if (res && res.status === 200) {
        let options = [];
        res.data.map((item) => {
          options.push({ key: item.id, text: item.name, value: item.id });
        });
        this.setState({
          options_studenttype: options,
          rawDataStudenttype: res.data,
        });
      }
    });
  };
  getAttendance = (qString) => {
    getAttendanceById(qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({ attendance: res.data });
      }
    });
  };
  onClickExportAttendance = (qString) => {
    this.setState({ loadPopup: true, errorMessage: null });
    exportAttendance(this.state.currentId, qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({ loadPopup: false });
        var newBlob = new Blob([res.data]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var newBlobIE = new Blob([res.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          return navigator.msSaveOrOpenBlob(
            newBlobIE,
            "Attendance_" + moment().format("YYYYMMDDHHmmss") + ".xlsx"
          );
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        this.setState({ link: link.href });
        link.download =
          "Attendance_" + moment().format("YYYYMMDDHHmmss") + ".xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL

          window.URL.revokeObjectURL(data);
        }, 100);
      } else {
        this.setState({
          errorMessage: "The request is taking too long. Please try again",
        });
      }
    });
  };
  getSnag = (qString) => {
    getSnagById(qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({ snag: res.data });
      }
    });
    getSnagPassedById(qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({ snagPassed: res.data });
      }
    });
  };
  checkPermissionMenu = (group, permission) => {
    let { bac_permission } = this.state;
    let userpermissionGroup = bac_permission.find(
      (item) => item.group === group
    );
    let allowed = permission.find((item) => {
      if (
        userpermissionGroup &&
        userpermissionGroup.permission &&
        userpermissionGroup.permission.indexOf(item) !== -1
      )
        return item;
    });
    if (allowed) return true;
    else return false;
  };
  componentDidMount() {

    let currentId = null;
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.id
    ) {
      currentId =this.props.location.state.id 

    }else{
      const params = new URLSearchParams(this.props.location.search);
      currentId = params.get('id'); // bar
      // currentId = this.props.location.search; 
    }

      this.setState({currentId,})
      this.getDataStudentById(currentId);
      this.getClass();
      this.getCompany();
      this.getAllStudentType();
      let qString2 = "?additionalCourse=false&active=true";
      this.getOptionCourse(qString2);
      this.getNotes(currentId);
      // this.getHospital();
      // this.getReservation(currentId);
      // this.getSplById(currentId);
      // this.getMedById(currentId);
      // this.getSnag(currentId);
      // this.getAttendance(currentId);
    
  }

  render() {
    const state = this.state;
    const { handleSubmit, pristine, submitting } = this.props;
    // console.log("state.options_hospital", state.options_hospital)
    if (state.data.length === 0)
      return (
        <Segment className="contentSegment" style={{ minHeight: "50vh" }}>
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        </Segment>
      );

    return (
      <div>
        <Modal
          size={state.errorMessage ? "tiny" : "mini"}
          open={state.loadPopup}
          closeIcon={state.errorMessage ? true : false}
          dimmer="blurring"
          closeOnDimmerClick={false}
          onClose={() =>
            this.setState({ loadPopup: false, errorMessage: null })
          }
        >
          <Wrap>
            {state.errorMessage ? (
              <Wrap>
                <Modal.Header>
                  <Icon name="warning sign" /> Warning
                </Modal.Header>
                <Modal.Content>{state.errorMessage}</Modal.Content>
              </Wrap>
            ) : (
              <Modal.Content>
                <div style={{ textAlign: "center" }}>
                  <Icon loading name="circle notch" />
                  <br />
                  Waiting Download File
                </div>
              </Modal.Content>
            )}
          </Wrap>
        </Modal>
        <ModalForm
          open={state.openUploadPhoto}
          icon="image outline"
          size="small"
          content={`${state.data.firstNameEng} ${state.data.lastNameEng}`}
          close={this.onClickCancel}
          save={this.onUploadPhoto}
        >
          <UploadImage
            uploadError={state.uploadError}
            loading={state.loading}
            imageUrl={state.imageURL}
            onChange={this.onUploadPhoto}
            onClickDelete={this.onDeletePhoto}
          />
        </ModalForm>
        <ModalForm
          icon="edit"
          content="Edit Profile"
          open={state.open}
          close={this.onClickCancel}
          size="large"
        >
          <FormStudent
            isAdd={false}
            haveCourse={state.dataEdit.course ? true : false}
            initialValues={state.dataEdit ? state.dataEdit : null}
            onSubmit={this.onSubmitEditStudent}
            onCancel={this.onClickCancel}
            activeUser={state.activeUser}
            errorMessage={state.errorMessage}
            onDisable={this.onDisable}
            onEnable={this.onEnable}
            loading={state.loading}
            loadingUp={state.loadingUp}
            isEdit={state.dataEdit ? true : false}
            options_class={state.options_class}
            options_company={state.options_company}
            options_course={state.options_course}
            options_hospital={state.options_hospital}
            options_studenttype={state.options_studenttype}
            options_instructors={state.options_instructors}
          />
        </ModalForm>

        <Segment className="contentSegment" textAlign="left">
          <HeaderWithMenu name="Student">
            {this.checkPermissionMenu("Personnel", ["manage-student"]) && (
              <Wrap>
                <Button
                  size="small"
                  content="Edit Profile"
                  onClick={this.onClickEdit}
                />
                <Button
                  size="small"
                  content="Upload Photo"
                  onClick={this.onClickUploadPhoto}
                />
              </Wrap>
            )}
          </HeaderWithMenu>
          <Breadcrumb>
            {state.locationState && !state.locationState.status && (
              <Wrap>
                <Breadcrumb.Section
                  onClick={() => this.props.history.push("/Students")}
                  style={{ color: "grey" }}
                >
                  Student
                </Breadcrumb.Section>
                <Breadcrumb.Divider icon="right angle" />
              </Wrap>
            )}
            <Breadcrumb.Section active>Student Profile</Breadcrumb.Section>
          </Breadcrumb>
          <br /> <br />
          <Grid>
            <Grid.Row
              id="myProfile"
              columns={2}
              divided
              style={{ paddingBottom: 0 }}
            >
              <Grid.Column width={4}>
                <Image
                  centered
                  src={
                    state.data.imageURL
                      ? state.data.imageURL + "?time=" + new Date()
                      : "https://app.flightschedulepro.com/images/img-01.png"
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src =
                      "https://app.flightschedulepro.com/images/img-01.png";
                  }}
                />
              </Grid.Column>
              <Grid.Column width={12}>
                {/* <Header as='h2' style={{ color: config.colorTheme }}>{state.data.studentId + ' ' + state.data.firstName + ' ' + state.data.lastName + ' ' + state.data.customerCode}</Header> */}
                <Header as="h2" style={{ color: config.colorTheme }}>
                  <Header.Content>
                    {state.data.studentId && state.data.studentId}&nbsp;&nbsp;
                    {state.data.firstNameEng && state.data.firstNameEng}
                    &nbsp;&nbsp;
                    {state.data.lastNameEng && state.data.lastNameEng}
                    &nbsp;&nbsp;
                    {state.data.customerCode && state.data.customerCode}
                    <Header.Subheader style={{ color: config.colorTheme }}>
                      {state.data.firstNameTh && state.data.firstNameTh}{" "}
                      &nbsp;&nbsp;
                      {state.data.lastNameTh && state.data.lastNameTh}{" "}
                      &nbsp;&nbsp;
                      {state.data.nickName
                        ? "( " + state.data.nickName + " )"
                        : ""}
                    </Header.Subheader>
                  </Header.Content>
                </Header>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      {/* <br /> */}
                      <Grid>
                        <Grid.Row>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Batch Code</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.batchCode ? state.data.batchCode : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Nationality</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.foreignerCountry
                              ? state.data.foreignerCountry
                              : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Phone</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.phoneNumber
                              ? state.data.phoneNumber
                              : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">BAC Email</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.emailAddress
                              ? state.data.emailAddress
                              : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Personnel Email</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.personnel_email
                              ? state.data.personnel_email
                              : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Username</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.username ? state.data.username : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Company</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.company ? state.data.company.name : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Student Type</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.studentType
                              ? state.data.studentType.name
                              : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Class</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.class ? state.data.class.name : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Course</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.course ? (
                              <div>
                                {`${state.data.course.name} (${state.data.course.desc})`}
                                <br />
                                {`(${moment(
                                  state.data.course.start,
                                  "DD-MM-YYYY"
                                ).format("DD MMMM YYYY")} - ${moment(
                                  state.data.course.end,
                                  "DD-MM-YYYY"
                                ).format("DD MMMM YYYY")})`}
                              </div>
                            ) : (
                              "-"
                            )}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Additional Course</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.additionalCourse
                              ? state.data.additionalCourse.length > 0
                                ? state.data.additionalCourse.map((addi) => (
                                    <p style={{ marginBottom: 0 }}>
                                      {`${addi.name} (${addi.desc})`}
                                      <br />
                                      {`(${moment(
                                        addi.start,
                                        "DD-MM-YYYY"
                                      ).format("DD MMMM YYYY")} - ${moment(
                                        addi.end,
                                        "DD-MM-YYYY"
                                      ).format("DD MMMM YYYY")})`}
                                      <br />
                                    </p>
                                  ))
                                : "-"
                              : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        {/*<Grid.Row style={{ paddingTop: 0 }}>
                                                    <Grid.Column width='5'> <p className="bold">Class</p> </Grid.Column >
                                                    <Grid.Column width='11'>
                                                        {state.data.class ? state.data.class.name : '-'}
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row style={{ paddingTop: 0 }}>
                                                    <Grid.Column width='5'> <p className="bold">Student Type</p> </Grid.Column >
                                                    <Grid.Column width='11'>
                                                        {state.data.studentType ? state.data.studentType.name : '-'}
                                                    </Grid.Column>
                                                </Grid.Row>*/}
                        {/* <Grid.Row style={{ paddingTop: 0 }}>
                                                    <Grid.Column width='5'> <p className="bold">Company</p> </Grid.Column >
                                                    <Grid.Column width='11'>
                                                        {state.data.company ? state.data.company.name : '-'}
                                                    </Grid.Column>
                                                </Grid.Row> */}
                        {/* <Grid.Row style={{ paddingTop: 0 }}>
                                                    <Grid.Column width='5'> <p className="bold">Discipline Score</p> </Grid.Column >
                                                    <Grid.Column width='11'>
                                                        {state.data.disciplineScore ? state.data.disciplineScore + "/100" : '-'}
                                                    </Grid.Column>
                                                </Grid.Row> */}
                        {/* <Grid.Row style={{ paddingTop: 0 }}>
                                                    <Grid.Column width='5'> <p className="bold">Categories</p> </Grid.Column >
                                                    <Grid.Column width='11'>
                                                        {
                                                            state.data.categories.length > 0 ?
                                                            state.data.categories.map(item =>
                                                                <span key={item.name}> {item.name} <br /> </span>
                                                            ) : '-'
                                                        }
                                                    </Grid.Column>
                                                </Grid.Row> */}
                      </Grid>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Grid>
                        {/* <Grid.Row>
                                                    <Grid.Column width='5'> <p className="bold">Advisor</p> </Grid.Column >
                                                    <Grid.Column width='11'>
                                                        {state.data.soloStudent ? state.data.soloStudent.firstName + " " + state.data.soloStudent.lastName : '-'}
                                                    </Grid.Column>
                                                </Grid.Row> */}
                        <Grid.Row>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">License Number</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.licencenumber
                              ? state.data.licencenumber
                              : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Issue Date</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.issueddate
                              ? moment(state.data.issueddate).format(
                                  "dddd, MMMM DD, YYYY"
                                )
                              : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        {/* <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Discipline Score</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.disciplineScore
                              ? state.data.disciplineScore + "/100"
                              : "-"}
                          </Grid.Column>
                        </Grid.Row> */}
                        {/* <Grid.Row style={{ paddingTop: 0 }}>
                                                    <Grid.Column width='5'> <p className="bold">Hospital</p> </Grid.Column >
                                                    <Grid.Column width='11'>
                                                        {state.data.hospital ? state.data.hospital.name : '-'}
                                                    </Grid.Column>
                                                </Grid.Row> */}
                        {/* <Grid.Row style={{ paddingTop: 0 }}>
                                                    <Grid.Column width='5'> <p className="bold">Medical class</p> </Grid.Column >
                                                    <Grid.Column width='11'>
                                                        {state.data.medicalClass ? state.data.medicalClass : '-'}
                                                    </Grid.Column>
                                                </Grid.Row> */}
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Date of Birth</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.birthDate
                              ? moment(
                                  state.data.birthDate,
                                  "DD/MM/YYYY"
                                ).format("DD MMMM YYYY")
                              : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Gender</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.gender ? state.data.gender : "-"}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Status</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.status ? state.data.status : "-"}
                            {/*state.data.isActive == true || state.data.isActive == "true" ? (
                                                            <p>Active</p>
                                                        ) : (
                                                                <p>Inactive</p> || "-"
                                                            )*/}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Last Flight</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.lastFlight
                              ? moment(state.data.lastFlight).format(
                                  "DD MMMM YYYY HH:mm"
                                )
                              : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Last Login</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {
                              state.data.lastLogin ? state.data.lastLogin : "-"
                              // moment(state.data.lastLogin).format("DD MMMM YYYY HH:mm") : '-'
                            }
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Created</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {
                              state.data.createdAt ? state.data.createdAt : "-"
                              // moment(state.data.createdAt).format("DD MMMM YYYY HH:mm") : '-'
                            }
                          </Grid.Column>
                        </Grid.Row>
                        {/* <Grid.Row style={{ paddingTop: 0 }}>
                                                    <Grid.Column width='5'> <p className="bold">Discipline Score</p> </Grid.Column >
                                                    <Grid.Column width='11'>
                                                        {state.data.disciplineScore ? state.data.disciplineScore + "/100" : '-'}
                                                    </Grid.Column>
                                                </Grid.Row> */}
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ paddingTop: 0 }}>
              <Grid.Column>
                <MainPeople
                  tabs={[
                    // {
                    //     menuItem: 'Profile', render: () => (
                    //         <Profile page='student' data={state.data}/>
                    //     )
                    // },
                    {
                      menuItem: "Notes",
                      render: () => (
                        <Notes
                          page="student"
                          personnelId={state.currentId}
                          getNote={this.getNotes}
                          listNote={state.note}
                        />
                      ),
                    },
                    {
                      menuItem: "Document",
                      render: () => (
                        <Documents
                          info={state.data}
                          spl={state.dataSpl}
                          med={state.dataMed}
                        />
                      ),
                    },
                    {
                      menuItem: "Schedule",
                      render: () => (
                        <ScheduleById id={state.data.id} disableClick />
                      ),
                    },
                    // {
                    //     menuItem: 'Logbook', render: () => (
                    //         <Wrap>
                    //             <Hours page='student'
                    //                 reservation={state.reservation}
                    //                 logbook={state.logbook} />
                    //         </Wrap>
                    //     )
                    // },
                    {
                      menuItem: "Portfolio",
                      render: () => (
                        <PortfolioProfile
                          manage={this.checkPermissionMenu("Personnel", [
                            "manage-student",
                          ])}
                          studentData={state.data}
                          attendance={state.attendance}
                          snag={state.snag}
                          snagPassed={state.snagPassed}
                          course={state.dataCourse}
                          personnelId={state.currentId}
                          exportAttendance={this.onClickExportAttendance}
                        />
                      ),
                    },
                    {
                      menuItem: "Info",
                      render: () => (
                        <Grid>
                          <Grid.Row>
                            <Grid.Column>
                              <Header
                                as="h2"
                                style={{ color: config.colorTheme }}
                              >
                                Personnel
                              </Header>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row columns="2">
                            <Grid.Column>
                              <Grid>
                                <Grid.Row>
                                  <Grid.Column width="5">
                                    {" "}
                                    <p className="bold">
                                      ID (thai ID/passport)
                                    </p>{" "}
                                  </Grid.Column>
                                  <Grid.Column width="11">
                                    {state.data.idcard
                                      ? state.data.idcard
                                      : "-"}
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{ paddingTop: 0 }}>
                                  <Grid.Column width="5">
                                    {" "}
                                    <p className="bold">Address</p>{" "}
                                  </Grid.Column>
                                  <Grid.Column width="11">
                                    {state.data.address
                                      ? state.data.address
                                      : "-"}
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{ paddingTop: 0 }}>
                                  <Grid.Column width="5">
                                    {" "}
                                    <p className="bold">Degree</p>{" "}
                                  </Grid.Column>
                                  <Grid.Column width="11">
                                    {state.data.degree
                                      ? state.data.degree
                                      : "-"}
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{ paddingTop: 0 }}>
                                  <Grid.Column width="5">
                                    {" "}
                                    <p className="bold">Institute</p>{" "}
                                  </Grid.Column>
                                  <Grid.Column width="11">
                                    {state.data.institute
                                      ? state.data.institute
                                      : "-"}
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{ paddingTop: 0 }}>
                                  <Grid.Column width="5">
                                    {" "}
                                    <p className="bold">Height (cm)</p>{" "}
                                  </Grid.Column>
                                  <Grid.Column width="11">
                                    {state.data.height
                                      ? state.data.height
                                      : "-"}
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{ paddingTop: 0 }}>
                                  <Grid.Column width="5">
                                    {" "}
                                    <p className="bold">Weight (kg)</p>{" "}
                                  </Grid.Column>
                                  <Grid.Column width="11">
                                    {state.data.weight
                                      ? state.data.weight
                                      : "-"}
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{ paddingTop: 0 }}>
                                  <Grid.Column width="5">
                                    {" "}
                                    <p className="bold">
                                      Identifying Features
                                    </p>{" "}
                                  </Grid.Column>
                                  <Grid.Column width="11">
                                    {state.data.identifyFeature
                                      ? state.data.identifyFeature
                                      : "-"}
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>
                            </Grid.Column>
                            <Grid.Column>
                              <Grid>
                                <Grid.Row>
                                  <Grid.Column width="5">
                                    {" "}
                                    <p className="bold">Religion</p>{" "}
                                  </Grid.Column>
                                  <Grid.Column width="11">
                                    {state.data.religion
                                      ? state.data.religion
                                      : "-"}
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{ paddingTop: 0 }}>
                                  <Grid.Column width="5">
                                    {" "}
                                    <p className="bold">
                                      Relationship Status
                                    </p>{" "}
                                  </Grid.Column>
                                  <Grid.Column width="11">
                                    {state.data.relationship
                                      ? state.data.relationship
                                      : "-"}
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{ paddingTop: 0 }}>
                                  <Grid.Column width="5">
                                    {" "}
                                    <p className="bold">
                                      Emergency Contact
                                    </p>{" "}
                                  </Grid.Column>
                                  <Grid.Column width="11">
                                    {state.data.emergencyName
                                      ? state.data.emergencyName
                                      : "-"}
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{ paddingTop: 0 }}>
                                  <Grid.Column width="5">
                                    {" "}
                                    <p className="bold">
                                      Emergency Contact Address
                                    </p>{" "}
                                  </Grid.Column>
                                  <Grid.Column width="11">
                                    {state.data.emergencyAddress
                                      ? state.data.emergencyAddress
                                      : "-"}
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{ paddingTop: 0 }}>
                                  <Grid.Column width="5">
                                    {" "}
                                    <p className="bold">
                                      Emergency Contact Number
                                    </p>{" "}
                                  </Grid.Column>
                                  <Grid.Column width="11">
                                    {state.data.emergencyPhone
                                      ? state.data.emergencyPhone
                                      : "-"}
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column>
                              <Header
                                as="h2"
                                style={{ color: config.colorTheme }}
                              >
                                Family
                              </Header>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row columns="2">
                            <Grid.Column>
                              <Grid>
                                <Grid.Row style={{ paddingTop: 0 }}>
                                  <Grid.Column width="5">
                                    {" "}
                                    <p className="bold">Children</p>{" "}
                                  </Grid.Column>
                                  <Grid.Column width="11">
                                    {state.data.children
                                      ? state.data.children
                                      : "-"}
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{ paddingTop: 0 }}>
                                  <Grid.Column width="5">
                                    {" "}
                                    <p className="bold">Mother's Name</p>{" "}
                                  </Grid.Column>
                                  <Grid.Column width="11">
                                    {state.data.motherFirstName &&
                                    state.data.motherLastName
                                      ? state.data.motherFirstName +
                                        " " +
                                        state.data.motherLastName
                                      : "-"}
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{ paddingTop: 0 }}>
                                  <Grid.Column width="5">
                                    {" "}
                                    <p className="bold">
                                      Mother's Contact Number
                                    </p>{" "}
                                  </Grid.Column>
                                  <Grid.Column width="11">
                                    {state.data.motherPhone
                                      ? state.data.motherPhone
                                      : "-"}
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{ paddingTop: 0 }}>
                                  <Grid.Column width="5">
                                    {" "}
                                    <p className="bold">Mother's Age</p>{" "}
                                  </Grid.Column>
                                  <Grid.Column width="11">
                                    {state.data.motherAge
                                      ? state.data.motherAge
                                      : "-"}
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{ paddingTop: 0 }}>
                                  <Grid.Column width="5">
                                    {" "}
                                    <p className="bold">
                                      Mother's Occupation
                                    </p>{" "}
                                  </Grid.Column>
                                  <Grid.Column width="11">
                                    {state.data.motherOccupation
                                      ? state.data.motherOccupation
                                      : "-"}
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{ paddingTop: 0 }}>
                                  <Grid.Column width="5">
                                    <p className="bold">Father's Name</p>
                                  </Grid.Column>
                                  <Grid.Column width="11">
                                    {state.data.fatherFirstName &&
                                    state.data.fatherLastName
                                      ? state.data.fatherFirstName +
                                        " " +
                                        state.data.fatherLastName
                                      : "-"}
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{ paddingTop: 0 }}>
                                  <Grid.Column width="5">
                                    {" "}
                                    <p className="bold">
                                      Father's Contact Number
                                    </p>{" "}
                                  </Grid.Column>
                                  <Grid.Column width="11">
                                    {state.data.fatherPhone
                                      ? state.data.fatherPhone
                                      : "-"}
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{ paddingTop: 0 }}>
                                  <Grid.Column width="5">
                                    {" "}
                                    <p className="bold">Father's Age</p>{" "}
                                  </Grid.Column>
                                  <Grid.Column width="11">
                                    {state.data.fatherAge
                                      ? state.data.fatherAge
                                      : "-"}
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{ paddingTop: 0 }}>
                                  <Grid.Column width="5">
                                    {" "}
                                    <p className="bold">
                                      Father's Occupation
                                    </p>{" "}
                                  </Grid.Column>
                                  <Grid.Column width="11">
                                    {state.data.fatherOccupation
                                      ? state.data.fatherOccupation
                                      : "-"}
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>
                            </Grid.Column>
                            <Grid.Column>
                              <Grid>
                                <Grid.Row>
                                  <Grid.Column width="5">
                                    {" "}
                                    <p className="bold">Spouse's Name</p>{" "}
                                  </Grid.Column>
                                  <Grid.Column width="11">
                                    {state.data.spouseFirstName &&
                                    state.data.spouseLastName
                                      ? state.data.spouseFirstName +
                                        " " +
                                        state.data.spouseLastName
                                      : "-"}
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{ paddingTop: 0 }}>
                                  <Grid.Column width="5">
                                    {" "}
                                    <p className="bold">
                                      Spouse's Contact Number
                                    </p>{" "}
                                  </Grid.Column>
                                  <Grid.Column width="11">
                                    {state.data.spousePhone
                                      ? state.data.spousePhone
                                      : "-"}
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{ paddingTop: 0 }}>
                                  <Grid.Column width="5">
                                    {" "}
                                    <p className="bold">Spouse's Age</p>{" "}
                                  </Grid.Column>
                                  <Grid.Column width="11">
                                    {state.data.spouseAge
                                      ? state.data.spouseAge
                                      : "-"}
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{ paddingTop: 0 }}>
                                  <Grid.Column width="5">
                                    {" "}
                                    <p className="bold">
                                      Spouse's Occupation
                                    </p>{" "}
                                  </Grid.Column>
                                  <Grid.Column width="11">
                                    {state.data.spouseOccupation
                                      ? state.data.spouseOccupation
                                      : "-"}
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>
                            </Grid.Column>
                          </Grid.Row>

                          {state.data.status === "Graduated" && (
                            <Grid.Row>
                              <Grid.Column>
                                <Header
                                  as="h2"
                                  style={{ color: config.colorTheme }}
                                >
                                  Graduated
                                </Header>
                              </Grid.Column>
                            </Grid.Row>
                          )}
                          {state.data.status === "Graduated" && (
                            <Grid.Row columns="2">
                              <Grid.Column>
                                <Grid>
                                  <Grid.Row style={{ paddingTop: 0 }}>
                                    <Grid.Column width="5">
                                      {" "}
                                      <p className="bold">
                                        License Number
                                      </p>{" "}
                                    </Grid.Column>
                                    <Grid.Column width="11">
                                      {state.data.licencenumber
                                        ? state.data.licencenumber
                                        : "-"}
                                    </Grid.Column>
                                  </Grid.Row>
                                  <Grid.Row style={{ paddingTop: 0 }}>
                                    <Grid.Column width="5">
                                      {" "}
                                      <p className="bold">Issue Date</p>{" "}
                                    </Grid.Column>
                                    <Grid.Column width="11">
                                      {state.data.issueddate
                                        ? state.data.issueddate
                                        : "-"}
                                    </Grid.Column>
                                  </Grid.Row>
                                  <Grid.Row style={{ paddingTop: 0 }}>
                                    <Grid.Column width="5">
                                      {" "}
                                      <p className="bold">New Company</p>{" "}
                                    </Grid.Column>
                                    <Grid.Column width="11">
                                      {state.data.new_company
                                        ? state.data.new_company
                                        : "-"}
                                    </Grid.Column>
                                  </Grid.Row>
                                  <Grid.Row style={{ paddingTop: 0 }}>
                                    <Grid.Column width="5">
                                      {" "}
                                      <p className="bold">Last Company</p>{" "}
                                    </Grid.Column>
                                    <Grid.Column width="11">
                                      {state.data.last_company
                                        ? state.data.last_company
                                        : "-"}
                                    </Grid.Column>
                                  </Grid.Row>
                                  <Grid.Row style={{ paddingTop: 0 }}>
                                    <Grid.Column width="5">
                                      {" "}
                                      <p className="bold">Position</p>{" "}
                                    </Grid.Column>
                                    <Grid.Column width="11">
                                      {state.data.job_position
                                        ? state.data.job_position
                                        : "-"}
                                    </Grid.Column>
                                  </Grid.Row>
                                  <Grid.Row style={{ paddingTop: 0 }}>
                                    <Grid.Column width="5">
                                      {" "}
                                      <p className="bold">Job</p>{" "}
                                    </Grid.Column>
                                    <Grid.Column width="11">
                                      {state.data.job_name
                                        ? state.data.job_name
                                        : "-"}
                                    </Grid.Column>
                                  </Grid.Row>
                                  <Grid.Row style={{ paddingTop: 0 }}>
                                    <Grid.Column width="5">
                                      {" "}
                                      <p className="bold">Year employed</p>{" "}
                                    </Grid.Column>
                                    <Grid.Column width="11">
                                      {state.data.year_employed
                                        ? state.data.year_employed
                                        : "-"}
                                    </Grid.Column>
                                  </Grid.Row>
                                </Grid>
                              </Grid.Column>
                              <Grid.Column></Grid.Column>
                            </Grid.Row>
                          )}
                        </Grid>
                      ),
                    },
                  ]}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </div>
    );
  }
}

// export default withRouter(ProfileAircraft)

export default ProfileStudent = reduxForm({
  // a unique name for the form
  form: "studentprofile",
  validate,
  enableReinitialize: true,
})(ProfileStudent);
