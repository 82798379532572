import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button, Label } from "semantic-ui-react";
import * as config from "../../config";
import HeaderWithMenu from "../../components/headerwithmenu";
import MainButton from "../../components/mainbutton";
import moment from "moment";
import Wrap from "../../components/wrap";
import { getOverallProgress, getModuleProcess } from "./service";
import { getFTRecordBystudent } from "../TrainingRecord/service";
import TableList from "../../components/table";
import ModalForm from "../../components/modal";
import Score from "../TrainingRecord/Student/score";
import ReactToPrint from "react-to-print";

class FlightTrainingRecord extends Component {
  state = {
    id: this.props && this.props.id,
    data: [],
    dataState: null,
    open: false,
    loading: true,
    column: [
      {
        Header: "Title",
        accessor: "title",
        // style: { textAlign: "center" },
        sortable: false,
        Cell: (cellInfo) =>
          cellInfo.original.subject &&
          cellInfo.original.subject.checkSubject &&
          cellInfo.original.status === "APPROVED" ? (
            <Wrap>
              <span>Aircraft: {cellInfo.original.aircraftName}</span>
              <br />
              <p>
                Subject:{" "}
                {cellInfo.original.subject &&
                  `${cellInfo.original.subject.code} (${cellInfo.original.subject.name})`}{" "}
                -
                {cellInfo.original.subject &&
                  cellInfo.original.subject.checkSubject &&
                  `${
                    cellInfo.original.subject.checkSubject === "bacCheck"
                      ? " BAC CHECK"
                      : " CAAT CHECK"
                  } (${cellInfo.original.subject.checkSubjectGroup})`}
              </p>
            </Wrap>
          ) : (
            // <p>{cellInfo.original.title}</p>
            <Wrap>
              <span
                className="bold"
                style={{ cursor: "pointer" }}
                onClick={() => this.onClickCheckScore(cellInfo.original)}
              >
                Aircraft: {cellInfo.original.aircraftName}
              </span>
              <br />
              <p>
                Subject:{" "}
                {cellInfo.original.subject &&
                  `${cellInfo.original.subject.code} (${cellInfo.original.subject.name})`}
              </p>
            </Wrap>
            // <p>{cellInfo.original.title}</p>
          ),
      },
      {
        Header: "Instructor",
        accessor: "instructorName",
        width: 200,
        sortable: false,
        Cell: (cellInfo) => (
          <Wrap>
            {cellInfo.original.instructorName && (
              <span>{cellInfo.original.instructorName}</span>
            )}
          </Wrap>
        ),
      },
      // {
      //   Header: "Aircraft",
      //   accessor: "aircraftName",
      //   width: 200,
      //   sortable: false
      // },
      {
        Header: "Time",
        accessor: "start",
        style: { textAlign: "center" },
        sortable: false,
        width: 250,
        Cell: (cellInfo) => (
          <p>
            {moment(cellInfo.original.start).format("MMMM Do YYYY") ===
            moment(cellInfo.original.end).format("MMMM Do YYYY")
              ? moment(cellInfo.original.start).format(
                  "ddd, MMMM Do YYYY HH:mm"
                ) +
                " - " +
                moment(cellInfo.original.end).format("HH:mm")
              : moment(cellInfo.original.start).format("MMMM Do YYYY, HH:mm") +
                " - " +
                moment(cellInfo.original.end).format("MMMM Do YYYY, HH:mm")}
          </p>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        sortable: false,
        style: { textAlign: "center" },
        width: 150,
        Cell: (cellInfo) => (
          <span>
            {cellInfo.original.status === "SUMMITED" ? (
              <Label
                size="small"
                className="statusLabel"
                style={{
                  backgroundColor: "#FF9800",
                  color: "#FFFFFF",
                  border: "1px solid #FF9800",
                  borderRadius: "14px",
                }}
              >
                SUBMITTED
              </Label>
            ) : cellInfo.original.status === "APPROVED" ? (
              <Label
                size="small"
                className="statusLabel"
                style={{
                  backgroundColor: "#56B9F4",
                  color: "#FFFFFF",
                  border: "1px solid #56B9F4",
                  borderRadius: "14px",
                }}
              >
                APPROVED
              </Label>
            ) : cellInfo.original.status === "REPEAT" ? (
                <Label
                    size="small"
                    className="statusLabel"
                    style={{
                      backgroundColor: "#dc8def",
                      color: "#FFFFFF",
                      border: "1px solid #dc8def",
                      borderRadius: "14px",
                    }}
                >
                  REPEAT
                </Label>
            ):null}
            <br />
            {cellInfo.original.subject &&
            cellInfo.original.subject.checkSubject &&
            cellInfo.original.status === "APPROVED" &&
            cellInfo.original.passed === "true" ? (
              <span>
                <Label
                  size="small"
                  className="statusLabel"
                  style={{
                    backgroundColor: "#33CC66",
                    color: "#FFFFFF",
                    border: "1px solid #33CC66",
                    borderRadius: "14px",
                    margin: "4px",
                  }}
                >
                  PASS
                </Label>
              </span>
            ) : (
              cellInfo.original.subject &&
              cellInfo.original.subject.checkSubject &&
              cellInfo.original.status === "APPROVED" &&
              cellInfo.original.passed === "false" && (
                <span>
                  <Label
                    size="small"
                    className="statusLabel"
                    style={{
                      backgroundColor: "#ec2d4c",
                      color: "#FFFFFF",
                      border: "1px solid #ec2d4c",
                      borderRadius: "14px",
                      margin: "4px",
                    }}
                  >
                    NOT PASS
                  </Label>
                </span>
              )
            )}
          </span>
        ),
      },
    ],
  };
  onClickCheckScore = (data) => {
    console.log(data);
    // this.setState({dataState: data},
    // ()=>{
    //   this.setState({open: true})
    // })

    this.setState({ dataState: data });
  };
  getFlightTraningRecordAll = (qString) => {
    this.setState({ loading: true });
    getFTRecordBystudent(qString).then((res) => {
      console.log("getFlightTraningRecord in port folio", res);
      if (res && res.status === 200) {
        this.setState({ data: res.data });
      }
      this.setState({ loading: false });
    });
  };
  componentDidMount() {
    let qString = "?student=" + this.props.id;
    this.getFlightTraningRecordAll(qString);
  }

  render() {
    const state = this.state;

    return (
      <div>
        {state.dataState ? (
          <div>
            <Button
              size="mini"
              basic
              onClick={() => this.setState({ dataState: null })}
            >
              Back
            </Button>

            <ReactToPrint
              trigger={() => {
                // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                // to the root node of the returned component as it will be overwritten.
                return (
                  <Button
                    floated="right"
                    icon="print"
                    compact
                    content="Print"
                  />
                );
              }}
              content={() => this.componentRef}
            />
            <div
              ref={(el) => (this.componentRef = el)}
              style={{ textAlign: "center", margin: "20px" }}
            >
              <Score portfolio={true} state={state.dataState} />
            </div>
          </div>
        ) : (
          <TableList
            loading={state.loading}
            data={state.data}
            columns={state.column}
          />
        )}
        {/* <TableList
          loading={state.loading}
          data={state.data}
          columns={state.column}
        />
        <ModalForm
          open={state.open}
          // icon="book"
          // content="Flight Training Record"
          size='large'
          close={() => this.setState({open: false})}
        >
          <Score portfolio={true} state={state.dataState}/>
        </ModalForm> */}
      </div>
    );
  }
}

export default withRouter(FlightTrainingRecord);
