// export const urlAPI = "http://127.0.0.1:8080/api/v1/web";
// export const urlAPI = "http://10.50.51.184:8080/api/v1/web";
// export const urlAPI = "http://172.20.10.4:8080/api/v1/web";
// const prod = "http://35.240.175.255:8080/api/v1/web";
const prod = "https://bacpro.ba.ac.th:8080/api/v1/web";
const dev = "http://35.247.176.96:8080/api/v1/web";
const devssl = "https://dev-bac.loxleyorbit.com:8080/api/v1/web"
const local = "http://127.0.0.1:8000/api/v1/web"

export const urlAPI = prod;

// {process.env.NODE_ENV === 'development' ?
//                         "http://35.247.176.96:8080/api/v1/web"
//                       : process.env.NODE_ENV === 'production' ?
//                         "http://35.240.175.255:8080/api/v1/web"
//                       };
// export const urlAPI = "https:///api-bac.firebaseapp.com/api";
// export const urlAPI = "https://api-json-bac.firebaseapp.com/api";
// 13: 50 NEUNG superadmin
// 13: 50 NEUNG OrbitOnly1
// p.aaaa@stu.bac.ac.th  14051994 BAC57

export const headerCallAPI = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("bac_access_token"),
    Accept: "application/json",
    // "Content-Type": "multipart/form-data"
  },
};
// export const headerFileCallAPI = {
//   headers: {
//     Authorization: "Bearer " + localStorage.getItem("bac_access_token"),
//     Accept: "application/json"
//     // "Content-Type": "multipart/form-data"
//   }
// };

export const colorTheme = "#e07916";

export const options_status_reservation = [
  { key: "r", text: "Reserved", value: 0 },
  { key: "u", text: "Unsuccess", value: 1 },
  { key: "c", text: "Complete", value: 2 },
  { key: "ca", text: "Cancelled", value: 3 },
  { key: "d", text: "Deleted", value: "Deleted" },
];

export const options_relationship = [
  { key: "b", text: "Single", value: "Single" },
  { key: "c", text: "Married", value: "Married" },
  { key: "i", text: "Divorced", value: "Divorced" },
];
export const options_religion = [
  { key: "b", text: "Buddhism", value: "Buddhism" },
  { key: "c", text: "Christian", value: "Christian" },
  { key: "i", text: "Islam", value: "Islam" },
  { key: "h", text: "Hindu", value: "Hindu" },
  { key: "n", text: "None", value: "None" },
];
export const options_degree = [
  { key: "s", text: "Senior High School", value: "Senior High School" },
  { key: "b", text: "Bachelor Degree", value: "Bachelor Degree" },
  { key: "m", text: "Master Degree", value: "Master Degre" },
  { key: "d", text: "Doctor Degree", value: "Doctor Degree" },
];
export const options_regisstatus = [
  { key: "r", text: "Registered", value: "Registered" },
  { key: "u", text: "Unregistered", value: "Unregistered" },
  // ,
  // { key: "g", text: "Graduated", value: "Graduated" }
];
export const options_studentstatus_report = [
  { key: "r", text: "Registered", value: "Registered" },
  { key: "g", text: "Graduated", value: "Graduated" },
  { key: "d", text: "Drop", value: "Drop" },
  { key: "l", text: "Leave", value: "Leave" },
  { key: "t", text: "Terminated", value: "Terminated" },
];
export const options_studentstatus = [
  { key: "a", text: "Active", value: "Active" },
  { key: "i", text: "Inactive", value: "Inactive" },
  { key: "g", text: "Graduated", value: "Graduated" },
  { key: "d", text: "Drop", value: "Drop" },
  { key: "l", text: "Leave", value: "Leave" },
  { key: "t", text: "Terminated", value: "Terminated" },
];

export const options_studentstatus_profile = [
  { key: "a", text: "Active", value: "Active" },
  { key: "i", text: "Inactive", value: "Inactive" },
  // { key: "g", text: "Graduated", value: "Graduated" },
  { key: "d", text: "Drop", value: "Drop" },
  { key: "l", text: "Leave", value: "Leave" },
  // { key: "t", text: "Terminated", value: "Terminated" }
];

export const options_active = [
  { key: "a", text: "Active", value: "Active" },
  { key: "i", text: "Inactive", value: "Inactive" },
];
export const options_checktype = [
  { key: "a", text: "Flight Checker", value: "Flight Checker" },
  { key: "b", text: "SIM Checker", value: "SIM Checker" },
  { key: "c", text: "Ground Checker", value: "Ground Checker" },
  { key: "d", text: "DCP Checker", value: "DCP Checker" },
];
export const options_englevel = [
  { key: "a", text: "4", value: "4" },
  { key: "b", text: "5", value: "5" },
  { key: "c", text: "6", value: "6" },
  { key: "d", text: "N/A", value: "N/A" },
];

export const options_statusremind = [
  { key: "1", text: "All Status", value: 1 },
  { key: "2", text: "Warning & Expired", value: 4 },
  { key: "3", text: "Warning", value: 2 },
  { key: "4", text: "Expired", value: 3 },
];

export const options_aircaft = [
  {
    key: "1",
    text: "HS-BAB [CA160] Cessna 172M",
    value: "265becc6-7c2c-465c-a684-59e0eaf64db7",
  },
  {
    key: "2",
    text: "HS-BAC [CA160] Cessna 172P",
    value: "67a2063b-238a-4326-98ec-431fbe0c22cd",
  },
];

export const options_remindtype = [
  { key: "1", text: "Date Based", value: "1" },
  { key: "2", text: "Time Based", value: "2" },
];

export const options_reservation = [
  { key: "1", text: "Today Only", value: "1" },
  { key: "2", text: "Today & Future", value: "2" },
  { key: "3", text: "All Past", value: "3" },
];
export const options_title_eng = [
  { key: "1", value: "Mr.", text: "Mr." },
  { key: "2", value: "Mrs.", text: "Mrs." },
  { key: "3", value: "Miss", text: "Miss" },
  { key: "4", value: "Wg Cdr", text: "Wg Cdr" },
  { key: "5", value: "Gp. Capt.", text: "Gp. Capt." },
  { key: "6", value: "Sqn Ldr", text: "Sqn Ldr" },
  { key: "7", value: "Maj", text: "Maj" },
  { key: "8", value: "ACM", text: "ACM" },
  { key: "9", value: "AVM", text: "AVM" },
  { key: "10", value: "Pol Lt Col.", text: "Pol Lt Col." },
  { key: "11", value: "Flt Lt", text: "Flt Lt" },
  { key: "12", value: "Gp Capt", text: "Gp Capt" },
  { key: "13", value: "Pol. Maj. Gen.", text: "Pol. Maj. Gen." },
  { key: "14", value: "Flt Off.", text: "Flt Off." },
  { key: "15", value: "Lt Col.", text: "Lt Col." },
  { key: "16", value: "Sqn Ldr.", text: "Sqn Ldr." },
  { key: "17", value: "Dr.", text: "Dr." },
];
export const options_title_th = [
  { key: "1", value: "นาย", text: "นาย" },
  { key: "2", value: "นาง", text: "นาง" },
  { key: "3", value: "นางสาว", text: "นางสาว" },
  { key: "4", value: "นาวาอากาศโท", text: "นาวาอากาศโท" },
  { key: "5", value: "นาวาอากาศเอก", text: "นาวาอากาศเอก" },
  { key: "6", value: "นาวาอากาศตรี", text: "นาวาอากาศตรี" },
  { key: "7", value: "เรืออากาศตรี", text: "เรืออากาศตรี" },
  { key: "8", value: "พันตรี", text: "พันตรี" },
  { key: "9", value: "พลอากาศเอก", text: "พลอากาศเอก" },
  { key: "10", value: "พลอากาศตรี", text: "พลอากาศตรี" },
  { key: "11", value: "พันตำรวจโท", text: "พันตำรวจโท" },
  { key: "12", value: "เรืออากาศเอก", text: "เรืออากาศเอก" },
  { key: "13", value: "พลตำรวจตรี", text: "พลตำรวจตรี" },
  { key: "14", value: "เรืออากาศโท", text: "เรืออากาศโท" },
  { key: "15", value: "พันโท", text: "พันโท" },
  { key: "16", value: "เรืออากาศเอกหม่อมหลวง", text: "เรืออากาศเอกหม่อมหลวง" },
  { key: "17", value: "พลอากาศตรี ดร.", text: "พลอากาศตรี ดร." },
  { key: "18", value: "ดอกเตอร์", text: "ดอกเตอร์" },
];

export const optionsSchedule = [
  {
    key: "Timeline",
    text: "Timeline",
    value: "agenda",
    content: "Timeline",
  },
  // {
  //   key: "Day Vertical",
  //   text: "Day Vertical",
  //   value: "day",
  //   content: "Day Vertical"
  // },
  {
    key: "Day Horizontal",
    text: "Day Horizontal",
    value: "Day Horizontal",
    content: "Day Horizontal",
  },
  {
    key: "2 Day Horizontal",
    text: "2 Day Horizontal",
    value: "2 Day Horizontal",
    content: "2 Day Horizontal",
  },
  {
    key: "3 Day Horizontal",
    text: "3 Day Horizontal",
    value: "3 Day Horizontal",
    content: "3 Day Horizontal",
  },
  // {
  //     key: '7 Day Horizontal',
  //     text: '7 Day Horizontal',
  //     value: '7 Day Horizontal',
  //     content: '7 Day Horizontal',
  // },
  {
    key: "week",
    text: "Week",
    value: "week",
    content: "Week",
  },
  {
    key: "month",
    text: "Month",
    value: "month",
    content: "Month",
  },
];

export const room_type = [
  { id: 0, text: "All", value: 0 },
  { id: 2, text: "Class Room", value: 2 },
  { id: 1, text: "Meeting Room", value: 1 },
]; // รอลบ

export const time = [
  { key: "1", text: "00:00", value: 0 },
  { key: "2", text: "01:00", value: 1 },
  { key: "3", text: "02:00", value: 2 },
  { key: "4", text: "03:00", value: 3 },
  { key: "5", text: "04:00", value: 4 },
  { key: "6", text: "05:00", value: 5 },
  { key: "7", text: "06:00", value: 6 },
  { key: "8", text: "07:00", value: 7 },
  { key: "9", text: "08:00", value: 8 },
  { key: "10", text: "09:00", value: 9 },
  { key: "11", text: "10:00", value: 10 },
  { key: "12", text: "11:00", value: 11 },
  { key: "13", text: "12:00", value: 12 },
  { key: "14", text: "13:00", value: 13 },
  { key: "15", text: "14:00", value: 14 },
  { key: "16", text: "15:00", value: 15 },
  { key: "17", text: "16:00", value: 16 },
  { key: "18", text: "17:00", value: 17 },
  { key: "19", text: "18:00", value: 18 },
  { key: "20", text: "19:00", value: 19 },
  { key: "21", text: "20:00", value: 20 },
  { key: "22", text: "21:00", value: 21 },
  { key: "23", text: "22:00", value: 22 },
  { key: "24", text: "23:00", value: 23 },
];

export const permission = [
  {
    group: "Personnel",
    permission: [
      {
        label: "Manage Instructor",
        name: "manage-instructor",
      },
      {
        label: "View Instructor",
        name: "view-instructor",
      },
      {
        label: "Manage Student",
        name: "manage-student",
      },
      {
        label: "View Student",
        name: "view-student",
      },
      {
        label: "Manage Staff",
        name: "manage-staff",
      },
      {
        label: "View Staff",
        name: "view-staff",
      },
      {
        label: "View Internal Note",
        name: "view-internalnote",
      },
    ],
  },
  {
    group: "Academic",
    permission: [
      // {
      //   label: "Manage E-Book",
      //   name: "manage-ebook"
      // },
      {
        label: "View E-Book",
        name: "view-ebook",
      },
      // {
      //   label: "Manage Portfolio",
      //   name: "manage-portfolio"
      // },
      {
        label: "View Portfolio",
        name: "view-portfolio",
      },
      {
        label: "Manage Flight Training Record (Student)",
        name: "manage-flighttraining-student",
      },
      {
        label: "Manage Flight Training Record (Instructor)",
        name: "manage-flighttraining-instructor",
      },
      {
        label: "Manage Flight Training Record (Admin)",
        name: "manage-flighttraining-admin",
      },
      {
        label: "Manage Registration",
        name: "manage-registration",
      },
      {
        label: "Manage Ground Attendance (Admin)",
        name: "manage-groundtraining-admin",
      },
      {
        label: "Manage Ground Attendance (Instructor)",
        name: "manage-groundtraining-instructor",
      },

      // {
      //   label: "Manage Discipline Score",
      //   name: "manage-disciplinescore"
      // },
      // {
      //   label: "Manage Score",
      //   name: "manage-score"
      // }
    ],
  },
  {
    group: "Schedule",
    permission: [
      {
        label: "Manage Schedule",
        name: "manage-schedule",
      },
      {
        label: "View Schedule",
        name: "view-schedule",
      },
      {
        label: "View Schedule Tracking",
        name: "view-schedule-tracking",
      },
      {
        label: "Manage Flight Table",
        name: "manage-flighttable",
      },
      {
        label: "View Flight Table",
        name: "view-flighttable",
      },
      {
        label: "Manage Flight Hour Record",
        name: "manage-flighthourrecord",
      },
      {
        label: "Save Flight Hour Record",
        name: "save-flighthourrecord",
      },
      {
        label: "Duplicate Schedule",
        name: "duplicate-schedule",
      },
    ],
  },
  {
    group: "Resource",
    permission: [
      {
        label: "Manage Academic",
        name: "manage-academic",
      },
      {
        label: "Manage Aircraft & Simulator",
        name: "manage-aircraftsimulator",
      },
      {
        label: "Manage Room",
        name: "manage-room",
      },
    ],
  },
  {
    group: "Report",
    permission: [
      {
        label: "View Report",
        name: "view-report",
      },
    ],
  },
  {
    group: "Management",
    permission: [
      {
        label: "Manage Alert & News",
        name: "manage-alertnews",
      },
      {
        label: "Manage Aircraft & Simulator",
        name: "manage-aircrafts",
      },
      {
        label: "Manage Personnel",
        name: "manage-personnel",
      },
      {
        label: "Manage Room (Room Location)",
        name: "manage-roomlocation",
      },
      {
        label: "Manage Ground Instruction Type",
        name: "manage-groundinstructiontype",
      },
      {
        label: "Manage Role & Permission",
        name: "manage-role",
      },
      {
        label: "Manage E-Book",
        name: "manage-ebook",
      },
      {
        label: "Manage Discipline Score",
        name: "manage-disciplinescore",
      },
      {
        label: "Manage Flight Time Rule",
        name: "manage-flighttimerule",
      },
      {
        label: "Manage User (Student)",
        name: "manage-user-student",
      },
      {
        label: "Manage User (Instructor / Staff)",
        name: "manage-user",
      },
    ],
  },
];

export const timeSchedule = [
  {
    key: "00:00",
    text: "00:00",
    value: "00:00",
  },
  {
    key: "00:05",
    text: "00:05",
    value: "00:05",
  },
  {
    key: "00:10",
    text: "00:10",
    value: "00:10",
  },
  {
    key: "00:15",
    text: "00:15",
    value: "00:15",
  },
  {
    key: "00:20",
    text: "00:20",
    value: "00:20",
  },
  {
    key: "00:25",
    text: "00:25",
    value: "00:25",
  },
  {
    key: "00:30",
    text: "00:30",
    value: "00:30",
  },
  {
    key: "00:35",
    text: "00:35",
    value: "00:35",
  },
  {
    key: "00:40",
    text: "00:40",
    value: "00:40",
  },
  {
    key: "00:45",
    text: "00:45",
    value: "00:45",
  },
  {
    key: "00:50",
    text: "00:50",
    value: "00:50",
  },
  {
    key: "00:55",
    text: "00:55",
    value: "00:55",
  },
  {
    key: "01:00",
    text: "01:00",
    value: "01:00",
  },
  {
    key: "01:05",
    text: "01:05",
    value: "01:05",
  },
  {
    key: "01:10",
    text: "01:10",
    value: "01:10",
  },
  {
    key: "01:15",
    text: "01:15",
    value: "01:15",
  },
  {
    key: "01:20",
    text: "01:20",
    value: "01:20",
  },
  {
    key: "01:25",
    text: "01:25",
    value: "01:25",
  },
  {
    key: "01:30",
    text: "01:30",
    value: "01:30",
  },
  {
    key: "01:35",
    text: "01:35",
    value: "01:35",
  },
  {
    key: "01:40",
    text: "01:40",
    value: "01:40",
  },
  {
    key: "01:45",
    text: "01:45",
    value: "01:45",
  },
  {
    key: "01:50",
    text: "01:50",
    value: "01:50",
  },
  {
    key: "01:55",
    text: "01:55",
    value: "01:55",
  },
  {
    key: "02:00",
    text: "02:00",
    value: "02:00",
  },
  {
    key: "02:05",
    text: "02:05",
    value: "02:05",
  },
  {
    key: "02:10",
    text: "02:10",
    value: "02:10",
  },
  {
    key: "02:15",
    text: "02:15",
    value: "02:15",
  },
  {
    key: "02:20",
    text: "02:20",
    value: "02:20",
  },
  {
    key: "02:25",
    text: "02:25",
    value: "02:25",
  },
  {
    key: "02:30",
    text: "02:30",
    value: "02:30",
  },
  {
    key: "02:35",
    text: "02:35",
    value: "02:35",
  },
  {
    key: "02:40",
    text: "02:40",
    value: "02:40",
  },
  {
    key: "02:45",
    text: "02:45",
    value: "02:45",
  },
  {
    key: "02:50",
    text: "02:50",
    value: "02:50",
  },
  {
    key: "02:55",
    text: "02:55",
    value: "02:55",
  },
  {
    key: "03:00",
    text: "03:00",
    value: "03:00",
  },
  {
    key: "03:05",
    text: "03:05",
    value: "03:05",
  },
  {
    key: "03:10",
    text: "03:10",
    value: "03:10",
  },
  {
    key: "03:15",
    text: "03:15",
    value: "03:15",
  },
  {
    key: "03:20",
    text: "03:20",
    value: "03:20",
  },
  {
    key: "03:25",
    text: "03:25",
    value: "03:25",
  },
  {
    key: "03:30",
    text: "03:30",
    value: "03:30",
  },
  {
    key: "03:35",
    text: "03:35",
    value: "03:35",
  },
  {
    key: "03:40",
    text: "03:40",
    value: "03:40",
  },
  {
    key: "03:45",
    text: "03:45",
    value: "03:45",
  },
  {
    key: "03:50",
    text: "03:50",
    value: "03:50",
  },
  {
    key: "03:55",
    text: "03:55",
    value: "03:55",
  },
  {
    key: "04:00",
    text: "04:00",
    value: "04:00",
  },
  {
    key: "04:05",
    text: "04:05",
    value: "04:05",
  },
  {
    key: "04:10",
    text: "04:10",
    value: "04:10",
  },
  {
    key: "04:15",
    text: "04:15",
    value: "04:15",
  },
  {
    key: "04:20",
    text: "04:20",
    value: "04:20",
  },
  {
    key: "04:25",
    text: "04:25",
    value: "04:25",
  },
  {
    key: "04:30",
    text: "04:30",
    value: "04:30",
  },
  {
    key: "04:35",
    text: "04:35",
    value: "04:35",
  },
  {
    key: "04:40",
    text: "04:40",
    value: "04:40",
  },
  {
    key: "04:45",
    text: "04:45",
    value: "04:45",
  },
  {
    key: "04:50",
    text: "04:50",
    value: "04:50",
  },
  {
    key: "04:55",
    text: "04:55",
    value: "04:55",
  },
  {
    key: "05:00",
    text: "05:00",
    value: "05:00",
  },
  {
    key: "05:05",
    text: "05:05",
    value: "05:05",
  },
  {
    key: "05:10",
    text: "05:10",
    value: "05:10",
  },
  {
    key: "05:15",
    text: "05:15",
    value: "05:15",
  },
  {
    key: "05:20",
    text: "05:20",
    value: "05:20",
  },
  {
    key: "05:25",
    text: "05:25",
    value: "05:25",
  },
  {
    key: "05:30",
    text: "05:30",
    value: "05:30",
  },
  {
    key: "05:35",
    text: "05:35",
    value: "05:35",
  },
  {
    key: "05:40",
    text: "05:40",
    value: "05:40",
  },
  {
    key: "05:45",
    text: "05:45",
    value: "05:45",
  },
  {
    key: "05:50",
    text: "05:50",
    value: "05:50",
  },
  {
    key: "05:55",
    text: "05:55",
    value: "05:55",
  },
  {
    key: "06:00",
    text: "06:00",
    value: "06:00",
  },
  {
    key: "06:05",
    text: "06:05",
    value: "06:05",
  },
  {
    key: "06:10",
    text: "06:10",
    value: "06:10",
  },
  {
    key: "06:15",
    text: "06:15",
    value: "06:15",
  },
  {
    key: "06:20",
    text: "06:20",
    value: "06:20",
  },
  {
    key: "06:25",
    text: "06:25",
    value: "06:25",
  },
  {
    key: "06:30",
    text: "06:30",
    value: "06:30",
  },
  {
    key: "06:35",
    text: "06:35",
    value: "06:35",
  },
  {
    key: "06:40",
    text: "06:40",
    value: "06:40",
  },
  {
    key: "06:45",
    text: "06:45",
    value: "06:45",
  },
  {
    key: "06:50",
    text: "06:50",
    value: "06:50",
  },
  {
    key: "06:55",
    text: "06:55",
    value: "06:55",
  },
  {
    key: "07:00",
    text: "07:00",
    value: "07:00",
  },
  {
    key: "07:05",
    text: "07:05",
    value: "07:05",
  },
  {
    key: "07:10",
    text: "07:10",
    value: "07:10",
  },
  {
    key: "07:15",
    text: "07:15",
    value: "07:15",
  },
  {
    key: "07:20",
    text: "07:20",
    value: "07:20",
  },
  {
    key: "07:25",
    text: "07:25",
    value: "07:25",
  },
  {
    key: "07:30",
    text: "07:30",
    value: "07:30",
  },
  {
    key: "07:35",
    text: "07:35",
    value: "07:35",
  },
  {
    key: "07:40",
    text: "07:40",
    value: "07:40",
  },
  {
    key: "07:45",
    text: "07:45",
    value: "07:45",
  },
  {
    key: "07:50",
    text: "07:50",
    value: "07:50",
  },
  {
    key: "07:55",
    text: "07:55",
    value: "07:55",
  },
  {
    key: "08:00",
    text: "08:00",
    value: "08:00",
  },
  {
    key: "08:05",
    text: "08:05",
    value: "08:05",
  },
  {
    key: "08:10",
    text: "08:10",
    value: "08:10",
  },
  {
    key: "08:15",
    text: "08:15",
    value: "08:15",
  },
  {
    key: "08:20",
    text: "08:20",
    value: "08:20",
  },
  {
    key: "08:25",
    text: "08:25",
    value: "08:25",
  },
  {
    key: "08:30",
    text: "08:30",
    value: "08:30",
  },
  {
    key: "08:35",
    text: "08:35",
    value: "08:35",
  },
  {
    key: "08:40",
    text: "08:40",
    value: "08:40",
  },
  {
    key: "08:45",
    text: "08:45",
    value: "08:45",
  },
  {
    key: "08:50",
    text: "08:50",
    value: "08:50",
  },
  {
    key: "08:55",
    text: "08:55",
    value: "08:55",
  },
  {
    key: "09:00",
    text: "09:00",
    value: "09:00",
  },
  {
    key: "09:05",
    text: "09:05",
    value: "09:05",
  },
  {
    key: "09:10",
    text: "09:10",
    value: "09:10",
  },
  {
    key: "09:15",
    text: "09:15",
    value: "09:15",
  },
  {
    key: "09:20",
    text: "09:20",
    value: "09:20",
  },
  {
    key: "09:25",
    text: "09:25",
    value: "09:25",
  },
  {
    key: "09:30",
    text: "09:30",
    value: "09:30",
  },
  {
    key: "09:35",
    text: "09:35",
    value: "09:35",
  },
  {
    key: "09:40",
    text: "09:40",
    value: "09:40",
  },
  {
    key: "09:45",
    text: "09:45",
    value: "09:45",
  },
  {
    key: "09:50",
    text: "09:50",
    value: "09:50",
  },
  {
    key: "09:55",
    text: "09:55",
    value: "09:55",
  },
  {
    key: "10:00",
    text: "10:00",
    value: "10:00",
  },
  {
    key: "10:05",
    text: "10:05",
    value: "10:05",
  },
  {
    key: "10:10",
    text: "10:10",
    value: "10:10",
  },
  {
    key: "10:15",
    text: "10:15",
    value: "10:15",
  },
  {
    key: "10:20",
    text: "10:20",
    value: "10:20",
  },
  {
    key: "10:25",
    text: "10:25",
    value: "10:25",
  },
  {
    key: "10:30",
    text: "10:30",
    value: "10:30",
  },
  {
    key: "10:35",
    text: "10:35",
    value: "10:35",
  },
  {
    key: "10:40",
    text: "10:40",
    value: "10:40",
  },
  {
    key: "10:45",
    text: "10:45",
    value: "10:45",
  },
  {
    key: "10:50",
    text: "10:50",
    value: "10:50",
  },
  {
    key: "10:55",
    text: "10:55",
    value: "10:55",
  },
  {
    key: "11:00",
    text: "11:00",
    value: "11:00",
  },
  {
    key: "11:05",
    text: "11:05",
    value: "11:05",
  },
  {
    key: "11:10",
    text: "11:10",
    value: "11:10",
  },
  {
    key: "11:15",
    text: "11:15",
    value: "11:15",
  },
  {
    key: "11:20",
    text: "11:20",
    value: "11:20",
  },
  {
    key: "11:25",
    text: "11:25",
    value: "11:25",
  },
  {
    key: "11:30",
    text: "11:30",
    value: "11:30",
  },
  {
    key: "11:35",
    text: "11:35",
    value: "11:35",
  },
  {
    key: "11:40",
    text: "11:40",
    value: "11:40",
  },
  {
    key: "11:45",
    text: "11:45",
    value: "11:45",
  },
  {
    key: "11:50",
    text: "11:50",
    value: "11:50",
  },
  {
    key: "11:55",
    text: "11:55",
    value: "11:55",
  },
  {
    key: "12:00",
    text: "12:00",
    value: "12:00",
  },
  {
    key: "12:05",
    text: "12:05",
    value: "12:05",
  },
  {
    key: "12:10",
    text: "12:10",
    value: "12:10",
  },
  {
    key: "12:15",
    text: "12:15",
    value: "12:15",
  },
  {
    key: "12:20",
    text: "12:20",
    value: "12:20",
  },
  {
    key: "12:25",
    text: "12:25",
    value: "12:25",
  },
  {
    key: "12:30",
    text: "12:30",
    value: "12:30",
  },
  {
    key: "12:35",
    text: "12:35",
    value: "12:35",
  },
  {
    key: "12:40",
    text: "12:40",
    value: "12:40",
  },
  {
    key: "12:45",
    text: "12:45",
    value: "12:45",
  },
  {
    key: "12:50",
    text: "12:50",
    value: "12:50",
  },
  {
    key: "12:55",
    text: "12:55",
    value: "12:55",
  },
  {
    key: "13:00",
    text: "13:00",
    value: "13:00",
  },
  {
    key: "13:05",
    text: "13:05",
    value: "13:05",
  },
  {
    key: "13:10",
    text: "13:10",
    value: "13:10",
  },
  {
    key: "13:15",
    text: "13:15",
    value: "13:15",
  },
  {
    key: "13:20",
    text: "13:20",
    value: "13:20",
  },
  {
    key: "13:25",
    text: "13:25",
    value: "13:25",
  },
  {
    key: "13:30",
    text: "13:30",
    value: "13:30",
  },
  {
    key: "13:35",
    text: "13:35",
    value: "13:35",
  },
  {
    key: "13:40",
    text: "13:40",
    value: "13:40",
  },
  {
    key: "13:45",
    text: "13:45",
    value: "13:45",
  },
  {
    key: "13:50",
    text: "13:50",
    value: "13:50",
  },
  {
    key: "13:55",
    text: "13:55",
    value: "13:55",
  },
  {
    key: "14:00",
    text: "14:00",
    value: "14:00",
  },
  {
    key: "14:05",
    text: "14:05",
    value: "14:05",
  },
  {
    key: "14:10",
    text: "14:10",
    value: "14:10",
  },
  {
    key: "14:15",
    text: "14:15",
    value: "14:15",
  },
  {
    key: "14:20",
    text: "14:20",
    value: "14:20",
  },
  {
    key: "14:25",
    text: "14:25",
    value: "14:25",
  },
  {
    key: "14:30",
    text: "14:30",
    value: "14:30",
  },
  {
    key: "14:35",
    text: "14:35",
    value: "14:35",
  },
  {
    key: "14:40",
    text: "14:40",
    value: "14:40",
  },
  {
    key: "14:45",
    text: "14:45",
    value: "14:45",
  },
  {
    key: "14:50",
    text: "14:50",
    value: "14:50",
  },
  {
    key: "14:55",
    text: "14:55",
    value: "14:55",
  },
  {
    key: "15:00",
    text: "15:00",
    value: "15:00",
  },
  {
    key: "15:05",
    text: "15:05",
    value: "15:05",
  },
  {
    key: "15:10",
    text: "15:10",
    value: "15:10",
  },
  {
    key: "15:15",
    text: "15:15",
    value: "15:15",
  },
  {
    key: "15:20",
    text: "15:20",
    value: "15:20",
  },
  {
    key: "15:25",
    text: "15:25",
    value: "15:25",
  },
  {
    key: "15:30",
    text: "15:30",
    value: "15:30",
  },
  {
    key: "15:35",
    text: "15:35",
    value: "15:35",
  },
  {
    key: "15:40",
    text: "15:40",
    value: "15:40",
  },
  {
    key: "15:45",
    text: "15:45",
    value: "15:45",
  },
  {
    key: "15:50",
    text: "15:50",
    value: "15:50",
  },
  {
    key: "15:55",
    text: "15:55",
    value: "15:55",
  },
  {
    key: "16:00",
    text: "16:00",
    value: "16:00",
  },
  {
    key: "16:05",
    text: "16:05",
    value: "16:05",
  },
  {
    key: "16:10",
    text: "16:10",
    value: "16:10",
  },
  {
    key: "16:15",
    text: "16:15",
    value: "16:15",
  },
  {
    key: "16:20",
    text: "16:20",
    value: "16:20",
  },
  {
    key: "16:25",
    text: "16:25",
    value: "16:25",
  },
  {
    key: "16:30",
    text: "16:30",
    value: "16:30",
  },
  {
    key: "16:35",
    text: "16:35",
    value: "16:35",
  },
  {
    key: "16:40",
    text: "16:40",
    value: "16:40",
  },
  {
    key: "16:45",
    text: "16:45",
    value: "16:45",
  },
  {
    key: "16:50",
    text: "16:50",
    value: "16:50",
  },
  {
    key: "16:55",
    text: "16:55",
    value: "16:55",
  },
  {
    key: "17:00",
    text: "17:00",
    value: "17:00",
  },
  {
    key: "17:05",
    text: "17:05",
    value: "17:05",
  },
  {
    key: "17:10",
    text: "17:10",
    value: "17:10",
  },
  {
    key: "17:15",
    text: "17:15",
    value: "17:15",
  },
  {
    key: "17:20",
    text: "17:20",
    value: "17:20",
  },
  {
    key: "17:25",
    text: "17:25",
    value: "17:25",
  },
  {
    key: "17:30",
    text: "17:30",
    value: "17:30",
  },
  {
    key: "17:35",
    text: "17:35",
    value: "17:35",
  },
  {
    key: "17:40",
    text: "17:40",
    value: "17:40",
  },
  {
    key: "17:45",
    text: "17:45",
    value: "17:45",
  },
  {
    key: "17:50",
    text: "17:50",
    value: "17:50",
  },
  {
    key: "17:55",
    text: "17:55",
    value: "17:55",
  },
  {
    key: "18:00",
    text: "18:00",
    value: "18:00",
  },
  {
    key: "18:05",
    text: "18:05",
    value: "18:05",
  },
  {
    key: "18:10",
    text: "18:10",
    value: "18:10",
  },
  {
    key: "18:15",
    text: "18:15",
    value: "18:15",
  },
  {
    key: "18:20",
    text: "18:20",
    value: "18:20",
  },
  {
    key: "18:25",
    text: "18:25",
    value: "18:25",
  },
  {
    key: "18:30",
    text: "18:30",
    value: "18:30",
  },
  {
    key: "18:35",
    text: "18:35",
    value: "18:35",
  },
  {
    key: "18:40",
    text: "18:40",
    value: "18:40",
  },
  {
    key: "18:45",
    text: "18:45",
    value: "18:45",
  },
  {
    key: "18:50",
    text: "18:50",
    value: "18:50",
  },
  {
    key: "18:55",
    text: "18:55",
    value: "18:55",
  },
  {
    key: "19:00",
    text: "19:00",
    value: "19:00",
  },
  {
    key: "19:05",
    text: "19:05",
    value: "19:05",
  },
  {
    key: "19:10",
    text: "19:10",
    value: "19:10",
  },
  {
    key: "19:15",
    text: "19:15",
    value: "19:15",
  },
  {
    key: "19:20",
    text: "19:20",
    value: "19:20",
  },
  {
    key: "19:25",
    text: "19:25",
    value: "19:25",
  },
  {
    key: "19:30",
    text: "19:30",
    value: "19:30",
  },
  {
    key: "19:35",
    text: "19:35",
    value: "19:35",
  },
  {
    key: "19:40",
    text: "19:40",
    value: "19:40",
  },
  {
    key: "19:45",
    text: "19:45",
    value: "19:45",
  },
  {
    key: "19:50",
    text: "19:50",
    value: "19:50",
  },
  {
    key: "19:55",
    text: "19:55",
    value: "19:55",
  },
  {
    key: "20:00",
    text: "20:00",
    value: "20:00",
  },
  {
    key: "20:05",
    text: "20:05",
    value: "20:05",
  },
  {
    key: "20:10",
    text: "20:10",
    value: "20:10",
  },
  {
    key: "20:15",
    text: "20:15",
    value: "20:15",
  },
  {
    key: "20:20",
    text: "20:20",
    value: "20:20",
  },
  {
    key: "20:25",
    text: "20:25",
    value: "20:25",
  },
  {
    key: "20:30",
    text: "20:30",
    value: "20:30",
  },
  {
    key: "20:35",
    text: "20:35",
    value: "20:35",
  },
  {
    key: "20:40",
    text: "20:40",
    value: "20:40",
  },
  {
    key: "20:45",
    text: "20:45",
    value: "20:45",
  },
  {
    key: "20:50",
    text: "20:50",
    value: "20:50",
  },
  {
    key: "20:55",
    text: "20:55",
    value: "20:55",
  },
  {
    key: "21:00",
    text: "21:00",
    value: "21:00",
  },
  {
    key: "21:05",
    text: "21:05",
    value: "21:05",
  },
  {
    key: "21:10",
    text: "21:10",
    value: "21:10",
  },
  {
    key: "21:15",
    text: "21:15",
    value: "21:15",
  },
  {
    key: "21:20",
    text: "21:20",
    value: "21:20",
  },
  {
    key: "21:25",
    text: "21:25",
    value: "21:25",
  },
  {
    key: "21:30",
    text: "21:30",
    value: "21:30",
  },
  {
    key: "21:35",
    text: "21:35",
    value: "21:35",
  },
  {
    key: "21:40",
    text: "21:40",
    value: "21:40",
  },
  {
    key: "21:45",
    text: "21:45",
    value: "21:45",
  },
  {
    key: "21:50",
    text: "21:50",
    value: "21:50",
  },
  {
    key: "21:55",
    text: "21:55",
    value: "21:55",
  },
  {
    key: "22:00",
    text: "22:00",
    value: "22:00",
  },
  {
    key: "22:05",
    text: "22:05",
    value: "22:05",
  },
  {
    key: "22:10",
    text: "22:10",
    value: "22:10",
  },
  {
    key: "22:15",
    text: "22:15",
    value: "22:15",
  },
  {
    key: "22:20",
    text: "22:20",
    value: "22:20",
  },
  {
    key: "22:25",
    text: "22:25",
    value: "22:25",
  },
  {
    key: "22:30",
    text: "22:30",
    value: "22:30",
  },
  {
    key: "22:35",
    text: "22:35",
    value: "22:35",
  },
  {
    key: "22:40",
    text: "22:40",
    value: "22:40",
  },
  {
    key: "22:45",
    text: "22:45",
    value: "22:45",
  },
  {
    key: "22:50",
    text: "22:50",
    value: "22:50",
  },
  {
    key: "22:55",
    text: "22:55",
    value: "22:55",
  },
  {
    key: "23:00",
    text: "23:00",
    value: "23:00",
  },
  {
    key: "23:05",
    text: "23:05",
    value: "23:05",
  },
  {
    key: "23:10",
    text: "23:10",
    value: "23:10",
  },
  {
    key: "23:15",
    text: "23:15",
    value: "23:15",
  },
  {
    key: "23:20",
    text: "23:20",
    value: "23:20",
  },
  {
    key: "23:25",
    text: "23:25",
    value: "23:25",
  },
  {
    key: "23:30",
    text: "23:30",
    value: "23:30",
  },
  {
    key: "23:35",
    text: "23:35",
    value: "23:35",
  },
  {
    key: "23:40",
    text: "23:40",
    value: "23:40",
  },
  {
    key: "23:45",
    text: "23:45",
    value: "23:45",
  },
  {
    key: "23:50",
    text: "23:50",
    value: "23:50",
  },
  {
    key: "23:55",
    text: "23:55",
    value: "23:55",
  },
];
