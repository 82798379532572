import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Segment, Icon, Form, Button } from "semantic-ui-react";
import { GoogleLogin } from 'react-google-login';
import {
  getDisciplineScore,
  postDisciplineScore,
  updateDisciplineScore,
  deleteDisciplineScore
} from "./service";

class Settings extends Component {
  state = {
    loading: true,
    data: []
  };

  componentDidMount() {
  }

  responseGoogle = (response) => {
     console.log(response);
  }
  onFailure = (response) => {
     console.log(response,"onFailure");
  }
  render() {
    const state = this.state;
    return (
      <Segment className="contentSegment" textAlign="center">
      <br/>
      <GoogleLogin
      clientId="559614217028-qofcftpnilkttk3shec5f245cvd2e1th.apps.googleusercontent.com"
       // clientId="559614217028-qofcftpnilkttk3shec5f245cvd2e1th.apps.googleusercontent.com"
       buttonText="Login"
       render={renderProps => (
         <Button onClick={renderProps.onClick} disabled={renderProps.disabled} color='google plus'>
            <Icon name='google' /> Log in with BAC account
          </Button>
       )}
       scope={SCOPES}
       onSuccess={this.responseGoogle}
       onFailure={this.onFailure}
       cookiePolicy={'single_host_origin'}
       // isSignedIn={true}
     />
     <br/>
      </Segment>
    );
  }
}
const SCOPES = 'profile email'
// const SCOPES = 'profile email  https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events'

export default withRouter(Settings);
