import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid, Statistic, Header } from "semantic-ui-react";
import Wrap from "../../components/wrap";
import { Doughnut, Chart } from "react-chartjs-2";
import { getModuleProcess, getModuleChecklist } from "./service";
var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
  draw: function () {
    originalDoughnutDraw.apply(this, arguments);

    var chart = this.chart.chart;
    var ctx = chart.ctx;
    var width = chart.width;
    var height = chart.height;

    var fontSize = (height / 114).toFixed(2);
    ctx.font = fontSize + "em Athiti";
    ctx.textBaseline = "middle";
    ctx.fillStyle = "#27a8a2";

    var text = chart.config.data.text,
      textX = Math.round((width - ctx.measureText(text).width) / 2),
      textY = height / 2;

    ctx.fillText(text, textX, textY);
  },
});
class OverAllDetail extends Component {
  state = {
    id: this.props && this.props.id,
    data: this.props && this.props.course,
    dataPie: null,
    loading: true,
    dataChecklist: null,
  };
  getPieGraph = (course, module) => {
    // console.log('click course',course)
    // console.log('click module',module)
    this.setState({ dataPie: null, dataChecklist: null });
    let data = {
      id: this.state.id,
      courseId: course.id,
      moduleId: module.id,
    };
    getModuleProcess(data).then((res) => {
      if (res && res.status === 200) {
        console.log("getModuleProcess", res.data);
        this.setState({ dataPie: res.data });
      }
    });
    getModuleChecklist(data).then((res) => {
      if (res && res.status === 200) {
        console.log("getModuleChecklist", res.data);
        this.setState({ dataChecklist: res.data });
      }
    });
  };
  componentWillReceiveProps(nextProps) {
    // console.log('nextProps',nextProps);
    if (nextProps.course !== this.state.course) {
      if (nextProps.course) {
        this.setState({ dataPie: null });
      }
    }
  }

  render() {
    const state = this.state;
    const props = this.props;
    // console.log('stateid',state.id)
    // console.log('statedata',state.data)
    // console.log('statedataPie',state.dataPie)
    // if(state.data)
    //   return <Loader active inline="centered" content="Loading" />
    return (
      <div>
        <Grid style={{ padding: "20px" }}>
          {props.course && (
            <Wrap>
              <Grid.Row columns="1">
                <Grid.Column textAlign="center">
                  <Statistic color="teal statistic" size="large">
                    <Statistic.Label>
                      {props.course.name && (
                        <span>
                          {props.course.name} : {props.course.desc}
                        </span>
                      )}
                    </Statistic.Label>
                    <Statistic.Value>
                      {props.course.percent ? (
                        <span>{props.course.percent}%</span>
                      ) : (
                        <span>0%</span>
                      )}
                    </Statistic.Value>
                  </Statistic>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={props.course.modules.length}>
                {props.course.modules.map((mod) => (
                  <Grid.Column
                    textAlign="center"
                    className="progressbar"
                    style={{
                      border: "0.1rem solid #ffffff",
                      padding: "12px",
                      cursor: "pointer",
                      // height: "45px",
                      backgroundImage: `linear-gradient(90deg, #27a8a2  ${
                        mod.percent
                      }%, #FE8B05 ${mod.percent}% ${100 - mod.percent}%)`,
                    }}
                    onClick={() => this.getPieGraph(props.course, mod)}
                  >
                    <h4
                      style={{
                        textAlign: "center",
                        color: "#ffffff",
                        fontSize: "bold",
                      }}
                    >
                      {mod.name}
                    </h4>
                  </Grid.Column>
                ))}
              </Grid.Row>
            </Wrap>
          )}
        </Grid>
        {state.dataPie && (
          <Grid style={{ padding: "20px" }}>
            <Grid.Row>
              <Grid.Column>
                <Header
                  as="h2"
                  // content={state.dataPie.name}
                  // subheader={state.dataPie.desc}
                >
                  Module: {state.dataPie.name}{" "}
                  {state.dataPie.desc && <span>({state.dataPie.desc})</span>}
                </Header>
              </Grid.Column>
            </Grid.Row>
            {state.dataChecklist && (
              <Wrap>
                <Grid.Row columns="1">
                  <Grid.Column textAlign="center">
                    <Statistic color="teal statistic" size="large">
                      <Statistic.Label>Progress Checklist</Statistic.Label>
                    </Statistic>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={state.dataChecklist.checklist.length}>
                  {state.dataChecklist.checklist.length > 0 ? (
                    state.dataChecklist.checklist.map((chk) => (
                      <Grid.Column
                        textAlign="center"
                        style={{
                          border: "0.1rem solid black",
                          padding: "16px",
                          backgroundColor: `${chk.color}`,
                        }}
                      >
                        <h4
                          style={{
                            textAlign: "center",
                            color: "#ffffff",
                            fontSize: "bold",
                          }}
                        >
                          {chk.name}
                        </h4>
                      </Grid.Column>
                    ))
                  ) : (
                    <Grid.Column
                      textAlign="center"
                      style={{
                        border: "0.1rem solid black",
                        padding: "16px",
                        backgroundColor: `#FFFFFF`,
                      }}
                    >
                      <h4
                        style={{
                          textAlign: "center",
                          color: "#000000",
                          fontSize: "bold",
                        }}
                      >
                        NO CHECKLIST
                      </h4>
                    </Grid.Column>
                  )}
                </Grid.Row>
              </Wrap>
            )}
            <Grid.Row columns="2">
              <Grid.Column textAlign="center">
                <Header
                  as="h3"
                  content="Ground"
                  // subheader='Manage your account settings and set email preferences'
                />
                <Doughnut
                  data={
                    state.dataPie.groundSubjects && {
                      labels: state.dataPie.groundSubjects.label,
                      datasets: [
                        {
                          labels: false,
                          data: state.dataPie.groundSubjects.value,
                          backgroundColor: state.dataPie.groundSubjects.color,
                        },
                      ],
                      text: state.dataPie.groundSubjects.percent
                        ? state.dataPie.groundSubjects.percent + "%"
                        : "0%",
                    }
                  }
                  options={{
                    legend: {
                      display: false,
                    },
                  }}
                />
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Header
                  as="h3"
                  content="Flight"
                  // subheader='Manage your account settings and set email preferences'
                />
                <Doughnut
                  data={
                    state.dataPie.flightSubjects && {
                      labels: state.dataPie.flightSubjects.label,
                      datasets: [
                        {
                          data: state.dataPie.flightSubjects.value,
                          backgroundColor: state.dataPie.flightSubjects.color,
                        },
                      ],
                      text: state.dataPie.flightSubjects.percent
                        ? state.dataPie.flightSubjects.percent + "%"
                        : "0%",
                    }
                  }
                  options={{
                    legend: {
                      display: false,
                    },
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </div>
    );
  }
}

export default withRouter(OverAllDetail);
