import React, { Component } from "react";
import { Button, Icon, Form, Table, List, Message } from "semantic-ui-react";
import { Field, reduxForm, change } from "redux-form";
import MainButton from "../../components/mainbutton";
// import Table from '../../components/table'

import renderFileField from "../../components/renderFileField";
import ModalForm from "../../components/modal";
import FormPopup from "./form";
import {
  getSpl,
  postSpl,
  uploadImageSpl,
  deleteImageSpl,
  getMed,
  postMed,
  uploadImageMed,
  deleteImageMed,
  getHospital,
  getDcp,
  postDcp,
  uploadImageDCP,
  deleteImageDCP,
  getPersonnelDoc,
  uploadDoc,
  deleteDoc,
  updateDoc,
} from "./service";
import moment from "moment";
const validate = (values) => {
  console.log("validate", values);
  const errors = {};
  if (!values.medfile) errors.medfile = true;
  if (!values.splfile) errors.splfile = true;
  return errors;
};
class Documents extends Component {
  state = {
    bacUserData: JSON.parse(localStorage.getItem("bacUserData")),
    bac_permission: JSON.parse(localStorage.getItem("bac_permission")),
    fileSpl: null,
    fileNameSpl: null,
    fileMed: null,
    fileNameMed: null,
    fileDCP: null,
    fileNameDCP: null,
    uploadError: null,
    documentlist: [],
  };
  onSubmitMedical = (data) => {
    let { info } = this.props;
    this.setState({ loading: true });
    postMed(info.id, data).then((res) => {
      if (res && res.status === 200) {
        this.setState({ openCert: false });
        this.getMedById(info.id);
      }
      this.setState({ loading: false });
    });
  };
  onSubmitSpl = (data) => {
    let { info } = this.props;
    this.setState({ loading: true });
    postSpl(info.id, data).then((res) => {
      if (res && res.status === 200) {
        this.setState({ openPilot: false });
        this.getSplById(info.id);
      }
      this.setState({ loading: false });
    });
  };
  onSubmitDCP = (data) => {
    console.log(data);
    let { info } = this.props;
    this.setState({ loading: true });
    postDcp(info.id, data).then((res) => {
      if (res && res.status === 200) {
        this.setState({ openDCP: false });
        this.getDcpById(info.id);
      }
      this.setState({ loading: false });
    });
  };
  onClickAction = (value) => {
    if (value === "Complete User Profile") {
      this.setState({ openProfile: true });
    } else if (value === "Emergency Contact Info") {
      this.setState({ openContact: true });
    } else if (value === "Profile Photo") {
      this.setState({ openPPhoto: true });
    } else if (value === "Photo ID") {
      this.setState({ openPhotoID: true });
    } else if (value === "Medical Certificate") {
      this.setState({ openCert: true });
    } else if (value === "Student Pilot License") {
      this.setState({ openPilot: true });
    } else if (value === "DCP License") {
      this.setState({ openDCP: true });
    }
  };
  onClickUploadPhoto = (name) => {
    let { med, spl, dcp } = this.state;
    // console.log('ccc', med, spl)

    if (name === "UploadPhotoMed") {
      if (med.imageURL) {
        var fileName = med.imageURL.split("/");
        fileName = fileName.pop();
        this.setState({ fileMed: med.imageURL, fileNameMed: fileName });
      }
      this.setState({ openUploadPhotoMed: true });
      // this.props.dispatch(change("documents", "medfile", med.imageURL));
    } else if (name === "UploadPhotoSpl") {
      if (spl.imageURL) {
        var fileName = spl.imageURL.split("/");
        fileName = fileName.pop();
        this.setState({ fileSpl: spl.imageURL, fileNameSpl: fileName });
      }
      this.setState({ openUploadPhotoSpl: true });
      // this.props.dispatch(change("documents", "splfile", spl.imageURL));
    } else if (name === "UploadPhotoDCP") {
      if (dcp.imageUrl) {
        var fileName = dcp.imageUrl.split("/");
        fileName = fileName.pop();
        this.setState({ fileDCP: dcp.imageUrl, fileNameDCP: fileName });
      }
      this.setState({ openUploadPhotoDCP: true });
    }
  };
  onUploadfileSpl = (values) => {
    if (values.splfile) {
      // console.log('values.splfile',values.splfile);
      this.setState({ loading: true, uploadError: null }); // imageURLSpl: null
      let { data } = this.state;
      let { info } = this.props;
      var formData = new FormData();
      if (values.splfile && typeof values.splfile === "object")
        formData.append("image", values.splfile[0]);
      else if (values.splfile) {
        formData.append("image", values.splfile);
      } else formData.append("image", "");
      // console.log('formData',...formData)
      uploadImageSpl(info.id, formData).then((res) => {
        console.log("uploadImageSpl", res);
        if (res && res.status === 200) {
          if (res.data.image) {
            this.setState({ uploadError: res.data.image && res.data.image[0] });
          } else {
            this.getSplById(info.id);
            this.close();
          }
        } else if (res && res.status === 422) {
          this.setState({ FileError: "Please browse file" });
        }
        this.setState({ loading: false });
      });
    }
  };
  onDeletePhotoSpl = () => {
    let { info } = this.props;
    deleteImageSpl(info.id).then((res) => {
      if (res && res.status === 200) {
        this.setState({ fileSpl: null, fileNameSpl: null });
        this.props.dispatch(change("documents", "splfile", null));
        this.getSplById(info.id);
      }
      // this.setState({ loading: false });
    });
  };
  // onUploadPhotoMed = file => {
  //   if (file) {
  //     this.setState({ loading: true });// imageURLMed:null
  //     let { med } = this.state;
  //     let { info } = this.props;
  //     var formData = new FormData();
  //     if (typeof file === "object") formData.append("image", file[0]);
  //     else formData.append("image", file);
  //     uploadImageMed(info.id, formData).then(res => {
  //       if (res && res.status === 200) {
  //         this.setState({
  //           imageURLMed: res.data.imageUrl
  //         });
  //         this.getMedById(info.id);
  //         // this.getDataInstructors(data.id);
  //       } else if (res && res.status === 422) {
  //         this.setState({
  //           uploadError:
  //             res.data.errors &&
  //             res.data.errors.image &&
  //             res.data.errors.image[0]
  //         });
  //       } else {
  //         this.setState({
  //           uploadError: "Whoops, looks like something went wrong."
  //         });
  //       }
  //       this.setState({ loading: false });
  //     });
  //   }
  // };
  onUploadfileMed = (values) => {
    // console.log(values)
    if (values.medfile) {
      this.setState({ loading: true, uploadError: null }); // imageURLMed:null
      let { med } = this.state;
      let { info } = this.props;
      var formData = new FormData();
      if (values.medfile && typeof values.medfile === "object")
        formData.append("image", values.medfile[0]);
      else if (values.medfile) {
        formData.append("image", values.medfile);
      } else formData.append("image", "");
      uploadImageMed(info.id, formData).then((res) => {
        if (res && res.status === 200) {
          if (res.data.image) {
            this.setState({ uploadError: res.data.image && res.data.image[0] });
          } else {
            this.getMedById(info.id);
            this.close();
          }
        } else if (res && res.status === 422) {
          this.setState({ FileError: "Please browse file" });
        }
        this.setState({ loading: false });
      });
    }
  };
  onDeletePhotoMed = () => {
    let { info } = this.props;
    deleteImageMed(info.id).then((res) => {
      if (res && res.status === 200) {
        this.setState({ fileMed: null, fileNameMed: null });
        this.props.dispatch(change("documents", "medfile", null));
        this.getMedById(info.id);
        // this.getDataInstructors(data.id);
      }
      this.setState({ loading: false });
    });
  };
  onUploadfileDCP = (values) => {
    if (values.dcpfile) {
      // console.log('values.splfile',values.splfile);
      this.setState({ loading: true, uploadError: null }); // imageURLSpl: null
      let { data } = this.state;
      let { info } = this.props;
      var formData = new FormData();
      if (values.dcpfile && typeof values.dcpfile === "object")
        formData.append("image", values.dcpfile[0]);
      else if (values.dcpfile) {
        formData.append("image", values.dcpfile);
      } else formData.append("image", "");
      // console.log('formData',...formData)
      uploadImageDCP(info.id, formData).then((res) => {
        console.log("uploadImageDCP", res);
        if (res && res.status === 200) {
          if (res.data.image) {
            this.setState({ uploadError: res.data.image && res.data.image[0] });
          } else {
            this.getDcpById(info.id);
            this.close();
          }
        } else if (res && res.status === 422) {
          this.setState({ FileError: "Please browse file" });
        }
        this.setState({ loading: false });
      });
    }
  };
  onDeletePhotoDCP = () => {
    let { info } = this.props;
    deleteImageDCP(info.id).then((res) => {
      if (res && res.status === 200) {
        this.setState({ fileDCP: null, fileNameDCP: null });
        this.props.dispatch(change("documents", "dcpfile", null));
        this.getDcpById(info.id);
      }
      // this.setState({ loading: false });
    });
  };
  close = () => {
    this.setState({
      openProfile: false,
      openContact: false,
      openPPhoto: false,
      openPhotoID: false,
      openCert: false,
      openPilot: false,
      openUploadPhotoMed: false,
      openUploadPhotoSpl: false,
      openUploadPhotoDCP: false,
      openDCP: false,
      open: false,
      fileMed: null,
      fileNameMed: null,
      fileSpl: null,
      fileNameSpl: null,
      uploadError: null,
      newdoc: null,
    });
  };
  getSplById = (id) => {
    getSpl(id).then((res) => {
      if (res && res.status === 200) {
        this.setState({ spl: res.data });
      }
    });
  };
  getMedById = (id) => {
    getMed(id).then((res) => {
      if (res && res.status === 200) {
        this.setState({ med: res.data });
      }
    });
  };
  getDcpById = (id) => {
    getDcp(id).then((res) => {
      if (res && res.status === 200) {
        this.setState({ dcp: res.data });
      }
    });
  };

  getPersonnelDocById = (id) => {
    getPersonnelDoc(id).then((res) => {
      if (res && res.status === 200) {
        this.setState({ documentlist: res.data });
      }
    });
  };

  onUploadNewfile = (values) => {
    this.setState({ loading: true, uploadError: null }); // imageURLSpl: null
    let { info } = this.props;
    var formData = new FormData();
    if (values.name) formData.append("name", values.name);
    if (values.desc) formData.append("desc", values.desc);
    if (values.expirydate) formData.append("expirydate", values.expirydate);
    if (values.file && typeof values.file === "object")
      formData.append("file", values.file[0]);

    // console.log('formData',...formData)
    if (values.id) {
      updateDoc(values.id, formData).then((res) => {
        if (res && res.status === 200) {
          if (res.data.file) {
            this.setState({
              uploadError: res.data.file && res.data.file[0],
            });
          } else {
            this.getPersonnelDocById(info.id);
            this.close();
          }
        } else if (res && res.status === 422) {
          this.setState({ FileError: "Please browse file" });
        } else if (res && res.status === 201) {
          this.getPersonnelDocById(info.id);
          this.close();
        }
        this.setState({ loading: false });
      });
    } else {
      uploadDoc(info.id, formData).then((res) => {
        if (res && res.status === 200) {
          if (res.data.file) {
            this.setState({
              uploadError: res.data.file && res.data.file[0],
            });
          } else {
            this.getPersonnelDocById(info.id);
            this.close();
          }
        } else if (res && res.status === 422) {
          this.setState({ FileError: "Please browse file" });
        } else if (res && res.status === 201) {
          this.getPersonnelDocById(info.id);
          this.close();
        }
        this.setState({ loading: false });
      });
    }
  };
  onDeleteNewfile = (id) => {
    this.setState({ loading: true });
    deleteDoc(id).then((res) => {
      if (res && res.status === 200) {
        let { info } = this.props;

        this.getPersonnelDocById(info.id);
        this.close();
      }
      this.setState({ loading: false });
    });
  };
  checkPermissionMenu = (group, permission) => {
    let { bac_permission } = this.state;
    let userpermissionGroup = bac_permission.find(
      (item) => item.group === group
    );
    let allowed = permission.find((item) => {
      if (
        userpermissionGroup &&
        userpermissionGroup.permission &&
        userpermissionGroup.permission.indexOf(item) !== -1
      )
        return item;
    });
    if (allowed) return true;
    else return false;
  };
  show = () => {
    this.setState({ open: true, content: "New Document", uploadError: null });
  };

  onEditNewfile = (value) => {
    this.setState({
      newdoc: value,
      open: true,
    });
  };

  componentDidMount() {
    let { info } = this.props;
    getHospital().then((res) => {
      let options = [];
      if (res && res.status === 200) {
        res.data.map((item) => {
          options.push({ key: item.id, text: item.name, value: item.id });
        });
        this.setState({
          options_hospital: options,
          hospital: res.data,
        });
      }
    });

    this.getSplById(info.id);
    this.getMedById(info.id);
    this.getDcpById(info.id);
    this.getPersonnelDocById(info.id);
  }

  render() {
    const state = this.state;
    const { info, page } = this.props;
    const { handleSubmit, pristine, submitting } = this.props;
    // console.log(state.uploadError)
    // console.log('state.med.expirydate', moment(state.med.expirydate).format('YYYY-MM-DD'), moment(state.med.expirydate))

    // if (state.med || state.spl) {
    //   return (
    //     <div>
    //       <Icon loading name="spinner" /> Loading
    //     </div>
    //   );
    // }

    return (
      <div>
        {this.checkPermissionMenu("Personnel", [
          "manage-instructor",
          "manage-student",
          "manage-staff",
        ]) && (
          <Form>
            <Form.Field>
              <MainButton
                size="small"
                onClick={this.show}
                text="New Document"
              />
            </Form.Field>
          </Form>
        )}
        <ModalForm
          icon="sticky note outline"
          content={state.content}
          open={state.open}
          close={this.close}
        >
          {state.uploadError && (
            <Message negative>
              <p>{state.uploadError}</p>
            </Message>
          )}
          <FormPopup
            content="newdoc"
            title="Document"
            onSubmit={this.onUploadNewfile}
            initialValues={state.newdoc ? state.newdoc : null}
            loading={state.loading}
            onCancel={this.close}
          />
        </ModalForm>

        <Table compact celled striped style={{ marginBottom: "30px" }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="header-table" width={5}>
                Name
              </Table.HeaderCell>
              <Table.HeaderCell className="header-table" width={8}>
                Description
              </Table.HeaderCell>
              {this.checkPermissionMenu("Personnel", [
                "manage-instructor",
                "manage-student",
                "manage-staff",
              ]) && (
                <Table.HeaderCell
                  className="header-table"
                  width={3}
                  textAlign="center"
                >
                  Action
                </Table.HeaderCell>
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell textAlign="left">Medical Certificate</Table.Cell>
              <Table.Cell>
                <List>
                  <List.Item>
                    <b>Medical Class:</b>{" "}
                    {state.med && state.med.medicalclass
                      ? state.med.medicalclass
                      : "-"}
                  </List.Item>
                  <List.Item>
                    <b>Hospital:</b>{" "}
                    {state.med && state.med.name ? state.med.name : "-"}
                  </List.Item>
                  <List.Item>
                    <b>Expired Date: </b>
                    {state.med && state.med.expirydate ? (
                      moment().isAfter(
                        moment(state.med.expirydate, "DD-MM-YYYY").format(
                          "YYYY-MM-DD"
                        )
                      ) ? (
                        <span>
                          <Icon name="exclamation triangle" color="red" />
                          <span style={{ color: "red" }}>
                            <b>{state.med.expirydate} Expired!!!</b>
                          </span>
                        </span>
                      ) : moment().isAfter(
                          moment(state.med.expirydate, "DD-MM-YYYY")
                            .subtract(30, "days")
                            .format("YYYY-MM-DD")
                        ) ? (
                        <span>
                          <Icon name="exclamation triangle" color="orange" />
                          <span style={{ color: "orange" }}>
                            <b>{state.med.expirydate}</b>
                          </span>
                        </span>
                      ) : (
                        state.med.expirydate
                      )
                    ) : (
                      "-"
                    )}
                  </List.Item>
                  <List.Item>
                    <b>File:</b>{" "}
                    {state.med && state.med.imageURL ? (
                      <span>
                        <Icon name="paperclip" />
                        <a href={state.med.imageURL} download target="_blank">
                          Download File
                        </a>
                      </span>
                    ) : (
                      "-"
                    )}
                  </List.Item>
                </List>
              </Table.Cell>
              {this.checkPermissionMenu("Personnel", [
                "manage-instructor",
                "manage-student",
                "manage-staff",
              ]) && (
                <Table.Cell textAlign="center">
                  <Icon
                    link
                    circular
                    color="orange"
                    name="edit"
                    onClick={() => this.onClickAction("Medical Certificate")}
                  />
                  <Icon
                    link
                    circular
                    color="orange"
                    name="file pdf"
                    onClick={() => this.onClickUploadPhoto("UploadPhotoMed")}
                  />
                </Table.Cell>
              )}
            </Table.Row>
            <Table.Row>
              <Table.Cell textAlign="left">
                {page && page === "Instructor" ? (
                  <p>Pilot License</p>
                ) : (
                  <p>Student Pilot License</p>
                )}
              </Table.Cell>
              <Table.Cell>
                <List>
                  <List.Item>
                    <b>
                      {page && page === "Instructor" ? (
                        <span>CPL/ATPL No.: </span>
                      ) : (
                        <span>SPL No.: </span>
                      )}
                    </b>{" "}
                    {state.spl && state.spl.splnumber
                      ? state.spl.splnumber
                      : "-"}
                  </List.Item>
                  <List.Item>
                    <b>
                      {page && page === "Instructor" ? (
                        <span>CPL/ATPL Expired Date: </span>
                      ) : (
                        <span>SPL Expired Date: </span>
                      )}
                    </b>
                    {/* {state.spl && state.spl.expirydate ? state.spl.expirydate : '-'} */}
                    {state.spl && state.spl.expirydate ? (
                      moment().isAfter(
                        moment(state.spl.expirydate, "DD-MM-YYYY").format(
                          "YYYY-MM-DD"
                        )
                      ) ? (
                        <span>
                          <Icon name="exclamation triangle" color="red" />
                          <span style={{ color: "red" }}>
                            <b>{state.spl.expirydate} Expired!!!</b>
                          </span>
                        </span>
                      ) : moment().isAfter(
                          moment(state.spl.expirydate, "DD-MM-YYYY")
                            .subtract(30, "days")
                            .format("YYYY-MM-DD")
                        ) ? (
                        <span>
                          <Icon name="exclamation triangle" color="orange" />
                          <span style={{ color: "orange" }}>
                            <b>{state.spl.expirydate}</b>
                          </span>
                        </span>
                      ) : (
                        state.spl.expirydate
                      )
                    ) : (
                      "-"
                    )}
                  </List.Item>
                  <List.Item>
                    <b>File:</b>
                    {state.spl && state.spl.imageURL ? (
                      <span>
                        <Icon name="paperclip" />
                        <a href={state.spl.imageURL} download target="_blank">
                          Download File
                        </a>
                      </span>
                    ) : (
                      "-"
                    )}
                  </List.Item>
                </List>
              </Table.Cell>
              {this.checkPermissionMenu("Personnel", [
                "manage-instructor",
                "manage-student",
                "manage-staff",
              ]) && (
                <Table.Cell textAlign="center">
                  <Icon
                    link
                    circular
                    color="orange"
                    name="edit"
                    onClick={() => this.onClickAction("Student Pilot License")}
                  />
                  <Icon
                    link
                    circular
                    color="orange"
                    name="file pdf"
                    onClick={() => this.onClickUploadPhoto("UploadPhotoSpl")}
                  />
                </Table.Cell>
              )}
            </Table.Row>
            {page && page === "Instructor" && (
              <Table.Row>
                <Table.Cell textAlign="left">
                  <p>DCP License</p>
                </Table.Cell>
                <Table.Cell>
                  <List>
                    <List.Item>
                      <b>Issued Date: </b>{" "}
                      {state.dcp && state.dcp.issueddate
                        ? state.dcp.issueddate
                        : "-"}
                    </List.Item>
                    <List.Item>
                      <b>Expired Date: </b>
                      {/* {state.spl && state.spl.expirydate ? state.spl.expirydate : '-'} */}
                      {state.dcp && state.dcp.expirydate ? (
                        moment().isAfter(
                          moment(state.dcp.expirydate, "DD-MM-YYYY").format(
                            "YYYY-MM-DD"
                          )
                        ) ? (
                          <span>
                            <Icon name="exclamation triangle" color="red" />
                            <span style={{ color: "red" }}>
                              <b>{state.dcp.expirydate} Expired!!!</b>
                            </span>
                          </span>
                        ) : moment().isAfter(
                            moment(state.dcp.expirydate, "DD-MM-YYYY")
                              .subtract(30, "days")
                              .format("YYYY-MM-DD")
                          ) ? (
                          <span>
                            <Icon name="exclamation triangle" color="orange" />
                            <span style={{ color: "orange" }}>
                              <b>{state.dcp.expirydate}</b>
                            </span>
                          </span>
                        ) : (
                          state.dcp.expirydate
                        )
                      ) : (
                        "-"
                      )}
                    </List.Item>
                    <List.Item>
                      <b>File:</b>
                      {state.dcp && state.dcp.imageUrl ? (
                        <span>
                          <Icon name="paperclip" />
                          <a href={state.dcp.imageUrl} download target="_blank">
                            Download File
                          </a>
                        </span>
                      ) : (
                        "-"
                      )}
                    </List.Item>
                  </List>
                </Table.Cell>
                {this.checkPermissionMenu("Personnel", [
                  "manage-instructor",
                  "manage-student",
                  "manage-staff",
                ]) && (
                  <Table.Cell textAlign="center">
                    <Icon
                      link
                      circular
                      color="orange"
                      name="edit"
                      onClick={() => this.onClickAction("DCP License")}
                    />
                    <Icon
                      link
                      circular
                      color="orange"
                      name="file pdf"
                      onClick={() => this.onClickUploadPhoto("UploadPhotoDCP")}
                    />
                  </Table.Cell>
                )}
              </Table.Row>
            )}
            {state.documentlist.map((item) => (
              <Table.Row>
                <Table.Cell textAlign="left">
                  <p>{item.name}</p>
                </Table.Cell>
                <Table.Cell>
                  <List>
                    <List.Item>
                      <p>{item.desc}</p>
                      <b>Expired Date: </b>
                      {item.expirydate ? (
                        moment().isAfter(
                          moment(item.expirydate, "DD-MM-YYYY").format(
                            "YYYY-MM-DD"
                          )
                        ) ? (
                          <span>
                            <Icon name="exclamation triangle" color="red" />
                            <span style={{ color: "red" }}>
                              <b>{item.expirydate} Expired!!!</b>
                            </span>
                          </span>
                        ) : moment().isAfter(
                            moment(item.expirydate, "DD-MM-YYYY")
                              .subtract(30, "days")
                              .format("YYYY-MM-DD")
                          ) ? (
                          <span>
                            <Icon name="exclamation triangle" color="orange" />
                            <span style={{ color: "orange" }}>
                              <b>{item.expirydate}</b>
                            </span>
                          </span>
                        ) : (
                          item.expirydate
                        )
                      ) : (
                        "-"
                      )}
                    </List.Item>
                    <List.Item>
                      <b>File:</b>
                      {item.imageUrl ? (
                        <span>
                          <Icon name="paperclip" />
                          <a href={item.imageUrl} download target="_blank">
                            Download File
                          </a>
                        </span>
                      ) : (
                        "-"
                      )}
                    </List.Item>
                  </List>
                </Table.Cell>
                {this.checkPermissionMenu("Personnel", [
                  "manage-instructor",
                  "manage-student",
                  "manage-staff",
                ]) && (
                  <Table.Cell textAlign="center">
                    <Icon
                      link
                      circular
                      color="orange"
                      name="edit"
                      onClick={() => this.onEditNewfile(item)}
                    />
                    <Icon
                      link
                      color="red"
                      name="trash alternate"
                      onClick={() => this.onDeleteNewfile(item.id)}
                    />
                  </Table.Cell>
                )}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <ModalForm
          // icon='calendar alternate'
          content="DCP License"
          open={state.openDCP}
          close={this.close}
        >
          <FormPopup
            content="dcp"
            onSubmit={this.onSubmitDCP}
            initialValues={state.dcp ? state.dcp : null}
            loading={state.loading}
            onCancel={this.close}
          />
        </ModalForm>
        <ModalForm
          // icon='calendar alternate'
          content="Medical Certificate"
          open={state.openCert}
          close={this.close}
        >
          <FormPopup
            content="cert"
            onSubmit={this.onSubmitMedical}
            initialValues={state.med ? state.med : null}
            options_hospital={state.options_hospital}
            loading={state.loading}
            onCancel={this.close}
          />
        </ModalForm>
        <ModalForm
          // icon='calendar alternate'
          content={
            page && page === "Instructor" ? (
              <p>Pilot License</p>
            ) : (
              <p>Student Pilot License</p>
            )
          }
          open={state.openPilot}
          close={this.close}
        >
          <FormPopup
            content="pilot"
            title={page && page === "Instructor" ? "CPL/ATPL" : "SPL"}
            onSubmit={this.onSubmitSpl}
            initialValues={state.spl ? state.spl : null}
            loading={state.loading}
            onCancel={this.close}
          />
        </ModalForm>
        <ModalForm
          open={state.openUploadPhotoMed}
          icon="file pdf"
          size="small"
          content={`Medical Certificate`}
          close={this.close}
        >
          <Form onSubmit={handleSubmit(this.onUploadfileMed)}>
            {state.fileMed && (
              <Message size="small" style={{ margin: "3px 0px 3px 0px" }}>
                <Message.Content style={{ textAlign: "left" }}>
                  <p>
                    <Icon name="file pdf" />
                    <a
                      href={state.fileMed}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {state.fileNameMed}
                    </a>
                    <Icon
                      name="x"
                      color="red"
                      link
                      onClick={() => this.onDeletePhotoMed()}
                    />
                  </p>
                </Message.Content>
              </Message>
            )}
            <br />
            <Field
              name="medfile"
              type="file"
              required={true}
              component={renderFileField}
              label="Upload File"
              removeabel={true}
            />
            {state.uploadError && (
              <Message negative>
                <p>{state.uploadError}</p>
              </Message>
            )}
            <br />
            <Button
              floated="left"
              type="submit"
              content="Save"
              color="green"
              loading={state.loading}
              disabled={pristine || submitting}
            />
            <Button
              floated="right"
              type="button"
              content="Cancel"
              color="green"
              basic
              onClick={this.close}
            />
          </Form>
        </ModalForm>
        <ModalForm
          open={state.openUploadPhotoSpl}
          icon="file pdf"
          size="small"
          content={
            page && page === "Instructor" ? (
              <p>Pilot License</p>
            ) : (
              <p>Student Pilot License</p>
            )
          }
          close={this.close}
        >
          <Form onSubmit={handleSubmit(this.onUploadfileSpl)}>
            {state.fileSpl && (
              <Message size="small" style={{ margin: "3px 0px 3px 0px" }}>
                <Message.Content style={{ textAlign: "left" }}>
                  <p>
                    <Icon name="file pdf" />
                    <a
                      href={state.fileSpl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {state.fileNameSpl}
                    </a>
                    <Icon
                      name="x"
                      color="red"
                      link
                      onClick={() => this.onDeletePhotoSpl()}
                    />
                  </p>
                </Message.Content>
              </Message>
            )}
            <br />
            <Field
              name="splfile"
              type="file"
              required={true}
              component={renderFileField}
              label="Upload File"
              removeabel={true}
            />
            {state.uploadError && (
              <Message negative>
                <p>{state.uploadError}</p>
              </Message>
            )}
            <br />
            <Button
              floated="left"
              type="submit"
              content="Save"
              color="green"
              loading={state.loading}
              disabled={pristine || submitting}
            />
            <Button
              floated="right"
              type="button"
              content="Cancel"
              color="green"
              basic
              onClick={this.close}
            />
          </Form>
        </ModalForm>
        <ModalForm
          open={state.openUploadPhotoDCP}
          icon="file pdf"
          size="small"
          content={
            page && page === "Instructor" ? (
              <p>DCP License</p>
            ) : (
              <p>DCP License</p>
            )
          }
          close={this.close}
        >
          <Form onSubmit={handleSubmit(this.onUploadfileDCP)}>
            {state.fileDCP && (
              <Message size="small" style={{ margin: "3px 0px 3px 0px" }}>
                <Message.Content style={{ textAlign: "left" }}>
                  <p>
                    <Icon name="file pdf" />
                    <a
                      href={state.fileDCP}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {state.fileNameDCP}
                    </a>
                    <Icon
                      name="x"
                      color="red"
                      link
                      onClick={() => this.onDeletePhotoDCP()}
                    />
                  </p>
                </Message.Content>
              </Message>
            )}
            <br />
            <Field
              name="dcpfile"
              type="file"
              required={true}
              component={renderFileField}
              label="Upload File"
              removeabel={true}
            />
            {state.uploadError && (
              <Message negative>
                <p>{state.uploadError}</p>
              </Message>
            )}
            <br />
            <Button
              floated="left"
              type="submit"
              content="Save"
              color="green"
              loading={state.loading}
              disabled={pristine || submitting}
            />
            <Button
              floated="right"
              type="button"
              content="Cancel"
              color="green"
              basic
              onClick={this.close}
            />
          </Form>
        </ModalForm>
      </div>
    );
  }
}

// export default withRouter(Documents);
export default Documents = reduxForm({
  // a unique name for the form
  form: "documents",
  validate,
  enableReinitialize: true,
})(Documents);
