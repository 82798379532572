import axios from "axios";
import * as config from "../../config";

export function getStudentRegistered(id) {
  return axios
    .get(config.urlAPI + "/registrationCourse/"+id, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function getCourseList() {
  return axios
    .get(config.urlAPI + "/course", config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getCourseMasterList() {
  return axios
    .get(config.urlAPI + "/coursemaster", config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function getCourseListBySearch(qString) {
  return axios
    .get(config.urlAPI + "/course" + qString, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getCourseDetailById(id) {
  return axios
    .get(config.urlAPI + "/course/" + id, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getCourse(qString) {
  return axios
    .get(config.urlAPI + "/courseDetail" + qString, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getCourseMaster() {
  return axios
    .get(config.urlAPI + "/courseDetail/Master", config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function postCourse(data) {
  return axios
    .post(config.urlAPI + "/course", data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}


export function postCoursemaster(data) {
  return axios
    .post(config.urlAPI + "/coursemaster", data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function deleteCourse(id) {
  return axios
    .delete(config.urlAPI + "/course/"+id, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function deleteCoursemaster(id) {
  return axios
    .delete(config.urlAPI + "/coursemaster/"+id, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function updateCourse(data) {
  return axios
    .put(config.urlAPI + "/course/" + data.id, data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function updateCoursemaster(data) {
  return axios
    .put(config.urlAPI + "/coursemaster/" + data.id, data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function updateModuleInCourse(id, data) {
  return axios
    .put(
      config.urlAPI + "/updatemoduleincourse/" + id,
      data,
      config.headerCallAPI
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function getCourseHasModuleByCourse(id) {
  return axios
    .get(config.urlAPI + "/course_has_module?courseId=" + id)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function postCourseHasModule(data) {
  return axios
    .post(config.urlAPI + "/course_has_module", data)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function deleteCourseHasModule(id) {
  return axios
    .delete(config.urlAPI + "/course_has_module/" + id)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getSubjectByModule(id) {
  return axios
    .get(config.urlAPI + "/subjectByModule/" + id, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getCourseModule(qString) {
  return axios
    .get(config.urlAPI + "/module" + qString, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function postCourseModule(data) {
  return axios
    .post(config.urlAPI + "/module", data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function updateCourseModule(data) {
  return axios
    .put(config.urlAPI + "/module/"+data.id, data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function updateProgressChecklist(data) {
  return axios
    .put(
      config.urlAPI + "/moduleChecklist/" + data.id,
      data,
      config.headerCallAPI
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

// export function getCourseModuleHasSubject(qString) {
//   return axios
//     .get(config.urlAPI + "/module_has_subject" + qString)
//     .then(res => {
//       return res;
//     })
//     .catch(err => {
//       return err.response;
//     });
// }

// export function postCourseModuleHasSubject(data) {
//   return axios
//     .post(config.urlAPI + "/module_has_subject", data)
//     .then(res => {
//       return res;
//     })
//     .catch(err => {
//       return err.response;
//     });
// }


export function deleteCourseModule(id) {
  return axios
    .delete(config.urlAPI + "/module/" + id, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
// export function deleteCourseModuleHasSubject(id) {
//   return axios
//     .delete(config.urlAPI + "/module_has_subject/" + id)
//     .then(res => {
//       return res;
//     })
//     .catch(err => {
//       return err.response;
//     });
// }
export function getCourseStage(id) {
  return axios
    .get(config.urlAPI + "/courseStage/" + id)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getSubjectsByCourse(id) {
  return axios
    .get(config.urlAPI + "/subjectByCourse/" + id)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getSubjects(qString) {
  return axios
    .get(config.urlAPI + "/subject" + qString, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function postSubjects(data) {
  return axios
    .post(config.urlAPI + "/subject", data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function updateSubjects(data) {
  return axios
    .put(config.urlAPI + "/subject/" + data.id, data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function deleteSubject(id) {
  return axios
    .delete(config.urlAPI + "/subject/" + id, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

// export function postSubjectHasLesson(data) {
//   return axios
//     .post(config.urlAPI + "/subject_has_lesson", data)
//     .then(res => {
//       return res;
//     })
//     .catch(err => {
//       return err.response;
//     });
// }

// export function deleteSubjectHasLesson(id) {
//   return axios
//     .delete(config.urlAPI + "/subject_has_lesson/" + id)
//     .then(res => {
//       return res;
//     })
//     .catch(err => {
//       return err.response;
//     });
// }
export function getLesson(qString) {
  return axios
    .get(config.urlAPI + "/lesson" + qString, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function postLesson(data) {
  return axios
    .post(config.urlAPI + "/lesson", data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function updateLesson(data) {
  return axios
    .put(config.urlAPI + "/lesson/" + data.id, data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function deleteLesson(id) {
  return axios
    .delete(config.urlAPI + "/lesson/" + id, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getLogbookIP(qString) {
  return axios
    .get(config.urlAPI + "/logbookIP" + qString, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function getLogbookSP(qString) {
  return axios
    .get(config.urlAPI + "/logbookSP" + qString, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function postLogbook(data) {
  return axios
    .post(config.urlAPI + "/logbook", data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function updateLogbook(data) {
  return axios
    .put(config.urlAPI + "/logbook/" + data.id, data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function deleteLogbook(id) {
  return axios
    .delete(config.urlAPI + "/logbook/" + id, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getLogbookSPCheckBySubject(id) {
  return axios
    .get(config.urlAPI + "/logbookSPCheck?subjectId=" + id)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
// export function deleteLogbookSPCheck(id) {
//   return axios
//     .delete(config.urlAPI + "/logbookSPCheck/" + id)
//     .then(res => {
//       return res;
//     })
//     .catch(err => {
//       return err.response;
//     });
// }
// export function postLogbookSPCheck(data) {
//   return axios
//     .post(config.urlAPI + "/logbookSPCheck", data)
//     .then(res => {
//       return res;
//     })
//     .catch(err => {
//       return err.response;
//     });
// }
export function getLogbookIPCheckBySubject(id) {
  return axios
    .get(config.urlAPI + "/logbookIPCheck?subjectId=" + id)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
// export function deleteLogbookIPCheck(id) {
//   return axios
//     .delete(config.urlAPI + "/logbookIPCheck/" + id)
//     .then(res => {
//       return res;
//     })
//     .catch(err => {
//       return err.response;
//     });
// }
// export function postLogbookIPCheck(data) {
//   return axios
//     .post(config.urlAPI + "/logbookIPCheck", data)
//     .then(res => {
//       return res;
//     })
//     .catch(err => {
//       return err.response;
//     });
// }

export function getGroundInstructionType() {
  return axios
    .get(config.urlAPI + "/groundinstructiontype", config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function getFlightGroup() {
  return axios
    .get(config.urlAPI + "/flightGroup")
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function postSubjectFlightGroup(data) {
  return axios
    .post(config.urlAPI + "/subjectFlightGroup", data)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function deleteSubjectFlightGroup(id) {
  return axios
    .delete(config.urlAPI + "/subjectFlightGroup/" + id)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getSubjectFlightGroupByModule(id) {
  return axios
    .get(config.urlAPI + "/moduleFlightGroup/" + id, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function updateSubjectFlightGroupByModule(data) {
  return axios
    .put(config.urlAPI + "/moduleFlightGroup/" + data.id,data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function getProgressChecklist(id) {
  return axios
    .get(config.urlAPI + "/moduleChecklist/" + id, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getTrainingCriteria() {
  return axios
      .get(config.urlAPI + "/trainingcriteria", config.headerCallAPI)
      .then(res => {
        return res;
      })
      .catch(err => {
        return err.response;
      });
}

export function getFlightHourCriteria(id) {
  return axios
    .get(config.urlAPI + "/flightHourCriteria/" + id)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function updateModuleFlightHourCriteria(id, data) {
  return axios
    .put(
      config.urlAPI + "/courseFlightHourCriteria/" + id,
      data,
      config.headerCallAPI
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getModuleChecklist(data) {
  return axios
    .get(config.urlAPI + "/moduleChecklist/" + data.courseId + "/"+ data.moduleId, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getModuleTimeline(data) {
  return axios
    .get(config.urlAPI + "/moduleTimeline/" + data.courseId + "/"+ data.moduleId, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function updateModuleTimeline(id,data) {
  return axios
    .put(
      config.urlAPI + "/moduleTimeline/" + id,
      data,
      config.headerCallAPI
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
