import React, { Component } from "react";
import { withRouter,Link } from "react-router-dom";
import {
  Segment,
  Dropdown,
  Form,
  Button,
  Image,
  Icon,
} from "semantic-ui-react";
import * as config from "../../config";
import {
  postStudent,
  getStudentListBySearch,
  getAllClass,
  getAllCompany,
  getStudentType,
  getAllCourse,
  importStudent,
  getAllInstructors,
} from "./service";
import HeaderWithMenu from "../../components/headerwithmenu";
// import TableList from '../../components/table'
import MainButton from "../../components/mainbutton";
import ModalForm from "../../components/modal";
import DropdownCheckbox from "../../components/dropdownCheckbox";
import Wrap from "../../components/wrap";
import ScheduleById from "../Schedule/scheduleById";
import FormStudent from "./studentForm";
import moment from "moment";
import TableList from "../../components/tablePerPage";

class Student extends Component {
  state = {
    current_page: 0,
    per_page: 10,
    last_page: 1,
    open: false,
    loading: false,
    btnLoading: false,
    importFile: false,
    errorsRow: 0,
    errorInfo: null,
    rawData: [],
    data: [],
    dataEvent: [],
    options_class: [],
    options_company: [],
    options_course: [],
    options_instructors: [],
    options_studenttype: [],
    select_course: null,
    select_class: null,
    select_company: null,
    filterClassByText: "All Class",
    filterStdtypeByText: "All Student type",
    filterCourseByText: "All Course",
    search: {
      status: "Active",
      group: "",
      sort: {
        key: "firstName",
        text: "Name",
        value: "1",
        icon: "sort alphabet ascending",
        description: "ASC ",
      },
    },
    sortText: "Name [ASC]",
    options_sortby: [
      {
        key: "firstNameEng",
        text: "Name",
        value: "1",
        icon: "sort alphabet ascending",
        description: "ASC",
      },
      {
        key: "firstNameEng",
        text: "Name",
        value: "2",
        icon: "sort alphabet descending",
        description: "DESC",
      },
      {
        key: "medicalexpirydate",
        text: "Medical Expired Date",
        value: "4",
        icon: "sort alphabet descending",
        description: "DESC",
      },
      {
        key: "splexpirydate",
        text: "SPL Expired Date",
        value: "6",
        icon: "sort alphabet descending",
        description: "DESC",
      },
      {
        key: "lastFlight",
        text: "Last Flight",
        value: "10",
        icon: "sort alphabet descending",
        description: "DESC",
      },
    ],
    column: [
      {
        Header: "No.",
        accessor: "",
        style: { textAlign: "center" },
        sortable: false,
        Cell: (cellInfo) =>
          this.state.current_page * this.state.per_page + (cellInfo.index + 1),
        width: 50,
      },
      {
        Header: "Image",
        accessor: "image",
        style: { textAlign: "center" },
        sortable: false,
        Cell: (cellInfo) => (
          <Image
            avatar
            size="mini"
            verticalAlign="middle"
            src={
              cellInfo.original.image &&
              cellInfo.original.image + "?time=" + new Date()
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://app.flightschedulepro.com/images/instructor-default.gif";
            }}
          />
        ),
        width: 60,
      },
      {
        Header: "Name",
        accessor: "description",
        sortable: false,
        width: 280,
        Cell: (cellInfo) => (
          <Wrap>
            <Link
              className="bold"
              // target="_blank"
              style={{ cursor: "pointer" }}
              to={{
                pathname: "/Students/Profile",
                search:'?id='+cellInfo.original.id,
                state: { id: cellInfo.original.id }
              }}
              
              // onClick={() => this.onClickProfile(cellInfo.original)}
            >
              {cellInfo.original.description}
            </Link>
            <br />
            <p>{cellInfo.original.nameTH || "-"}</p>
          </Wrap>
        ),
      },
      // {
      //     Header: 'Advisor',
      //     accessor: 'advisor',
      //     sortable: false,
      //     hide: true,
      //     Cell: cellInfo => (
      //         <Wrap>
      //             <span>{cellInfo.original.advisor}</span>
      //         </Wrap>
      //     )
      // },
      {
        Header: "Student type",
        accessor: "description",
        sortable: false,
        Cell: (cellInfo) => (
          <Wrap>
            <span className="">
              {cellInfo.original
                ? cellInfo.original.studentType
                  ? cellInfo.original.studentType.name
                  : null
                : null}
            </span>
          </Wrap>
        ),
        width: 100,
      },
      {
        Header: "Course",
        accessor: "course",
        style: { textAlign: "left" },
        sortable: false,
        Cell: (cellInfo) => (
          // console.log(cellInfo)
          <span>
            {cellInfo.original ? (
              cellInfo.original.course ? (
                <span>
                  {cellInfo.original.course.name} (
                  {cellInfo.original.course.desc})
                </span>
              ) : null
            ) : null}
          </span>
        ),
        width: 150,
      },
      {
        Header: "Additional Course",
        accessor: "additionalCourse",
        style: { textAlign: "left" },
        sortable: false,
        Cell: (cellInfo) => (
          <span>
            {cellInfo.original
              ? cellInfo.original.additionalCourse &&
                cellInfo.original.additionalCourse.length > 0
                ? cellInfo.original.additionalCourse.map((add) => (
                    <span>
                      {add.name} ({add.desc}) <br />
                    </span>
                  ))
                : null
              : null}
          </span>
        ),
        width: 150,
      },
      {
        Header: "Class",
        accessor: "class",
        sortable: false,
        Cell: (cellInfo) => (
          <Wrap>
            <p>{cellInfo.original.class && cellInfo.original.class.name}</p>
          </Wrap>
        ),
        width: 100,
      },
      {
        Header: "Medical Certificate",
        accessor: "personnelmedical",
        style: { textAlign: "left" },
        sortable: false,
        Cell: (cellInfo) => (
          // console.log(cellInfo)
          <span>
            {cellInfo.original && (
              <Wrap>
                <span>
                  Medical Class: {cellInfo.original.medicalclass || "-"}
                </span>
                <br />
                <span>
                  Expired Date:
                  {cellInfo.original.medicalexpirydate ? (
                    moment().isAfter(
                      moment(cellInfo.original.medicalexpirydate)
                    ) ? (
                      <span style={{ color: "red" }}>
                        {cellInfo.original.medicalexpirydate}
                      </span>
                    ) : moment().isAfter(
                        moment(cellInfo.original.medicalexpirydate).subtract(
                          30,
                          "days"
                        )
                      ) ? (
                      <span style={{ color: "orange" }}>
                        {cellInfo.original.medicalexpirydate}
                      </span>
                    ) : (
                      cellInfo.original.medicalexpirydate
                    )
                  ) : (
                    "-"
                  )}
                </span>
              </Wrap>
            )}
          </span>
        ),
        width: 180,
      },
      {
        Header: "Student Pilot License",
        accessor: "personnelspl",
        style: { textAlign: "left" },
        sortable: false,
        Cell: (cellInfo) => (
          <span>
            {cellInfo.original && (
              <Wrap>
                <span>SPL No.: {cellInfo.original.splnumber || "-"}</span>
                <br />
                <span>
                  SPL Expired Date:
                  {cellInfo.original.splexpirydate ? (
                    moment().isAfter(
                      moment(cellInfo.original.splexpirydate)
                    ) ? (
                      <span>
                        <span style={{ color: "red" }}>
                          {cellInfo.original.splexpirydate}
                        </span>
                      </span>
                    ) : moment().isAfter(
                        moment(cellInfo.original.splexpirydate).subtract(
                          30,
                          "days"
                        )
                      ) ? (
                      <span>
                        <span style={{ color: "orange" }}>
                          {cellInfo.original.splexpirydate}
                        </span>
                      </span>
                    ) : (
                      cellInfo.original.splexpirydate
                    )
                  ) : (
                    "-"
                  )}
                </span>
              </Wrap>
            )}
          </span>
        ),
        width: 250,
      },
      {
        Header: "Last Flight",
        accessor: "lastFlight",
        style: { textAlign: "left" },
        sortable: false,
        Cell: (cellInfo) => (
          // console.log(cellInfo)
          <span>
            {cellInfo.original ? (
              cellInfo.original.lastFlight ? (
                moment().diff(moment(cellInfo.original.lastFlight), "days") >
                44 ? (
                  <span style={{ color: "red" }}>
                    {cellInfo.original.lastFlight}
                  </span>
                ) : moment().diff(
                    moment(cellInfo.original.lastFlight),
                    "days"
                  ) > 34 ? (
                  <span style={{ color: "orange" }}>
                    {cellInfo.original.lastFlight}
                  </span>
                ) : (
                  cellInfo.original.lastFlight
                )
              ) : (
                "-"
              )
            ) : (
              "-"
            )}
          </span>
        ),
        width: 150,
      },
      // {
      //     Header: 'Phone',
      //     accessor: 'phone',
      //     sortable: false,
      //     Cell: cellInfo => (
      //         <Wrap>
      //             <p>{cellInfo.original.phone}</p>
      //         </Wrap>
      //     ),
      //     width: 100
      // }, {
      //     Header: 'Email',
      //     accessor: 'email',
      //     sortable: false,
      //     style: { textAlign: 'center' },
      //     Cell: cellInfo => (
      //         <span>
      //             {
      //                 cellInfo.original.email
      //                     ? <Icon link circular color='red' name='mail' />
      //                     : <Icon circular color='grey' name='mail' />
      //             }
      //
      //         </span>
      //     ),
      //     width: 70,
      // },
      {
        Header: "Action",
        accessor: "id",
        style: { textAlign: "center" },
        sortable: false,
        Cell: (cellInfo) => (
          <Wrap>
            <Icon
              link
              circular
              color="grey"
              name="calendar alternate"
              onClick={() => this.onClickWorkShd(cellInfo.original)}
            />
            <Icon
              link
              circular
              color="orange"
              name="user"
              onClick={() => this.onClickProfile(cellInfo.original)}
            />
            {/* <Button size='mini' circular color='grey' icon='calendar alternate' onClick={this.onClickWorkShd} /> */}
            {/* <Button size='mini' circular color='orange'  icon='user' onClick={() => this.onClickProfile(cellInfo.original)} /> */}
          </Wrap>
        ),
        width: 100,
      },
    ],
  };
  onClickWorkShd = (value) => {
    this.setState({ currentData: value, openWorkShd: true });
  };

  show = () => {
    this.setState({ open: true });
    this.getInstructors("?isActive=true");
  };

  close = () => {
    this.setState({ open: false, openWorkShd: false, importFile: false });
  };
  closeError = () => {
    this.setState({ openError: false });
  };
  closeImportFile = () => {
    document.getElementById("myForm").reset();
    this.setState({ importFile: false });
  };

  onSubmitAddStudent = (data) => {
    this.setState({ loading: true, btnLoading: true });
    // console.log('onSubmitAddStudent', data)
    // console.log(dataPersonnel, dataStudent)

    postStudent(data).then((res) => {
      if (res && res.status === 201) {
        // console.log('postPersonnel', res.data)
        let qString = "?status=Active";
        this.getData(qString);
        this.close();
      } else if (res && res.status === 409) {
        this.setState({ errorMessage: "Username or Email already exists." });
      }
      this.setState({ loading: false, btnLoading: false });
    });
  };

  onClickProfile = (data) => {
    // console.log(data)
    this.props.history.push({
      pathname: "/Students/Profile",
      state: { id: data.id },
    });
  };

  handleChangeStatus = (e, data) => {
    let {
      search,
      options_class,
      checked_class,
      checked_course,
      options_course,
      checked_stdtype,
      options_studenttype,
    } = this.state;
    let qString;

    search["status"] = data.value; // "Active/Inactive"
    this.setState({ search });

    qString = "?status=" + data.value;
    if (search["name"]) {
      qString += "&name=" + search.name;
    }
    if (search["sort"])
      qString +=
        "&_sort=" + search.sort.key + "&_order=" + search.sort.description;

    if (checked_class && checked_class.length != options_class.length)
      checked_class.map((item) => {
        qString += `&class[]=${item}`;
      });

    if (checked_course && checked_course.length != options_course.length)
      checked_course.map((item) => {
        qString += `&course[]=${item}`;
      });

    if (checked_stdtype && checked_stdtype.length != options_studenttype.length)
      checked_stdtype.map((item) => {
        qString += `&stdtype[]=${item}`;
      });
    this.getData(qString);
  };

  handleChangeSearch = (e) => {
    let {
      rawData,
      search,
      options_class,
      checked_class,
      checked_course,
      options_course,
      checked_stdtype,
      options_studenttype,
    } = this.state;
    let active;
    this.setState({ loading: true });
    if (this._timeout) {
      //if there is already a timeout in process cancel it
      clearTimeout(this._timeout);
    }
    const val = e.target.value;
    const name = e.target.name;
    this._timeout = setTimeout(() => {
      this._timeout = null;
      search[name] = val;
      let qString = "?name=" + val;
      qString += "&status=" + search.status;

      if (checked_class && checked_class.length != options_class.length)
        checked_class.map((item) => {
          qString += `&class[]=${item}`;
        });

      if (checked_course && checked_course.length != options_course.length)
        checked_course.map((item) => {
          qString += `&course[]=${item}`;
        });

      if (
        checked_stdtype &&
        checked_stdtype.length != options_studenttype.length
      )
        checked_stdtype.map((item) => {
          qString += `&stdtype[]=${item}`;
        });

      if (search["sort"])
        qString +=
          "&_sort=" + search.sort.key + "&_order=" + search.sort.description;
      this.getData(qString);
    }, 2000);
  };

  handleChangeSort = (e, data) => {
    let {
      search,
      checked_class,
      options_class,
      checked_course,
      options_course,
      checked_stdtype,
      options_studenttype,
    } = this.state;
    let qString;

    const result = data.options.find((member) => {
      return member.value === data.value;
    });
    search["sort"] = result;
    this.setState({
      search,
      sortText: `${result.text} (${result.description})`,
    });

    qString =
      "?status=" +
      search.status +
      "&_sort=" +
      search.sort.key +
      "&_order=" +
      search.sort.description;
    if (search["name"]) {
      qString += "&name=" + search.name;
    }
    if (checked_class && checked_class.length != options_class.length)
      checked_class.map((item) => {
        qString += `&class[]=${item}`;
      });

    if (checked_course && checked_course.length != options_course.length)
      checked_course.map((item) => {
        qString += `&course[]=${item}`;
      });

    if (checked_stdtype && checked_stdtype.length != options_studenttype.length)
      checked_stdtype.map((item) => {
        qString += `&stdtype[]=${item}`;
      });

    this.getData(qString);
  };

  filterByClass = (data) => {
    // console.log(data)
    let {
      search,
      options_class,
      checked_course,
      options_course,
      checked_stdtype,
      options_studenttype,
    } = this.state;

    let checked_class = [];

    let qString = "?status=" + search.status;

    if (data && data.length != options_class.length)
      data.map((item) => {
        qString += `&class[]=${item}`;
        checked_class.push(item);
      });
    else
      data.map((item) => {
        checked_class.push(item);
      });

    if (data.length === options_class.length) {
      this.setState({ filterClassByText: "All Class" });
    } else {
      this.setState({ filterClassByText: `Class (${data.length})` });
    }
    this.setState({ checked_class });
    if (search["name"]) {
      qString += "&name=" + search.name;
    }
    if (search["sort"])
      qString +=
        "&_sort=" + search.sort.key + "&_order=" + search.sort.description;

    if (checked_course && checked_course.length != options_course.length)
      checked_course.map((item) => {
        qString += `&course[]=${item}`;
      });

    if (checked_stdtype && checked_stdtype.length != options_studenttype.length)
      checked_stdtype.map((item) => {
        qString += `&stdtype[]=${item}`;
      });
    // console.log(qString)
    this.getData(qString);
  };

  filterByStdtype = (data) => {
    // console.log(data)
    let {
      search,
      options_studenttype,
      checked_class,
      options_class,
      checked_course,
      options_course,
    } = this.state;

    let checked_stdtype = [];

    let qString = "?status=" + search.status;

    if (data && data.length != options_studenttype.length)
      data.map((item) => {
        qString += `&stdtype[]=${item}`;
        checked_stdtype.push(item);
      });
    else
      data.map((item) => {
        checked_stdtype.push(item);
      });

    if (data.length === options_studenttype.length) {
      this.setState({ filterStdtypeByText: "All Student type" });
    } else {
      this.setState({ filterStdtypeByText: `Student type (${data.length})` });
    }
    this.setState({ checked_stdtype });

    if (search["name"]) {
      qString += "&name=" + search.name;
    }
    if (search["sort"])
      qString +=
        "&_sort=" + search.sort.key + "&_order=" + search.sort.description;

    if (checked_class && checked_class.length != options_class.length)
      checked_class.map((item) => {
        qString += `&class[]=${item}`;
      });

    if (checked_course && checked_course.length != options_course.length)
      checked_course.map((item) => {
        qString += `&course[]=${item}`;
      });
    // console.log(qString)
    this.getData(qString);
  };

  filterByCourse = (data) => {
    // console.log(data)
    let {
      search,
      options_course,
      checked_class,
      options_class,
      checked_stdtype,
      options_studenttype,
    } = this.state;

    let checked_course = [];

    let qString = "?status=" + search.status;

    if (data && data.length != options_course.length)
      data.map((item) => {
        qString += `&course[]=${item}`;
        checked_course.push(item);
      });
    else
      data.map((item) => {
        checked_course.push(item);
      });

    if (data.length === options_course.length) {
      this.setState({ filterCourseByText: "All Course" });
    } else {
      this.setState({ filterCourseByText: `Course (${data.length})` });
    }
    this.setState({ checked_course });
    if (search["name"]) {
      qString += "&name=" + search.name;
    }
    if (search["sort"])
      qString +=
        "&_sort=" + search.sort.key + "&_order=" + search.sort.description;

    if (checked_class && checked_class.length != options_class.length)
      checked_class.map((item) => {
        qString += `&class[]=${item}`;
      });

    if (checked_stdtype && checked_stdtype.length != options_studenttype.length)
      checked_stdtype.map((item) => {
        qString += `&stdtype[]=${item}`;
      });
    // console.log(qString)
    this.getData(qString);
  };

  getClass = () => {
    getAllClass().then((res) => {
      let checked_class = [];
      if (res && res.status === 200) {
        let options = [];
        res.data.map((item) => {
          options.push({ key: item.id, text: item.name, value: item.id });
          checked_class.push(item.id);
        });
        this.setState({
          options_class: options,
          rawDataclass: res.data,
          checked_class,
        });
      }
    });
  };

  getCompany = () => {
    getAllCompany().then((res) => {
      if (res && res.status === 200) {
        let options = [];
        res.data.map((item) => {
          options.push({ key: item.id, text: item.name, value: item.id });
        });
        this.setState({ options_company: options, rawDataCompany: res.data });
      }
    });
  };

  getAllStudentType = () => {
    getStudentType().then((res) => {
      let checked_stdtype = [];
      if (res && res.status === 200) {
        let options = [];
        res.data.map((item) => {
          options.push({ key: item.id, text: item.name, value: item.id });
          checked_stdtype.push(item.id);
        });
        this.setState({
          options_studenttype: options,
          rawDataStudenttype: res.data,
          checked_stdtype,
        });
      }
    });
  };

  getOptionCourse = (qString) => {
    getAllCourse(qString).then((res) => {
      let checked_course = [];
      if (res && res.status === 200) {
        let options = [];
        res.data.map((item) => {
          options.push({
            key: item.id,
            text: `${item.name} (${item.desc})`,
            value: item.id,
          });
          // text: `${item.name} ${moment(item.start, 'DD-MM-YYYY').format("DD/MM/YYYY")} - ${moment(item.end, 'DD-MM-YYYY').format("DD/MM/YYYY")}`,
          checked_course.push(item.id);
        });
        this.setState({
          options_course: options,
          rawDataCourse: res.data,
          checked_course,
        });
      }
    });
  };

  getData = (qString) => {
    this.setState({ loading: true });
    let { per_page, current_page, search } = this.state;
    current_page = current_page + 1;
    qString = qString + "&_perpage=" + per_page + "&page=" + current_page;
    getStudentListBySearch(qString).then((res) => {
      // console.log('student', res)
      if (res && res.status === 200) {
        let newdata = [];
        res.data.data.map((item) => {
          let description = `${item.studentId || ""} ${item.titleEng || ""} ${
            item.firstNameEng
          } ${item.lastNameEng} ${item.customerCode || ""}`;
          let nameTH = ` ${item.title || ""} ${item.firstName} ${
            item.lastName
          }`;
          newdata.push({
            id: item.id,
            image: item.imageURL || [],
            description: description,
            nameTH: nameTH,
            email: item.emailAddress,
            phone: item.phoneNumber,
            roles: item.roles || [],
            class: item.class,
            course: item.course,
            studentType: item.studentType,
            additionalCourse: item.additionalCourse || [],
            advisor: item.advisor,
            medicalclass: item.medicalclass,
            medicalexpirydate: item.medicalexpirydate,
            splnumber: item.splnumber,
            splexpirydate: item.splexpirydate,
            lastFlight: item.lastFlight,
          });
        });
        // console.log('newdata', newdata)
        this.setState({
          data: newdata,
          rawData: res.data.data,
          current_page: res.data.current_page - 1,
          last_page: res.data.last_page,
        });
      }
      this.setState({ loading: false });
    });
  };
  onChange = (e, data) => {
    // console.log(e, data)
    this.setState({ [data.name]: data.value });
  };

  getInstructors = (qString) => {
    getAllInstructors(qString).then((res) => {
      if (res && res.status === 200) {
        let options = [];
        res.data.map((item) => {
          options.push({
            key: item.id,
            text: `${item.firstName} ${item.lastName} (${
              item.nickName || "-"
            })`,
            value: item.id,
          });
        });
        this.setState({
          options_instructors: options,
          rawDataInstructors: res.data,
        });
      }
    });
  };

  componentDidMount() {
    let qString = "?status=Active";
    this.getData(qString);
    this.getClass();
    this.getCompany();
    this.getAllStudentType();
    let qString2 = "?additionalCourse=false&active=true";
    this.getOptionCourse(qString2);
  }

  render() {
    const state = this.state;
    return (
      <Segment className="contentSegment" textAlign="left">
        <HeaderWithMenu name="Students">
          {/* <Dropdown icon={<Icon circular name='align justify' color="black" />} direction='left'>
                        <Dropdown.Menu>
                            <Dropdown.Item icon='external share' text='Import Data' onClick={this.openImportExcel} />
                            <Dropdown.Item icon='download' text='Dowload template' onClick={this.dowloadTemplate} />
                        </Dropdown.Menu>
                    </Dropdown> */}
          {/* <MainButton
                        size='small'
                        onClick={this.show}
                        text='Add Student' /> */}
        </HeaderWithMenu>
        <Form>
          <Form.Group>
            <Form.Input
              width="3"
              icon="search"
              size="small"
              placeholder="Search"
              name="name"
              onChange={this.handleChangeSearch}
            />
            <Form.Field width="2">
              <DropdownCheckbox
                style={{ textAlign: "left" }}
                checked={state.checked_class}
                options={state.options_class}
                onClick={this.filterByClass}
              >
                <Button
                  fluid
                  size="small"
                  icon
                  basic
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  {state.filterClassByText}
                  <Icon name="angle down" />
                </Button>
              </DropdownCheckbox>
            </Form.Field>
            <Form.Field width="2">
              <DropdownCheckbox
                style={{ textAlign: "left" }}
                checked={state.checked_stdtype}
                options={state.options_studenttype}
                onClick={this.filterByStdtype}
              >
                <Button
                  fluid
                  size="small"
                  icon
                  basic
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  {state.filterStdtypeByText}
                  <Icon name="angle down" />
                </Button>
              </DropdownCheckbox>
            </Form.Field>
            <Form.Field width="2">
              <DropdownCheckbox
                style={{ textAlign: "left" }}
                checked={state.checked_course}
                options={state.options_course}
                onClick={this.filterByCourse}
              >
                <Button
                  fluid
                  size="small"
                  icon
                  basic
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  {state.filterCourseByText}
                  <Icon name="angle down" />
                </Button>
              </DropdownCheckbox>
            </Form.Field>
            <Form.Dropdown
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Sort by : <b>{state.sortText}</b>
                </Button>
              }
              options={state.options_sortby}
              defaultValue={state.search.sort}
              selectOnBlur={false}
              onChange={this.handleChangeSort}
            />
            <Form.Dropdown
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Status : <b>{state.search.status}</b>
                </Button>
              }
              options={config.options_studentstatus}
              defaultValue={state.search.status}
              selectOnBlur={false}
              onChange={this.handleChangeStatus}
            />
            {/* <Form.Field width='9' style={{ textAlign: 'right' }}>
                            <MainButton
                                size='small'
                                onClick={this.show}
                                text='Add Student' />
                        </Form.Field> */}
          </Form.Group>
        </Form>
        <ModalForm
          icon="exclamation circle"
          content="นำเข้าข้อมูลไม่สำเร็จ"
          size="large"
          open={state.openError}
          close={this.closeError}
        >
          <Wrap>
            {state.errorsRow && (
              <h3>จำนวนข้อผิดพลาดทั้งหมด {state.errorsRow} จุด</h3>
            )}
            <br />
            {state.errorInfo && <p>{state.errorInfo}</p>}
          </Wrap>
        </ModalForm>
        <ModalForm
          icon="calendar alternate"
          content="Schedule"
          size="large"
          open={state.openWorkShd}
          close={this.close}
        >
          <Wrap>
            <ScheduleById
              id={state.currentData ? state.currentData.id : null}
              disableClick
            />{" "}
            //disableClick
          </Wrap>
        </ModalForm>
        <ModalForm
          icon="add user"
          content="Add Student"
          open={state.open}
          close={this.close}
        >
          <FormStudent
            isAdd={true}
            loading={state.btnLoading}
            onSubmit={this.onSubmitAddStudent}
            onCancel={this.close}
            activeUser={state.activeUser}
            errorMessage={state.errorMessage}
            loadingUp={state.loadingUp}
            options_class={state.options_class}
            options_company={state.options_company}
            options_course={state.options_course}
            options_studenttype={state.options_studenttype}
            options_instructors={state.options_instructors}
          />
        </ModalForm>
        <ModalForm
          icon="file excel"
          content="Import Student data"
          open={state.importFile}
          close={this.closeImportFile}
        >
          <Wrap>
            <Form id="myForm" onSubmit={this.importData}>
              <Form.Select
                label="Course"
                search
                clearable
                required={true}
                name="select_course"
                error={state.errorCourse}
                onChange={(e, value) => this.onChange(e, value)}
                options={state.options_course}
              ></Form.Select>
              <Form.Group widths="equal">
                <Form.Select
                  label="Class"
                  search
                  clearable
                  name="select_class"
                  onChange={(e, value) => this.onChange(e, value)}
                  options={state.options_class}
                ></Form.Select>
                <Form.Select
                  label="Company"
                  search
                  clearable
                  name="select_company"
                  onChange={(e, value) => this.onChange(e, value)}
                  options={state.options_company}
                ></Form.Select>
              </Form.Group>

              <Form.Input
                label="Excel file"
                type="file"
                name="excel"
                onChange={this.onChangeFile}
              />
              <p style={{ color: "red", textAlign: "left" }}>
                {state.FileError ? state.FileError : ""}
              </p>
              <br />
              <Button
                floated="left"
                type="submit"
                content="Import"
                color="green"
                loading={state.activeLoader}
              />
              <Button
                floated="right"
                type="button"
                content="Cancel"
                color="green"
                basic
                onClick={this.closeImportFile}
              />
            </Form>
          </Wrap>
        </ModalForm>
        <TableList
          loading={state.loading}
          data={state.data}
          columns={state.column}
          pageSize={state.per_page}
          page={state.current_page}
          pages={state.last_page}
          onPageChange={this.onPageChange}
          onPageSizeChange={this.onPageSizeChange}
        />
      </Segment>
    );
  }

  onPageSizeChange = (pageSize, pageIndex) => {
    let {
      search,
      options_class,
      checked_class,
      checked_course,
      options_course,
      checked_stdtype,
      options_studenttype,
    } = this.state;
    this.setState(
      {
        per_page: pageSize,
        current_page: pageIndex,
      },
      () => {
        let qString = "?status=" + search.status;
        if (search["name"]) {
          qString += "&name=" + search.name;
        }
        if (search["sort"])
          qString +=
            "&_sort=" + search.sort.key + "&_order=" + search.sort.description;

        if (checked_class && checked_class.length != options_class.length)
          checked_class.map((item) => {
            qString += `&class[]=${item}`;
          });

        if (checked_course && checked_course.length != options_course.length)
          checked_course.map((item) => {
            qString += `&course[]=${item}`;
          });

        if (
          checked_stdtype &&
          checked_stdtype.length != options_studenttype.length
        )
          checked_stdtype.map((item) => {
            qString += `&stdtype[]=${item}`;
          });
        this.getData(qString);
      }
    );
  };

  onPageChange = (pageIndex) => {
    let {
      search,
      options_class,
      checked_class,
      checked_course,
      options_course,
      checked_stdtype,
      options_studenttype,
    } = this.state;
    this.setState(
      {
        current_page: pageIndex,
      },
      () => {
        let qString = "?status=" + search.status;
        if (search["name"]) {
          qString += "&name=" + search.name;
        }
        if (search["sort"])
          qString +=
            "&_sort=" + search.sort.key + "&_order=" + search.sort.description;

        if (checked_class && checked_class.length != options_class.length)
          checked_class.map((item) => {
            qString += `&class[]=${item}`;
          });

        if (checked_course && checked_course.length != options_course.length)
          checked_course.map((item) => {
            qString += `&course[]=${item}`;
          });

        if (
          checked_stdtype &&
          checked_stdtype.length != options_studenttype.length
        )
          checked_stdtype.map((item) => {
            qString += `&stdtype[]=${item}`;
          });
        this.getData(qString);
      }
    );
  };
}

export default withRouter(Student);
// export default Student = reduxForm({
//     // a unique name for the form
//     form: 'Student',
//     validate,
//     enableReinitialize: true
// })(Student)
