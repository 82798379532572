import axios from 'axios'
import * as config from '../../config'
//Admin
export function getFlightTraningRecord(search) {
    return axios.get(config.urlAPI + '/flightTraningRecord'+ search, config.headerCallAPI)
        .then(res => {
            return res
        }).catch(err => {
            return err.response
        })
}
//instructor
export function getFTRecordByInstructor(search) {
    return axios.get(config.urlAPI + '/flightTraningRecord/instuctor'+ search, config.headerCallAPI)
        .then(res => {
            return res
        }).catch(err => {
            return err.response
        })
}

//student
export function getFTRecordBystudent(search) {
    return axios.get(config.urlAPI + '/flightTraningRecord/student'+ search, config.headerCallAPI)
        .then(res => {
            return res
        }).catch(err => {
            return err.response
        })
}

export function getSnagById(id) {
    return axios.get(config.urlAPI + '/flightTraningRecord/snag/'+ id, config.headerCallAPI)
        .then(res => {
            return res
        }).catch(err => {
            return err.response
        })
}

export function getFlightTraningRecordById(id) {
    return axios.get(config.urlAPI + '/flightTraningRecord/'+ id, config.headerCallAPI)
        .then(res => {
            return res
        }).catch(err => {
            return err.response
        })
}

export function postFlightTraningRecord(id,data) {
    return axios.put(config.urlAPI + '/flightTraningRecord/'+ id, data, config.headerCallAPI)
        .then(res => {
            return res
        }).catch(err => {
            return err.response
        })
}

export function approveFlightTraningRecord(id,data) {
    return axios.put(config.urlAPI + '/flightTraningRecord/approve/'+ id, data, config.headerCallAPI)
        .then(res => {
            return res
        }).catch(err => {
            return err.response
        })
}
export function repeatFlightTraningRecord(id,data) {
    return axios.put(config.urlAPI + '/flightTraningRecord/repeat/'+ id, data, config.headerCallAPI)
        .then(res => {
            return res
        }).catch(err => {
            return err.response
        })
}

// export function getflightSubject(id) {
//     return axios.get(config.urlAPI + '/subject/' + id, config.headerCallAPI)
//         .then(res => {
//             return res
//         }).catch(err => {
//             return err.response
//         })
// }
// instuctor
// chokechai.spa@ba.ac.th
// 29121954
// student
// Patara@std.ba.ac.th
// 30101996
export function postLogin(data) {
  return axios
    .post(config.urlAPI + "/flightTraningRecord/login", data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
