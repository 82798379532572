import axios from "axios";
import * as config from "../../config";

export function getReservationTypesIDByName(search) {
  return axios
    .get(config.urlAPI + "/reservationTypesList" + search)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function getReservation(search) {
  //search detail https://api-json-bac.firebaseapp.com/api/reservation/1?_embed=attendance
  return axios
    .get(config.urlAPI + "/reservation" + search, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

// export function getReservationList() {
//   //search detail https://api-json-bac.firebaseapp.com/api/reservation/1?_embed=attendance
//   return axios
//     .get(config.urlAPI + "/reservation", config.headerCallAPI)
//     .then(res => {
//       return res;
//     })
//     .catch(err => {
//       return err.response;
//     });
// }

export function getAttendanceAdmin(search) {
  return axios
    .get(config.urlAPI + "/groundAttendance/admin" + search, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getAttendance(search) {
  return axios
    .get(config.urlAPI + "/groundAttendance" + search, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function updateAttendance(id,data) {
  return axios
    .put(config.urlAPI + "/groundAttendance/" + id , data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
// FIXME No.1
export function uploadDocAttendance(id, data) {
    return axios
        .post(
            config.urlAPI + "/groundAttendance/" + id + "/uploadDocument",
            data,
            config.headerCallAPI
        )
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

